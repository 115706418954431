import { AnyAction } from 'redux';
import {
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
} from './actionTypes';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  error: null,
};

const myActivities = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case FETCH_ACTIVITIES_REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_ACTIVITIES_SUCCESS: {
      return { ...state, data: action.payload, isLoading: false };
    }

    case FETCH_ACTIVITIES_FAILURE: {
      return { ...state, error: action.payload.error, isLoading: false };
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: 'myActivities',
  storage,
};

export default persistReducer(persistConfig, myActivities);
