const mapUserSettings = (mapFrom, sourceArr) =>
  mapFrom.map(item => {
    const obj = sourceArr.find(s => s.value === item);
    if (obj) {
      return obj;
    }
    return {
      label: item,
      value: item,
    };
  });

export { mapUserSettings };
