// const getUserInitials = userName => {
//   return userName ? userName.match(/\b\w/g).join('') : 'A';
// };

import _ from 'lodash';

const getUserInitials = user => {
  if (_.isNil(user)) {
    return '';
  }
  const { firstName, lastName, name } = user;

  let initials = '';
  if (
    !_.isNil(firstName) &&
    !_.isEmpty(firstName) &&
    !_.isNil(lastName) &&
    !_.isEmpty(lastName)
  ) {
    const firstNameInitial = firstName.toUpperCase().charAt(0);
    const lastNameInitial = lastName.toUpperCase().charAt(0);
    initials = `${firstNameInitial}${lastNameInitial}`;
  } else {
    initials =
      !_.isNil(name) && !_.isEmpty(name) ? name.match(/\b\w/g).join('') : 'A';
  }

  return initials;
};

export { getUserInitials };
