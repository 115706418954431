import React from 'react';

import logo from '../../assets/images/logo-old.svg';
import Navigation from './Navigation';
import UserMenu from '../UserMenu';
import styles from './MainHeader.module.scss';
import Button from '../Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Hidden } from '@material-ui/core';

function MainHeader({ user, isAuth }) {
  const { loginWithRedirect } = useAuth0();
  return (
    <Grid
      container
      className={styles['custom-header']}
      alignItems="center"
      wrap="nowrap"
    >
      <Grid
        item
        container
        wrap="nowrap"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <a href="https://dropcite.com" className={styles.logo}>
            <img
              src={logo}
              alt="DropCite logo"
              className={styles['logo__img']}
            />
            <span className={styles['logo__text']}>DropCite</span>
          </a>
        </Grid>
      </Grid>
      <Grid
        item
        container
        wrap="nowrap"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Hidden mdDown>
          <Grid item>
            <Navigation isAuth={isAuth} />
          </Grid>
          {!isAuth && (
            <Grid item>
              <Button
                onClick={() => {
                  localStorage.setItem(
                    'goBackPathname',
                    window.location.pathname,
                  );
                  loginWithRedirect();
                }}
                caption="Sign in"
                size="small"
              />
            </Grid>
          )}
        </Hidden>
        <Grid item>
          <UserMenu user={user} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MainHeader;
