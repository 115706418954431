import React, { Component } from 'react';

import MainHeader from '../../components/MainHeader';
import styles from './MainLayout.module.scss';
import { ROUTES_WITHOUT_HEADER } from '../../constants/routes';

export default class MainLayout extends Component {
  render() {
    const {
      children,
      location: { pathname },
    } = this.props;

    if (ROUTES_WITHOUT_HEADER.includes(pathname)) {
      return children;
    }

    return (
      <div className={styles.content}>
        <MainHeader />
        <div className={styles.body}>{children}</div>
      </div>
    );
  }
}
