import React, { useState } from "react";

import { SortByFilter } from "../../pages/Home/components/SortByFilter";
import { IsLikedFilter } from "../../pages/Home/components/IsLikedFilter";
import { CategoryFilter } from "../../pages/Home/components/CategoryFilter";

import classNames from "classnames";
import { Divider } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import classes from "./MobileFilters.module.scss";

const MobileFilters = (props) => {
    const {
        styles,
        sortByData,
        filters,
        handleSortByChange,
        isAuth,
        handleIsLikedChange,
        categories,
        handleCategoriesChange,
    } = props;

    const [menuOpened, setMenuOpened] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpened(!menuOpened);
    };

    return (
        <>
            <div
                className={classNames(
                    classes["overlay"],
                    menuOpened ? classes["is-opened"] : ""
                )}
            >
            <div
                className={classNames(
                    classes["mobile-filters"],
                    menuOpened ? classes["is-opened"] : ""
                )}
            >
                <div className="container-fluid text-right">
                    <button
                        onClick={handleMenuToggle}
                        className="btn btn-primary"
                    >
                        <ArrowBackIcon></ArrowBackIcon>
                    </button>
                </div>
                <div className={classNames("col-12", styles["filters-column"])}>
                    <SortByFilter
                        data={sortByData}
                        selected={filters.sortBy}
                        onChange={handleSortByChange}
                    />
                    {isAuth && (
                        <>
                            <IsLikedFilter
                                selected={filters.isLiked}
                                onChange={handleIsLikedChange}
                            />
                            <Divider />
                        </>
                    )}
                    <CategoryFilter
                        data={categories}
                        selected={filters.category}
                        onChange={handleCategoriesChange}
                    />
                </div>
            </div>
            </div>
            <button onClick={handleMenuToggle} className="btn btn-primary">
                <FilterListIcon></FilterListIcon>
            </button>
        </>
    );
};

export default MobileFilters;