import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import PagePreloader from '../components/PagePreloader';
import { ROUTES } from '../constants/routes';
import api from '../api';

export default function withRouteProtection(Route) {
  function RouteProtection({ isAuth, ...restProps }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isOnboardingRedirecting, setIsOnboardingRedirecting] = useState(
      false,
    );
    const { pathname } = useLocation();

    useEffect(() => {
      if (!isAuth) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      api.users
        .getSettings()
        .then(({ data: user }) => {
          setIsOnboardingRedirecting(
            !user.hasUserCompletedOnBoarding && pathname !== ROUTES.onboarding,
          );
        })
        .catch(error => toast.error(error.message))
        .finally(() => setIsLoading(false));
    }, [isAuth]);

    if (isLoading) return <PagePreloader />;

    if (!isAuth) {
      return <Redirect to={ROUTES.signIn} />;
    }

    if (isOnboardingRedirecting) {
      return <Redirect to={ROUTES.onboarding} />;
    }

    return <Route {...restProps} />;
  }

  return connect(state => ({
    isAuth: state.auth.isAuth,
  }))(RouteProtection);
}
