import storage from 'redux-persist/lib/storage';
import {
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS_FILTER,
  FETCH_ARTICLES_SUCCESS_SCROLL,
  SET_FILTERS,
} from './actionTypes';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

const INITIAL_STATE = {
  isLoading: false,
  offset: 0,
  limit: 50,
  filters: {
    sortBy: 'commentsCount',
    category: ['all'],
    isLiked: false,
  },
  hasMore: true,
  data: null,
  error: null,
};

const persistConfig = {
  key: 'homePageArticles',
  storage,
  stateReconciler: autoMergeLevel2,
};

const homePageArticles = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_ARTICLES_REQUEST:
      return { ...state, isLoading: true };

    case FETCH_ARTICLES_SUCCESS_FILTER: {
      return {
        ...state,
        isLoading: false,
        data: payload,
        offset: state.limit,
        hasMore: payload.length === state.limit,
      };
    }

    case FETCH_ARTICLES_SUCCESS_SCROLL: {
      return {
        ...state,
        isLoading: false,
        data: [...state.data, ...payload],
        offset: state.offset + state.limit,
        hasMore: payload.length === state.limit,
      };
    }

    case FETCH_ARTICLES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    case SET_FILTERS: {
      return {
        ...state,
        filters: payload,
      };
    }

    default:
      return state;
  }
};

export default persistReducer(persistConfig, homePageArticles);
