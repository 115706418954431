import React from 'react';
import cn from 'classnames';

import Tooltip from '../Tooltip';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';

import styles from './PercentageLine.module.scss';

function PercentageLine(props) {
  const { width, totalCount, segments, extended, className } = props;

  return (
    <div
      className={cn(styles['wrapper'], {
        [styles['wrapper--extended']]: extended,
      })}
    >
      <div className={cn(styles.line, className)} style={{ width }}>
        {segments.map(({ color, count, hashtag }) => (
          <Tooltip
            key={color}
            placement="bottom"
            text={hashtag ? capitalizeFirstLetter(hashtag) : ''}
            triggers={['hover', 'focus']}
            className={styles.tooltip}
          >
            <div
              className={styles.segment}
              style={{
                backgroundColor: color,
                width: `${(count / totalCount) * 100}%`,
              }}
            >
              {count}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}

PercentageLine.defaultProps = {
  width: '100%',
  className: '',
};

export default PercentageLine;
