import { combineReducers } from 'redux';

import auth from './auth/reducer';
import articles from './articles/reducer';
import comments from './comments/reducer';
import modals from './modals/reducer';
import commentsAlignUI from './commentsAlignUI/reducer';
import homePageArticles from './homePageArticles/reducer';
import myArticles from './myArticles/reducer';
import myActivities from './myActivities/reducer';
import systemSettings from './systemSettings/reducer';

export default combineReducers({
  modals,
  auth,
  articles,
  comments,
  commentsAlignUI,
  homePageArticles,
  myArticles,
  myActivities,
  systemSettings,
});
