import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

export default function withCompleteOnboarding(BaseComponent) {
  function Component({ hasUserCompletedOnBoarding, isAuth, ...restProps }) {
    if (isAuth && !hasUserCompletedOnBoarding) {
      return <Redirect to={ROUTES.onboarding} />;
    }

    return <BaseComponent {...restProps} />;
  }

  return connect(state => ({
    isAuth: state.auth.isAuth,
    hasUserCompletedOnBoarding: state.auth.user.hasUserCompletedOnBoarding,
  }))(Component);
}
