import animate from './animate';

const ANIMATION_DURATION = 500;

export default function scrollTo(
  container,
  element,
  offset = 0,
  duration = ANIMATION_DURATION,
) {
  if (!container || !element) return;

  const scrollTop = container.scrollTop;
  const scrollDistance = element.offsetTop - scrollTop;

  animate({
    duration: duration,
    timing(timeFraction) {
      return Math.pow(timeFraction, 3);
    },
    draw(progress) {
      container.scrollTop = scrollTop + progress * (scrollDistance - offset);
    },
  });
}
