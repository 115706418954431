function isHighlightStandsOnposition(
  highlight,
  { position, pageNumber, containerWidth },
) {
  const scale = containerWidth / highlight.position.boundingRect.width;

  return (
    highlight.position.pageNumber === pageNumber &&
    highlight.position.rects.find(
      rect =>
        position.x >= rect.x1 * scale &&
        position.x <= rect.x2 * scale &&
        position.y >= rect.y1 * scale &&
        position.y <= rect.y2 * scale,
    )
  );
}

export default isHighlightStandsOnposition;
