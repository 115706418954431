import { AnyAction } from 'redux';
import storage from 'redux-persist/lib/storage';
import {
  FETCH_SYSTEM_SETTINGS_REQUEST,
  FETCH_SYSTEM_SETTINGS_SUCCESS,
  FETCH_SYSTEM_SETTINGS_FAILURE,
} from './actionTypes';
import { persistReducer } from 'redux-persist';

const INITIAL_STATE = {
  isLoading: false,
  dailyRefresh: false,
  dailyRefreshRunning: false,
  anonymousUsers: false,
  optOutEnabled: true,
  optOutText: '',
};

const systemSettings = (state = INITIAL_STATE, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_SYSTEM_SETTINGS_REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_SYSTEM_SETTINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...payload,
      };
    }

    case FETCH_SYSTEM_SETTINGS_FAILURE: {
      return { ...state, isLoading: false, error: payload.error };
    }

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'systemSettings',
  storage,
};

export default persistReducer(persistConfig, systemSettings);
