import moment from 'moment';

import { DATE_FORMAT } from '../constants/common';

const formatDate = (date, dateFormat = DATE_FORMAT) =>
  moment(date).format(dateFormat);

const formatUtcDate = (date, dateFormat = DATE_FORMAT) =>
  moment.utc(date).format(dateFormat);

export { formatDate, formatUtcDate };
