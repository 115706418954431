import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import history from './services/history';
import store, { persistor } from './redux/store';
import Routes from './routes';
import ModalsContainer from './components/Modal/ModalsContainer';
import NotificationContainer from './components/NotificationContainer';

import './assets/styles/global.scss';
import './assets/styles/_reboot.scss';
import { useUserFeedbackScript } from './hooks/useUserFeedbackScript';
import InitialDataLoader from './components/InitialDataLoader';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0277bd',
    },
    secondary: {
      main: '#009786',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    info: {
      main: '#17a2b8',
    },
  },
});

const App = () => {
  useUserFeedbackScript();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Auth0ProviderWithHistory>
            <ThemeProvider theme={theme}>
              <InitialDataLoader>
                <ModalsContainer />
                <Routes />
              </InitialDataLoader>
            </ThemeProvider>
          </Auth0ProviderWithHistory>
        </Router>
        <NotificationContainer />
      </PersistGate>
    </Provider>
  );
};

export default App;
