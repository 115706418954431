import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroller";

import ArticleCard from "../../components/ArticleCard/ArticleCard";
import CircularProgress from "../../components/CircularProgress";

import styles from "./Home.module.scss";
import api from "../../api";
import { CategoryFilter } from "./components/CategoryFilter";
import { SortByFilter } from "./components/SortByFilter";
import _ from "lodash";
import { allSelectOption } from "./constants/constants";
import { IsLikedFilter } from "./components/IsLikedFilter";
import { IsPrivateFilter } from "./components/IsPrivateFilter";
import { useDispatch, useSelector } from "react-redux";
import { isUserAuthSelector } from "../../redux/auth/selectors";
import { Box, Container, Divider, Grid } from "@material-ui/core";

import { HomePageArticlesSelectors } from "../../redux/homePageArticles/selectors";
import { HomePageArticlesActions } from "../../redux/homePageArticles/actions";
import MobileFilters from "../../components/MobileFilters/MobileFilters";

const sortByData = [
    {
        id: 1,
        value: "likesCount",
        label: "Most Upvotes",
    },
    {
        id: 2,
        value: "commentsCount",
        label: "Most Comments",
    },
    {
        id: 3,
        value: "date",
        label: "Most Recent",
    },
];

const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1,
};

const Home = () => {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);

    const [categories, setCategories] = useState([]);

    const isAuth = useSelector(isUserAuthSelector);
    const articles =
        useSelector(HomePageArticlesSelectors.selectArticles) || [];
    const isArticlesLoading = useSelector(
        HomePageArticlesSelectors.selectIsLoading
    );
    const offset = useSelector(HomePageArticlesSelectors.selectOffset);
    const limit = useSelector(HomePageArticlesSelectors.selectLimit);
    const hasMoreArticles = useSelector(
        HomePageArticlesSelectors.selectHasMore
    );
    const filters = useSelector(HomePageArticlesSelectors.selectFilters);
    const specificMessage= process.env.REACT_APP_CLIENT_SPECIFIC_MESSAGE || '';

    const fetchCategories = async () => {
        try {
            return await api.categories.list();
        } catch (err) {
            console.error(err);
        }
    };

    const fetchArticlesOnFilterChange = async (offset, limit, filters) =>
        dispatch(
            HomePageArticlesActions.fetchArticlesOnFilterChange(
                offset,
                limit,
                filters
            )
        );

    const fetchArticlesOnScroll = async (offset, limit, filters) =>
        dispatch(
            HomePageArticlesActions.fetchArticlesOnScroll(
                offset,
                limit,
                filters
            )
        );

    useEffect(() => {
        (async () => {
            const res = await fetchCategories();
            setCategories(res);
        })();
    }, []);

    useEffect(() => {
        fetchArticlesOnFilterChange(0, limit, filters);
    }, [limit, filters]);

    const onLoadMoreArticles = useCallback(async () => {
        if (isArticlesLoading) {
            return;
        }
        fetchArticlesOnScroll(offset, limit, filters);
    }, [isArticlesLoading, offset, limit, filters, articles]);

    const handleSortByChange = (value) => {
        dispatch(
            HomePageArticlesActions.setFilters({
                ...filters,
                sortBy: value,
            })
        );
    };

    const handleIsLikedChange = () => {
        dispatch(
            HomePageArticlesActions.setFilters({
                ...filters,
                isLiked: !filters.isLiked,
            })
        );
    };

    const handleIsPrivateChange = () => {
        dispatch(
            HomePageArticlesActions.setFilters({
                ...filters,
                isPrivate: !filters.isPrivate,
            })
        );
    };

    const handleAllSelectOption = (filters) => {
        const isAllSelected = _.includes(filters.category, allSelectOption);
        const arr = isAllSelected ? [] : [allSelectOption];
        dispatch(
            HomePageArticlesActions.setFilters({
                ...filters,
                category: arr,
            })
        );
    };

    const handleCategoryChange = (filters, value) => {
        const filteredCategories = filters.category.filter(
            (c) => c !== allSelectOption
        );

        const isAdded = _.includes(filteredCategories, value);
        if (!isAdded) {
            dispatch(
                HomePageArticlesActions.setFilters({
                    ...filters,
                    category: [...filteredCategories, value],
                })
            );
        } else {
            const newArr = filteredCategories.filter((sc) => sc !== value);
            dispatch(
                HomePageArticlesActions.setFilters({
                    ...filters,
                    category: newArr,
                })
            );
        }
    };

    const handleCategoriesChange = (value) => {
        if (value === allSelectOption) {
            handleAllSelectOption(filters);
            return;
        }
        handleCategoryChange(filters, value);
    };

    return (
        <Container>
            <Grid container>
                <Box display={{ xs: "block", md: "none" }}>
                    <MobileFilters
                        styles={styles}
                        sortByData={sortByData}
                        filters={filters}
                        handleSortByChange={handleSortByChange}
                        isAuth={isAuth}
                        handleIsLikedChange={handleIsLikedChange}
                        categories={categories}
                        handleCategoriesChange={handleCategoriesChange}
                    />
                </Box>

                <Grid
                    container
                    item
                    sm={3}
                    lg={2}
                    className={classNames(styles.column)}
                >
                    <Box display={{ xs: "none", md: "block" }}>
                        <SortByFilter
                            data={sortByData}
                            selected={filters.sortBy}
                            onChange={handleSortByChange}
                        />
                        {isAuth && (
                            <>
                                <IsLikedFilter
                                    selected={filters.isLiked}
                                    onChange={handleIsLikedChange}
                                />
                                <IsPrivateFilter
                                    selected={filters.isPrivate}
                                    onChange={handleIsPrivateChange}
                                />
                                <Divider />
                            </>
                        )}
                        <CategoryFilter
                            data={categories}
                            selected={filters.category}
                            onChange={handleCategoriesChange}
                        />
                    </Box>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    md={9}
                    className={classNames(styles.wrapper, styles.column)}
                    ref={wrapperRef}
                >
                  {!isAuth && specificMessage !== '' &&(
                      <Grid item className={styles['welcome-message']}>
                          {specificMessage}
                      </Grid>
                  )}
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={onLoadMoreArticles}
                        hasMore={hasMoreArticles}
                        useWindow={false}
                        loader={null}
                        getScrollParent={() => wrapperRef && wrapperRef.current}
                    >
                        {/* {/* <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        > */}
                            {articles.map((article) => (
                                <ArticleCard
                                    key={article._id}
                                    article={article}
                                />
                            ))}

                            {articles.length === 0 && (
                                <h2>No articles match the filter criteria.</h2>
                            )}
                        {/* </Masonry> */}
                    </InfiniteScroll>
                    {isArticlesLoading && (
                        <div
                            className={styles["preloader-container"]}
                            key="loader"
                        >
                            <CircularProgress />
                        </div>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
