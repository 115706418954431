import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ErrorText from '../ErrorText';
import styles from './Checkbox.module.scss';

function Checkbox({
  field,
  checked,
  withError,
  containerClassName,
  inputClassName,
  placeholder,
  error,
  isTouched,
  isDisabled,
  onChange,
}) {
  const isError = !!error && isTouched;

  const handleChange = event => {
    if (field) {
      field.onChange(event);
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <label
        className={classNames(
          styles['checkbox-container'],
          containerClassName,
          {
            [styles.disabled]: isDisabled,
          },
        )}
      >
        <input
          {...field}
          type="checkbox"
          checked={(field && field.checked) || checked}
          disabled={isDisabled}
          className={styles.input}
          onChange={handleChange}
        />
        <span className={classNames(styles.checkmark, inputClassName)} />
        {placeholder && (
          <span className={classNames(styles.label, inputClassName)}>
            {placeholder}
          </span>
        )}
      </label>
      {!!field && withError && <ErrorText isShown={isError}>{error}</ErrorText>}
    </>
  );
}

Checkbox.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  withError: PropTypes.bool,
};

Checkbox.defaultProps = {
  size: 'md',
  withError: true,
};

export default Checkbox;
