import { withFormik } from 'formik';
import { compose, withState } from 'recompose';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import UserCreation from './UserCreation';
import getUserProperty from '../../../../utils/getUserProperty';

const validationSchema = Yup.object().shape({
  orcid: Yup.string().required('This field is required'),
  isEnabled: Yup.boolean(),
  isAdmin: Yup.boolean(),
});

export default compose(
  withState('isSaving', 'setIsSaving', false),
  withFormik({
    mapPropsToValues: () => ({
      orcid: '',
      isEnabled: true,
      isAdmin: false,
    }),
    validationSchema,
    handleSubmit: async (values, { setErrors, props }) => {
      const { setIsSaving, onCreateUser, onClose } = props;
      setIsSaving(true);

      // TODO: try/catch does not work for onCreateUser
      try {
        await onCreateUser(
          Object.entries(values).reduce((data, [key]) => {
            const [propertyName, value] = getUserProperty(values, key);
            return { ...data, [propertyName]: value };
          }, {}),
        );
        onClose();
        toast.success('User successfully created!');
      } catch (error) {
        setIsSaving(false);
        if (error.message.toLowerCase().includes('orcid'))
          setErrors({ orcid: error.message });
        else onClose();
      }
    },
    displayName: 'UserCreationForm',
  }),
)(UserCreation);
