import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { updateUser } from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  affiliations,
  emptyFormState,
  professions,
  validationSchema,
} from './constants';
import { useStyles } from './styles';
import { mapUserSettings } from './utilities';
import { SystemSettingsSelectors } from '../../redux/systemSettings/selectors';

const filterFn = createFilterOptions();

const UserSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const optOutEnabled = useSelector(
    SystemSettingsSelectors.selectOptOutEnabled,
  );
  const optOutText = useSelector(SystemSettingsSelectors.selectOptOutText);

  const [initialFormState, setInitialFormState] = useState(emptyFormState);
  const [formState, setFormState] = useState(emptyFormState);

  useEffect(() => {
    if (!user) {
      return;
    }
    const mapped = {
      ...user,
      profession: mapUserSettings(user.profession, professions),
      affiliation: mapUserSettings(user.affiliation, affiliations),
    };
    setFormState(mapped);
    setInitialFormState(mapped);
  }, [user]);

  const handleOnSubmit = data => {
    const mapped = {
      ...data,
      profession: data.profession.map(p => p.value),
      affiliation: data.affiliation.map(a => a.value),
    };
    dispatch(updateUser(mapped, user.hasUserCompletedOnBoarding));
  };

  const handleOnChange = (field, value, setFieldValue) => {
    setFormState({
      ...formState,
      [field]: value,
    });
    setFieldValue(field, value);
  };

  const handleAutocompleteOnChange = (field, value, setFieldValue) => {
    const mapped = value.map(v => {
      // e.g. value selected with enter, right from the input
      if (typeof v === 'string') {
        return {
          label: v,
          value: v,
        };
      }

      return v.isCustom
        ? {
            label: v.value,
            value: v.value,
          }
        : v;
    });
    handleOnChange(field, mapped, setFieldValue);
  };

  const getFilterOptions = (options, params) => {
    const filtered = filterFn(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        value: params.inputValue,
        label: `Add "${params.inputValue}"`,
        isCustom: true,
      });
    }

    return filtered;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
      className={classes.root}
    >
      <Grid item container justifyContent="center">
        <Grid item xs={6} xl={4}>
          <Paper className={classes.paper} elevation={0}>
            <Formik
              enableReinitialize={true}
              initialValues={initialFormState}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                handleOnSubmit(formState);
                setSubmitting(false);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.title}
                      >
                        To make DropCite more useful to you, please tell us a
                        bit more about yourself
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div>
                        <p>First name*</p>
                        <TextField
                          id="first-name"
                          name="fist-name"
                          variant="outlined"
                          fullWidth
                          placeholder="First Name"
                          value={formState.firstName}
                          onChange={event =>
                            handleOnChange(
                              'firstName',
                              event.target.value,
                              setFieldValue,
                            )
                          }
                          error={errors.firstName && touched.firstName}
                          helperText={
                            errors.firstName && touched.firstName
                              ? errors.firstName
                              : null
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <div>
                        <p>Last name*</p>
                        <TextField
                          id="last-name"
                          name="last-name"
                          variant="outlined"
                          fullWidth
                          placeholder="Last Name"
                          value={formState.lastName}
                          onChange={event =>
                            handleOnChange(
                              'lastName',
                              event.target.value,
                              setFieldValue,
                            )
                          }
                          error={errors.lastName && touched.lastName}
                          helperText={
                            errors.lastName && touched.lastName
                              ? errors.lastName
                              : null
                          }
                        />
                      </div>
                    </Grid>
                    {user.hasUserCompletedOnBoarding && (
                      <Grid item>
                        <div>
                          <p>
                            Profession
                            <br />
                            (Enter as many as needed)
                          </p>
                          <Autocomplete
                            id="profession"
                            value={formState.profession}
                            onChange={(event, newValue) => {
                              handleAutocompleteOnChange(
                                'profession',
                                newValue,
                                setFieldValue,
                              );
                            }}
                            filterOptions={getFilterOptions}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            multiple
                            options={professions}
                            getOptionLabel={option => option.label}
                            renderOption={option => option.label}
                            freeSolo
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Profession"
                                error={
                                  errors.profession &&
                                  touched.profession &&
                                  _.every(touched.profession, i => i)
                                }
                                helperText={
                                  errors.profession && touched.profession
                                    ? errors.profession
                                    : null
                                }
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    )}
                    {user.hasUserCompletedOnBoarding && (
                      <Grid item>
                        <div>
                          <p>
                            Affiliation
                            <br />
                            (Enter as many as needed)
                          </p>
                          <Autocomplete
                            id="affiliation"
                            value={formState.affiliation}
                            onChange={(event, newValue) => {
                              handleAutocompleteOnChange(
                                'affiliation',
                                newValue,
                                setFieldValue,
                              );
                            }}
                            filterOptions={getFilterOptions}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            multiple
                            options={affiliations}
                            getOptionLabel={option => option.label}
                            renderOption={option => option.label}
                            freeSolo
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Affiliation"
                                error={
                                  errors.affiliation &&
                                  touched.affiliation &&
                                  _.every(touched.affiliation, i => i)
                                }
                                helperText={
                                  errors.affiliation && touched.affiliation
                                    ? errors.affiliation
                                    : null
                                }
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item>
                      {optOutEnabled && (
                        <div>
                          <p>
                            {optOutText}
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={formState.optOut}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                              style={{ padding: '0px 0px 7px' }}
                              onChange={event =>
                                handleOnChange(
                                  'optOut',
                                  event.target.checked,
                                  setFieldValue,
                                )
                              }
                            />
                          </p>
                        </div>
                      )}
                    </Grid>
                    <Grid item>
                      <div className={classes.buttonContainer}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserSettings;
