import React from 'react';

import styles from './PageNavigation.module.scss';
import PagePercentageLine from './PagePercentageLine';

function PageNavigation(props) {
  const {
    isThumbnailsRendered,
    pdfThumbnails,
    statistic,
    maxCommentsCount,
    onPageClick,
  } = props;

  const handlePageClick = pageNumber => () => {
    onPageClick(pageNumber);
  };

  return (
    <div className={styles.navigation} id="thumbnails-viewport">
      {pdfThumbnails.map(page => (
        <div
          key={page._pageIndex}
          className={styles['thumbnail-container']}
          onClick={handlePageClick(page._pageIndex + 1)}
        >
          <canvas className={styles['thumbnail-canvas']}></canvas>
          {isThumbnailsRendered && statistic[page._pageIndex + 1] && (
            <PagePercentageLine
              pageStatistic={statistic[page._pageIndex + 1]}
              maxCommentsCount={maxCommentsCount}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default PageNavigation;
