import React, { useState } from 'react';
import classNames from 'classnames';

import PdfHighlighter from '../../../components/pdf/PdfHighlighter';
import Popup from '../../../components/pdf/Popup';
import Highlight from '../../../components/pdf/Highlight';
import HighlightForm from '../HighlightForm';
import PdfScalePanel from '../PdfScalePanel';
import styles from './PdfContent.module.scss';
import useHighlights from './useHighlights';
import usePdfScale from '../usePdfScale';
import { useSelector } from 'react-redux';

function PdfContent(props) {
  const {
    comments,
    pdfHighlighterRef,
    handlePdfClick,
    pdfDocument,
    renderTitle,
    onPdfScroll,
  } = props;
  const highlights = useHighlights(comments);
  const authUserId = useSelector(state => state.auth.user._id);
  const [isHighlighting, setIsHighlighting] = useState(false);

  const {
    scale,
    isAutoScale,
    setInitialScaleValue,
    setAutoScale,
    zoomIn,
    zoomOut,
  } = usePdfScale();
  return (
    <div
      onMouseDown={() => {
        setIsHighlighting(true);
      }}
      onMouseUp={() => {
        setIsHighlighting(false);
      }}
      className={classNames(styles.highlighter, {
        [styles['highlighter--disabled']]: isHighlighting,
      })}
    >
      <PdfScalePanel
        isZoomChanged={!isAutoScale}
        onZoomIn={zoomIn}
        onZoomOut={zoomOut}
        onReset={setAutoScale}
      />
      <PdfHighlighter
        comments={comments}
        pdfDocument={pdfDocument}
        highlights={highlights}
        pdfScaleValue={isAutoScale ? 'auto' : scale}
        scrollRef={pdfHighlighterRef}
        onClick={handlePdfClick}
        onInitialScaleSet={setInitialScaleValue}
        renderBefore={renderTitle}
        onPdfScroll={onPdfScroll}
        onSelectionFinished={(
          position,
          content,
          hideTipAndSelection,
          transformSelection,
        ) => {
          return (
            <HighlightForm
              onOpen={transformSelection}
              highlight={{
                text: content.text,
                position,
              }}
              onCommentSent={hideTipAndSelection}
            />
          );
        }}
        highlightTransform={(
          highlight,
          index,
          setTip,
          hideTip,
          _viewportToScaled,
          _screenshot,
          isScrolledTo,
        ) => {
          return (
            <Popup
              popupContent={
                highlight.comment && (
                  <HighlightForm
                    highlight={{
                      text: highlight.comment.text,
                      position: highlight.originPosition,
                    }}
                    onCommentSent={hideTip}
                  />
                )
              }
              onMouseOver={popupContent =>
                setTip(highlight, _highlight => popupContent)
              }
              onMouseOut={hideTip}
              key={index}
              children={
                <Highlight
                  id={highlight.id}
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  hashtag={highlight.comment ? highlight.comment.hashtag : null}
                  commentUserId={highlight.comment ? highlight.comment.userId : null}
                  authUserId={authUserId}
                  createdAt={highlight.comment ? highlight.comment.createdAt : null}
                />
              }
            />
          );
        }}
      />
    </div>
  );
}

export default PdfContent;
