import api from '../../api';
import * as MODALS from '../../components/Modal/modalNames';
import * as actionTypes from './actionTypes';
import { isUserAuthSelector } from './selectors';
import { show as showModal, hide as hideModal } from '../modals/actions';
import history from '../../services/history';
import { ROUTES } from '../../constants/routes';
import { toast } from 'react-toastify';

export const setLoginSuccess = (token, user) => dispatch => {
  dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: { token, user } });
};

export function login(orcidCode) {
  return dispatch => {
    dispatch({ type: actionTypes.LOGIN_REQUEST });
    api.auth
      .login(orcidCode)
      .then(res => {
        const { token, user } = res.data;
        api.client.setToken(token);
        dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: { token, user } });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.LOGIN_FAILURE,
          payload: { error: error.code ? error.message : null },
        });
      });
  };
}

export function logout() {
  return dispatch => {
    api.client.removeToken();
    dispatch({
      type: actionTypes.LOGOUT,
    });
  };
}

export function passOnlySignedIn() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const isUserAuth = isUserAuthSelector(getState());
      const isOnBoardingCompleted = getState().auth.user
        .hasUserCompletedOnBoarding;

      if (isUserAuth && isOnBoardingCompleted) {
        resolve();
        return;
      }

      dispatch(showModal({ name: MODALS.LOGIN_TO_ACTION })).then(action => {
        if (action !== 'cancel') {
          localStorage.setItem('goBackPathname', window.location.pathname);
          dispatch(hideModal(MODALS.LOGIN_TO_ACTION));

          if (action === 'signin') history.push(ROUTES.signIn);
          else if (action === 'onboarding') history.push(ROUTES.onboarding);
        }

        dispatch(hideModal(MODALS.LOGIN_TO_ACTION));
      });

      reject();
    });
  };
}

export function refreshUserData() {
  return (dispatch, getState) => {
    api.users.current().then(res => {
      dispatch({
        type: actionTypes.REFRESH_USER_DATA,
        payload: { user: res.data },
      });
    });
  };
}

export const updateUser = (
  data,
  hasUserCompletedOnBoarding,
) => async dispatch => {
  try {
    const res = await api.users.update(data);
    dispatch({
      type: actionTypes.REFRESH_USER_DATA,
      payload: { user: res.data },
    });
    toast.success('User update successful');

    if (!hasUserCompletedOnBoarding && res.data.hasUserCompletedOnBoarding) {
      const goBackPathname = localStorage.getItem('goBackPathname');
      localStorage.removeItem('goBackPathname');
      history.push(goBackPathname ? goBackPathname : ROUTES.home);
    }
  } catch (err) {
    toast.error('Something went wrong');
    console.log(err);
  }
};

export function checkAuth() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const isUserAuth = isUserAuthSelector(getState());
      const isOnBoardingCompleted = getState().auth.user
        .hasUserCompletedOnBoarding;

      if (isUserAuth && isOnBoardingCompleted) {
        resolve();
        return;
      } else if (isUserAuth && !isOnBoardingCompleted) {
        localStorage.setItem('goBackPathname', window.location.pathname);
        history.push(ROUTES.onboarding);
      } else {
        localStorage.setItem('goBackPathname', window.location.pathname);
        history.push(ROUTES.signIn);
      }
      reject();
    });
  };
}
