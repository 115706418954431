import React from 'react';
import classNames from 'classnames';

import styles from './Divider.module.scss';

const Divider = ({ className }) => (
  <div className={classNames(styles.divider, className)} />
);

export default Divider;
