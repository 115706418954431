import React, { useEffect, useState } from 'react';
import api from '../../api';
import ArticleForm from '../../components/ArticleForm/ArticleForm';
import history from '../../services/history';
import CircularProgress from '../../components/CircularProgress';
import { useLocation } from 'react-router-dom';

const Article = () => {
  const emptyArticle = {
    fileTitle: '',
    title: '',
    authors: '',
    authorsCorresponding: '',
    isPrivate: false,
    isPrivateCommentsOnly: false,
    categories: [],
  };
  const location = useLocation();
  const [id] = useState(location.state.id);
  const [editArticle] = useState(location.state.editArticle);
  const [categories, setCategories] = useState([]);
  const [article, setArticle] = useState(emptyArticle);
  const [isLoading, setLoading] = useState(true);

  const fetchCategories = async () => {
    try {
      return await api.categories.list();
    } catch (err) {
      console.error(err);
    }
  };

  const fetchArticle = async () => {
    try {
      return await api.articles.getArticle(id);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await fetchCategories();
      setCategories(res);
      if (editArticle) {
        const articleResult = await fetchArticle();
        setArticle(articleResult.data);
      }
      setLoading(false);
    })();
  }, []);

  const handleSubmit = async (metaData, file) => {
    try {
      const data = new FormData();
      const onlyCategoryIds = metaData.categories.map(s => s.id);
      const newData = { ...metaData, categories: onlyCategoryIds };
      data.append('file', file);
      data.append('metaData', JSON.stringify(newData));
      const res = await api.articles.createArticle(data);
      await history.push(`articles/${res.data.doi}`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitEdit = async metaData => {
    try {
      const onlyCategoryIds = metaData.categories.map(s => s.id);
      const data = { ...metaData, categories: onlyCategoryIds };
      const res = await api.articles.editArticle(data);
      await history.push(`articles/${res.data.doi}`);
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <ArticleForm
      article={editArticle ? article : emptyArticle}
      submitHandler={editArticle ? handleSubmitEdit : handleSubmit}
      categories={categories}
      isEdit={!!editArticle}
    />
  );
};

export default Article;
