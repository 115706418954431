import React from 'react';

import Preloader from '../Preloader';
import styles from './PagePreloader.module.scss';

function PagePreloader() {
  return (
    <div className={styles.wrapper}>
      <Preloader />
    </div>
  );
}

export default PagePreloader;
