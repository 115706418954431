import { createStore, applyMiddleware, compose as simpleCompose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { persistStore } from 'redux-persist';

import reducer from './reducer';

const compose =
  process.env.NODE_ENV === 'production' ? simpleCompose : composeWithDevTools;

const store = createStore(
  reducer,
  compose(applyMiddleware(thunkMiddleware)),
);

const persistor = persistStore(store);

export { store as default, persistor };
