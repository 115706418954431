import { AppState } from '../type';
import { createSelector } from 'reselect';

export default class MyActivitiesSelectors {
  static selectState = (state: AppState) => state.myActivities;

  static selectIsLoading = createSelector(
    MyActivitiesSelectors.selectState,
    state => state.isLoading,
  );

  static selectData = createSelector(
    MyActivitiesSelectors.selectState,
    state => state.data,
  );

  static selectUserActivities = createSelector(
    MyActivitiesSelectors.selectData,
    (data: any) => (data ? data.userNotifications : []),
  );

  static selectGlobalActivities = createSelector(
    MyActivitiesSelectors.selectData,
    (data: any) => (data ? data.globalNotifications : []),
  );
}
