import React from 'react';
import classNames from 'classnames';

import Icon, { ICON_NAMES } from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';

import styles from './ArticleRecommendations.module.scss';

export default function ArticleRecommendations({
  articleId,
  likesCount,
  isLiked,
  createLike,
  removeLike,
}) {
  const handleLikeClick = () => {
    if (isLiked) {
      removeLike(articleId);
    } else {
      createLike(articleId);
    }
  };

  const tooltipText = isLiked
    ? 'Click to remove your upvote'
    : 'Click to upvote article';

  return (
    <div className={styles.card}>
      <p className={styles.title}>Article Upvotes</p>
      <div className="d-flex justify-content-center mt-1">
        <Tooltip
          placement="bottom"
          text={tooltipText}
          triggers={['hover', 'focus']}
        >
          <button
            type="button"
            className={classNames(styles['btn-like'], {
              [styles['btn-like--active']]: isLiked,
            })}
            onClick={handleLikeClick}
          >
            <Icon name={ICON_NAMES.like} />
          </button>
        </Tooltip>
        <p className={styles.likesCount}>{likesCount}</p>
      </div>
    </div>
  );
}
