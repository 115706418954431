import { compose, withPropsOnChange, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';

import PageNavigation from './PageNavigation';
import { getStatistic } from '../../../redux/comments/actions';
import {
  mappedStatisticSelector,
  maxCommentsCountSelector,
} from '../../../redux/comments/selectors';
import scrollTo from '../../../utils/scrollTo';

export default compose(
  connect(
    state => {
      return {
        article: state.articles.data,
        statistic: mappedStatisticSelector(state),
        maxCommentsCount: maxCommentsCountSelector(state),
      };
    },
    { getStatistic },
  ),
  withState('pdfThumbnails', 'setPdfThumbnails', []),
  withState('isThumbnailsRendered', 'setIsThumbnailsRendered', false),
  withHandlers({
    renderThumbnail: ({ setIsThumbnailsRendered }) => page => {
      const pdfViewport = page.getViewport({ scale: 1 });
      const container = document.getElementById('thumbnails-viewport').children[
        page._pageIndex
      ];
      const canvas = container.children[0];
      const context = canvas.getContext('2d');
      canvas.height = pdfViewport.height;
      canvas.width = pdfViewport.width;

      page
        .render({
          canvasContext: context,
          viewport: pdfViewport,
        })
        .promise.then(() => setIsThumbnailsRendered(true));
    },
  }),
  withHandlers({
    loadPdfThumbnails: ({ setPdfThumbnails, pdfDocument }) => () => {
      const renderPagesPromises = [];
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        renderPagesPromises.push(pdfDocument.getPage(i));
      }
      Promise.all(renderPagesPromises).then(pages => setPdfThumbnails(pages));
    },
    onPageClick: () => pageNumber => {
      scrollTo(
        document.querySelector(`[data-article-container]`),
        document.querySelector(`[data-page-number="${pageNumber}"]`),
      );
    },
  }),
  withPropsOnChange([], ({ loadPdfThumbnails }) => {
    loadPdfThumbnails();
  }),
  withPropsOnChange(['article'], ({ article, getStatistic }) => {
    if (article) getStatistic();
  }),
  withPropsOnChange(['pdfThumbnails'], ({ pdfThumbnails, renderThumbnail }) => {
    setTimeout(() => {
      pdfThumbnails.forEach(page => renderThumbnail(page));
    });
  }),
)(PageNavigation);
