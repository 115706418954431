import React from 'react';
import { Box, Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        listStyle: 'none',
        padding: theme.spacing(1),
        margin: 0,
        width: '100%',
    },
    chip: {
        margin: theme.spacing(0.5),
        borderRadius: 3,
        width: '100%',
    },
}));

export const IsPrivateFilter = ({ selected, onChange }) => {
    const classes = useStyles();

    const handleClick = () => onChange();

    return (
        <Box component="ul" className={classes.root}>
            <li key="key-isPrivate">
                <Chip
                    color={selected ? 'secondary' : 'default'}
                    label="My Private"
                    onClick={handleClick}
                    className={classes.chip}
                />
            </li>
        </Box>
    );
};