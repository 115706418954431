import themeStyles from '../assets/styles/theme.scss';

export const COMMENT_PREDEFINED_HASHTAGS = {
  praise: 'praise',
  issue: 'issue',
  query: 'query',
  common: 'common',
};

export const COMMENT_HASHTAG_COLORS = {
  [COMMENT_PREDEFINED_HASHTAGS.praise]: themeStyles.praiseCommentColor,
  [COMMENT_PREDEFINED_HASHTAGS.issue]: themeStyles.issueCommentColor,
  [COMMENT_PREDEFINED_HASHTAGS.query]: themeStyles.queryCommentColor,
  [COMMENT_PREDEFINED_HASHTAGS.common]: themeStyles.commonCommentColor,
};

export const COMMENT_COLORS_ORDER = {
  [COMMENT_HASHTAG_COLORS.query]: 0,
  [COMMENT_HASHTAG_COLORS.praise]: 1,
  [COMMENT_HASHTAG_COLORS.issue]: 2,
  [COMMENT_HASHTAG_COLORS.common]: 3,
};

export const HASHTAG_COMMON_LABEL = 'Other';

export const COMMENT_TYPE_FOR_HIGHLIGHT ={
  myComment: 'myComment',
  newComment: 'newComment',
  oldComment: 'oldComment'
}