import api from '../../api';
import * as actionTypes from './actionTypes';
import { show as showModal, hide as hideModal } from '../modals/actions';
import waitElement from '../../utils/waitElement';
import scrollTo from '../../utils/scrollTo';
import { isCommentAppropriate } from './utils';
import * as MODALS from '../../components/Modal/modalNames';
import { passOnlySignedIn } from '../auth/actions';
import logger from '../../services/logger';

const COMMENTS_COLUMN_ID = '#comments-column';

export function createThread(comment) {
  return async dispatch => {
    const { data, serverDate } = await api.comments.createThread(comment);

    dispatch({
      type: actionTypes.APPEND_COMMENT,
      payload: { comment: { ...data, serverDate } },
    });

    dispatch(getComments());
    dispatch(getStatistic());
    dispatch(getFilters(data.articleId));
    return data;
  };
}

export function deleteThread(threadId) {
  return dispatch => {
    dispatch(showModal({ name: MODALS.CONFIRMATION }))
      .then(async action => {
        if (action.name === 'confirm') {
          const response = await api.comments.deleteThread(threadId);
          dispatch({
            type: actionTypes.DELETE_COMMENT,
            payload: response.data,
          });

          dispatch(getComments());
          dispatch(getStatistic());
          dispatch(getFilters(response.data.articleId));
        }
        return action;
      })
      .finally(() => {
        dispatch(hideModal(MODALS.CONFIRMATION));
      });
  };
}

export function addHighlight(highlight) {
  return dispatch => {
    dispatch({
      type: actionTypes.ADD_HIGHLIGHT,
      payload: { highlight },
    });
  };
}

export function setSelectedHighlights(selectedHighlights) {
  return {
    type: actionTypes.SET_SELECTED_HIGHLIGHTS,
    payload: { selectedHighlights },
  };
}

export function resetSelectedHighlights() {
  return {
    type: actionTypes.RESET_SELECTED_HIGHLIGHTS,
  };
}

export function cancelThreadCreation() {
  return {
    type: actionTypes.CANCEL_COMMENT_CREATION,
  };
}

export function appendComment(comment) {
  return (dispatch, getState) => {
    const {
      articles: { data: articleData },
      comments: { filter },
      auth: { user },
    } = getState();
    const { _id: articleId } = articleData || {};
    if (
      comment.articleId !== articleId ||
      comment.user._id === user._id ||
      !isCommentAppropriate({ comment, filter })
    )
      return;

    dispatch({
      type: actionTypes.APPEND_COMMENT,
      payload: { comment },
    });
    dispatch(getStatistic());
  };
}

export function removeComment(comment) {
  return (dispatch, getState) => {
    const {
      articles: { data: articleData },
      auth: { user },
    } = getState();
    const { _id: articleId } = articleData || {};
    if (comment.articleId !== articleId || comment.user._id === user._id)
      return;

    dispatch({
      type: actionTypes.DELETE_COMMENT,
      payload: comment,
    });
    dispatch(getStatistic());
  };
}

export function getComments() {
  return async (dispatch, getState) => {
    const { articles, comments } = getState();
    const {
      data: {
        list,
        totalThreadsCount,
        totalRepliesCount,
        filteredThreadsCount,
        filteredRepliesCount,
        commentsCountByHashtags,
      },
      serverDate,
    } = await api.comments.threads(articles.data._id, comments.filter);

    dispatch({
      type: actionTypes.GET_COMMENTS_SUCCESS,
      payload: {
        list: list.map(comment => ({ ...comment, serverDate })),
        totalThreadsCount,
        totalRepliesCount,
        filteredThreadsCount,
        filteredRepliesCount,
        commentsCountByHashtags,
      },
    });
  };
}

export function getReplies(threadId) {
  return async (dispatch, getState) => {
    const { comments } = getState();
    const { data, serverDate } = await api.comments.replies(
      threadId,
      comments.filter,
    );
    dispatch({
      type: actionTypes.GET_REPLIES_SUCCESS,
      payload: {
        threadId,
        list: data.map(reply => ({ ...reply, serverDate })),
      },
    });
  };
}

export function setReplyingComment(id) {
  return {
    type: actionTypes.SET_REPLYING_COMMENT,
    payload: { id },
  };
  // return dispatch => {
  //   dispatch(passOnlySignedIn()).then(() => {
  //     dispatch({
  //       type: actionTypes.SET_REPLYING_COMMENT,
  //       payload: { id },
  //     });
  //   });
  // };
}

export function setAreRepliesShown(threadId, areRepliesShown) {
  return {
    type: actionTypes.SET_ARE_REPLIES_SHOWN,
    payload: { threadId, areRepliesShown },
  };
}

export function createReply(reply) {
  return async (dispatch, getState) => {
    const {
      comments: { list, filter },
    } = getState();
    const { data, serverDate } = await api.comments.createReply(reply);
    dispatch(setReplyingComment(null));

    const { threadId } = reply;
    const thread = list.find(comment => comment._id === threadId);

    // if (!thread.replies || !thread.areRepliesShown) {
    //   dispatch(setAreRepliesShown(reply.threadId, true));
    // }

    if (thread.replies) {
      dispatch({
        type: actionTypes.APPEND_REPLY,
        payload: { ...data, serverDate },
      });
    } else {
      dispatch(getReplies(threadId, filter));
    }

    dispatch(getComments());
    dispatch(getStatistic());
    dispatch(getReplies(threadId, filter));
    // TODO: remove scrolling if dont need
    // const container = document.querySelector(COMMENTS_COLUMN_ID);
    // const element = await waitElement(data._id);
    // scrollTo(container, element);
  };
}

export function deleteReply(replyId) {
  return async dispatch => {
    const response = await api.comments.deleteReply(replyId);
    dispatch({
      type: actionTypes.DELETE_REPLY,
      payload: response.data,
    });
    dispatch(getComments());
    dispatch(getReplies());
    dispatch(getStatistic());
  };
}

export function appendReply(reply) {
  return async (dispatch, getState) => {
    const {
      articles: { data: articleData, activeFragment },
      comments: { list, filter },
      auth: { user },
    } = getState();
    const { _id: articleId } = articleData || {};

    if (
      reply.articleId !== articleId ||
      reply.user._id === user._id ||
      !isCommentAppropriate({
        comment: reply,
        filter: { ...filter, hashtags: [] },
      })
    )
      return;

    const { threadId } = reply;
    let thread = list.find(comment => comment._id === threadId);
    if (!thread) {
      ({ data: thread } = await api.comments.getThread(threadId));
      dispatch({
        type: actionTypes.APPEND_COMMENT,
        payload: { comment: thread },
      });
    }

    dispatch(getReplies());
    dispatch(getStatistic());

    if (reply.fragmentId === activeFragment) {
      if (thread.areRepliesShown) {
        dispatch({
          type: actionTypes.APPEND_REPLY,
          payload: reply,
        });
      } else {
        const { threadId } = reply;
        dispatch(setAreRepliesShown(threadId, true));
        dispatch(getReplies(threadId, filter));
      }

      const container = document.querySelector(COMMENTS_COLUMN_ID);
      const element = await waitElement(reply._id);
      scrollTo(container, element);
    } else {
      dispatch({
        type: actionTypes.INCREASE_REPLIES_COUNT,
        payload: reply,
      });
    }
  };
}

export function removeReply(reply) {
  return (dispatch, getState) => {
    const {
      articles: { data: articleData, activeFragment },
      comments: { list, filter },
      auth: { user },
    } = getState();
    const { _id: articleId } = articleData || {};

    if (reply.articleId !== articleId || reply.user._id === user._id) return;

    dispatch(getStatistic());

    if (reply.fragmentId === activeFragment) {
      const thread = list.find(comment => comment._id === reply.threadId);
      dispatch({
        type: actionTypes.DELETE_REPLY,
        payload: reply,
      });

      const repliesCount = thread.repliesCount - 1;
      if (
        repliesCount === 0 &&
        !isCommentAppropriate({ comment: thread, filter })
      ) {
        dispatch({
          type: actionTypes.DELETE_COMMENT,
          payload: thread,
        });
      }
    }
  };
}

export function createLike(commentId) {
  return dispatch => {
    dispatch(passOnlySignedIn()).then(() => {
      api.comments.createLike(commentId).then(() => {
        dispatch({
          type: actionTypes.CREATE_LIKE_SUCCESS,
          payload: { commentId },
        });
      });
    });
  };
}

export function removeLike(commentId) {
  return dispatch => {
    api.comments.removeLike(commentId).then(() => {
      dispatch({
        type: actionTypes.REMOVE_LIKE_SUCCESS,
        payload: { commentId },
      });
    });
  };
}

export function createReplyLike(threadId, replyId) {
  return dispatch => {
    dispatch(passOnlySignedIn()).then(() => {
      api.comments.createLike(replyId).then(() => {
        dispatch({
          type: actionTypes.CREATE_REPLY_LIKE_SUCCESS,
          payload: { threadId, replyId },
        });
      });
    });
  };
}

export function removeReplyLike(threadId, replyId) {
  return dispatch => {
    api.comments.removeLike(replyId).then(() => {
      dispatch({
        type: actionTypes.REMOVE_REPLY_LIKE_SUCCESS,
        payload: { threadId, replyId },
      });
    });
  };
}

export function shareComment(commentId) {
  return dispatch => {
    api.comments.shareComment(commentId).then(() => {
      dispatch({
        type: actionTypes.SHARE_COMMENT_SUCCESS,
        payload: { commentId },
      });
    });
  };
}

export function getStatistic() {
  return (dispatch, getState) => {
    const { articles, comments } = getState();
    api.comments
      .getStatistic(articles.data._id, comments.filter)
      .then(response => {
        dispatch({
          type: actionTypes.GET_STATISTIC_SUCCESS,
          payload: { statistic: response.data },
        });
      });
  };
}

export function applyFilter(values) {
  return dispatch => {
    dispatch({ type: actionTypes.APPLY_FILTER, payload: { values } });
    dispatch(getComments());
    dispatch(getStatistic());
  };
}

export function resetFilter() {
  return dispatch => {
    dispatch({ type: actionTypes.RESET_FILTER });
    dispatch(getComments());
    dispatch(getStatistic());
  };
}

export const getFilters = articleId => async dispatch => {
  try {
    const { data } = await api.comments.getFilter(articleId);
    dispatch({ type: actionTypes.GET_FILTERS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: actionTypes.GET_FILTERS_ERROR, payload: null });
  }
};
