import React from 'react';
import ReactModal from 'react-overlays/Modal';
import classNames from 'classnames';

import Divider from '../Divider';
import CircularProgress from '../CircularProgress';
import Icon, { ICON_NAMES } from '../Icon';

import styles from './Modal.module.scss';

const Modal = ({
  isShown,
  title,
  footer,
  children,
  onHide,
  className,
  isLoading,
  ...props
}) => {
  const renderBackdrop = props => (
    <div className={styles.backdrop} {...props}>
      {isLoading && <CircularProgress />}
    </div>
  );

  return isLoading ? (
    renderBackdrop()
  ) : (
    <ReactModal
      show={isShown && !isLoading}
      backdrop="static"
      renderBackdrop={renderBackdrop}
      className={classNames(
        styles.modal,
        className,
        'ignore-react-onclickoutside',
      )}
      {...props}
    >
      <div>
        <div className={styles['header']}>
          <h3>{title}</h3>
          <button
            className={styles['close-btn']}
            type="button"
            onClick={onHide}
          >
            <Icon className={styles['close-icon']} name={ICON_NAMES.close} />
          </button>
        </div>
        {children}
        {!!footer && (
          <>
            <Divider className={styles.divider} />
            <div className="d-flex justify-content-end">
              {footer.map((element, index) => (
                // eslint-disable-next-line
                <div key={index} className={styles['footer-element']}>
                  {element}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </ReactModal>
  );
};

export default Modal;
