import React from 'react';
import { Link } from 'react-router-dom';
import MaintenanceImg from '../../assets/images/maintenance.svg';

import styles from './Maintenance.module.scss';
import { ROUTES } from '../../constants/routes';

export const Maintenance = () => {
  return (
    <div className={styles.content}>
      <div className={styles.body}>
        <div className={styles.textContainer}>
          <h2 className={styles['mainHeader']}>Oops...</h2>
          <h3 className={styles['subHeader']}>
            DropCite is currently down for maintenance
          </h3>
          <Link to={ROUTES.home} className={styles.goBack}>
            Refresh
          </Link>
        </div>
        <div>
          <img src={MaintenanceImg} alt="Maintenance" />
        </div>
      </div>
    </div>
  );
};
