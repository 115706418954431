import React from 'react';

import styles from './HashtagRadio.module.scss';

function HashtagRadio(props) {
  const { field, value, placeholder, checked } = props;

  return (
    <label className={styles['input-container']}>
      <input
        type="radio"
        {...field}
        value={value}
        className={styles.input}
        checked={checked}
      />
      <span className={`${styles.label} ${styles[`label--${value}`]}`}>
        {placeholder}
      </span>
    </label>
  );
}

export default HashtagRadio;
