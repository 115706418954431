import api from '../../api';
import logger from '../../services/logger';
import {
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS_FILTER,
  FETCH_ARTICLES_SUCCESS_SCROLL,
  SET_FILTERS,
} from './actionTypes';

export class HomePageArticlesActions {
  static fetchArticlesOnFilterChange = (
    offset,
    limit,
    filters,
  ) => async dispatch => {
    try {
      dispatch({ type: FETCH_ARTICLES_REQUEST });
      const res = await api.articles.list(offset, limit, filters);
      dispatch({ type: FETCH_ARTICLES_SUCCESS_FILTER, payload: res });
    } catch (error) {
      logger(error);
      dispatch({
        type: FETCH_ARTICLES_FAILURE,
        payload: { error: error.code ? error : null },
      });
    }
  };

  static fetchArticlesOnScroll = (offset, limit, filters) => async dispatch => {
    try {
      dispatch({ type: FETCH_ARTICLES_REQUEST });
      const res = await api.articles.list(offset, limit, filters);
      dispatch({ type: FETCH_ARTICLES_SUCCESS_SCROLL, payload: res });
    } catch (error) {
      logger(error);
      dispatch({
        type: FETCH_ARTICLES_FAILURE,
        payload: { error: error.code ? error : null },
      });
    }
  };

  static setFilters = value => dispatch =>
    dispatch({ type: SET_FILTERS, payload: value });
}
