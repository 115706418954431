import queryString from 'query-string';

export default function getInitialFilterFromLocation(location) {
  const { user } = queryString.parse(location.search);

  const filter = {};

  if (user) filter.user = user;

  return filter;
}
