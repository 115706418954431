import { useMemo } from 'react';

import {
  COMMENT_HASHTAG_COLORS,
  COMMENT_COLORS_ORDER,
  HASHTAG_COMMON_LABEL,
} from '../constants/comments';

const getHashtagColor = hashtag =>
  COMMENT_HASHTAG_COLORS[hashtag] || COMMENT_HASHTAG_COLORS.common;

const useCommentsHashtagsSegments = commentCounts => {
  const mappedSegments = useMemo(
    () =>
      commentCounts
        .reduce((segments, { hashtag, count }) => {
          const hashtagColor = getHashtagColor(hashtag);
          const foundIndex = segments.findIndex(
            ({ color }) => color === hashtagColor,
          );

          if (foundIndex > -1) {
            const foundCommentCount = segments.splice(foundIndex, 1)[0];
            return [
              ...segments,
              {
                color: foundCommentCount.color,
                count: foundCommentCount.count + count,
              },
            ];
          }

          return [
            ...segments,
            {
              color: hashtagColor,
              count,
              hashtag: hashtag || HASHTAG_COMMON_LABEL,
            },
          ];
        }, [])
        .sort(
          (segment1, segment2) =>
            COMMENT_COLORS_ORDER[segment1.color] -
            COMMENT_COLORS_ORDER[segment2.color],
        ),
    [commentCounts],
  );

  return mappedSegments;
};

export default useCommentsHashtagsSegments;
