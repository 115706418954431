import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  articleTitleSize: null,
  articlePageSize: null,
  commentsFilterSize: null,
  pagesCount: 0,
  allowSyncScroll: true,
  limitedSectionScrolling: null,
};

function commentsAlignUI(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.SET_BLOCK_SIZE:
      return {
        ...state,
        ...payload.blocks,
      };

    case actionTypes.SET_ALLOW_SYNC_SCROLL:
      return {
        ...state,
        allowSyncScroll: payload.isEnabled,
      };

    case actionTypes.SET_LIMITED_SECTION_SCROLLING:
      return {
        ...state,
        limitedSectionScrolling: payload.sectionNumber,
      };

    default:
      return state;
  }
}

export default commentsAlignUI;
