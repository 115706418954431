import React from 'react';
import classNames from 'classnames';

import styles from './ErrorText.module.scss';

export default function ErrorText({ isShown, children }) {
  return (
    <div className={classNames(styles.error, { [styles.hidden]: !isShown })}>
      {children}
    </div>
  );
}
