import { connect } from 'react-redux';

import { setLimitedSectionScrolling } from '../../../redux/commentsAlignUI/actions';
import CommentsSection from './CommentsSection';

export default connect(
  state => ({
    commentsAlignUI: state.commentsAlignUI,
    limitedSectionScrolling: state.commentsAlignUI.limitedSectionScrolling,
  }),
  { setLimitedSectionScrolling },
)(CommentsSection);
