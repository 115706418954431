import { compose, getContext, withProps, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import CommentsBar from './CommentsBar';
import * as commentsActions from '../../../redux/comments/actions';
import * as commentsAlignUIActions from '../../../redux/commentsAlignUI/actions';
import getInitialFilterFromLocation from '../utils/getInitialFilterFromLocation';

const COMMENT_HEIGHT = 30;

export default compose(
  getContext({
    showFilter: PropTypes.func,
  }),
  connect(
    state => {
      return {
        comments: state.comments.list,
        selectedHighlights: state.comments.selectedHighlights,
        isAuth: state.auth.isAuth,
        pagesCount: state.commentsAlignUI.pagesCount,
        commentsAlignUI: state.commentsAlignUI,
      };
    },
    {
      getComments: commentsActions.getComments,
      applyFilter: commentsActions.applyFilter,
      setLimitedSectionScrolling:
        commentsAlignUIActions.setLimitedSectionScrolling,
    },
  ),
  withRouter,
  withProps(props => {
    const initialFilter = getInitialFilterFromLocation(props.location);

    return {
      initialFilter,
      hasInitialFilter: Object.keys(initialFilter).length > 0,
    };
  }),
  withPropsOnChange(
    ['isAuth'],
    ({
      hasInitialFilter,
      initialFilter,
      getComments,
      applyFilter,
      showFilter,
    }) => {
      if (hasInitialFilter) {
        applyFilter(initialFilter);
        showFilter(true);
      } else getComments();
    },
  ),
  withPropsOnChange(
    ['selectedHighlights'],
    ({ selectedHighlights, setLimitedSectionScrolling }) => {
      if (!selectedHighlights.length) return;

      const highlightToScroll = selectedHighlights[0];

      if (!highlightToScroll.scrollOff) {
        setLimitedSectionScrolling((highlightToScroll.pageNumber || 1) - 1);

        setTimeout(() => {
          const scrollToComment = document.querySelector(
            `[data-comment-id="${highlightToScroll.id}"]`,
          );

          let highlightOffset = highlightToScroll.offset;

          if (highlightOffset === null) {
            const highlightNode = document.querySelector(
              `[data-highlight-id="${highlightToScroll.id}"]`,
            );

            if (highlightNode)
              highlightOffset = highlightNode.getBoundingClientRect().top;
          }

          if (!highlightOffset) return;

          const sectionNode = document.querySelector(
            `[data-section="${(highlightToScroll.pageNumber || 1) - 1}"]`,
          );

          sectionNode.scrollTo(
            0,
            sectionNode.scrollTop +
              scrollToComment.getBoundingClientRect().top -
              highlightOffset +
              COMMENT_HEIGHT / 2,
          );
        });
      }
    },
  ),
)(CommentsBar);
