import React from 'react';
import {
  FormControl,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    background: 'white',
  },
}));

export const SortByFilter = ({ data, selected, onChange }) => {
  const classes = useStyles();

  const handleChange = event => onChange(event.target.value);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        id="sort-by"
        value={selected}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <ImportExportIcon />
          </InputAdornment>
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {data.map(d => (
          <MenuItem key={`key-${d.id}`} value={d.value}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
