import Base from './base';

export default class Articles extends Base {
  list(offset, limit, filter) {
    return this.apiClient
      .get('articles', { offset, limit, filter })
      .then(res => res.data);
  }

  itemPublic(doi) {
    return this.apiClient.get('articles/public',  doi );
  }

  itemPrivate(fingerprint) {
    return this.apiClient.get(`articles/private/${fingerprint}`);
  }

  createLike(articleId) {
    return this.apiClient.post(`articles/${articleId}/like`);
  }

  removeLike(articleId) {
    return this.apiClient.delete(`articles/${articleId}/like`);
  }

  getVersions(doi) {
    return Promise.resolve({ data: [1], status: 1 }); //this.apiClient.get(`articles/versions`, { doi });
  }

  getPdfUploadSignedUrl(fingerprint) {
    return this.apiClient.get('/articles/pdf/uploadSignedUrl', { fingerprint });
  }

  getPdfDownloadSignedUrl(fingerprint) {
    return this.apiClient.get('/articles/pdf/downloadSignedUrl', {
      fingerprint,
    });
  }

  comfirmPdfUpload(fingerprint, title, fileName) {
    return this.apiClient.post('/articles/pdf/confirm', {
      fingerprint,
      title,
      fileName,
    });
  }

  setFingerprint(articleId, fingerprint) {
    return Promise.resolve({
      data: { _id: articleId },
      fingerprint: fingerprint,
    });
    // return this.apiClient.post('/articles/fingerprint', {
    //   articleId,
    //   fingerprint,
    // });
  }

  getDoiById(id) {
    return this.apiClient
      .get(`articles/doi?articleId=${id}`)
      .then(res => res.data);
  }

  deleteArticle(articleId) {
    return this.apiClient.delete(`articles/${articleId}`);
  }

  createArticle( data) {
    return this.apiClient.post('articles',  data);
  }

  getArticle(articleId){
    return this.apiClient.get(`articles/${articleId}`)
  }

  editArticle( data) {
    return this.apiClient.put('articles',  data);
  }
}
