import { ROLE, IS_ADMIN } from '../constants/users';

export default function getUserProperty(values, updatedProperty) {
  const value = values[updatedProperty];
  switch (updatedProperty) {
    case IS_ADMIN:
      return ['role', value === true ? ROLE.ADMIN : ROLE.USER];
    default:
      return [updatedProperty, values[updatedProperty]];
  }
}
