import { useMemo } from 'react';
import { COMMENT_PREDEFINED_HASHTAGS } from '../../../constants/comments';

export default function useHighlights(comments) {
  const highlights = useMemo(
    () =>
      comments.reduce((acc, comment) => {
        if (!comment.position || comment.position.rects.length === 0)
          return acc;

        const hashtag = COMMENT_PREDEFINED_HASHTAGS[comment.hashtags];

        acc.push({
          id: comment._id,
          comment: {
            text: comment.text,
            hashtag: hashtag ? hashtag : COMMENT_PREDEFINED_HASHTAGS.common,
            userId: comment.user._id,
            createdAt: comment.createdAt,
          },
          content: {
            text: comment.quotedText,
          },
          position: comment.position,
          originPosition: comment.position,
        });

        return acc;
      }, []),
    [comments],
  );

  return highlights;
}
