import React from 'react';
import classNames from 'classnames';

import styles from './CircularProgress.module.scss';

function CircularProgress() {
  return (
    <div className={styles['sk-circle']}>
      <div className={classNames(styles['sk-circle1'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle2'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle3'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle4'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle5'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle6'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle7'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle8'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle9'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle10'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle11'], styles['sk-child'])} />
      <div className={classNames(styles['sk-circle12'], styles['sk-child'])} />
    </div>
  );
}

export default CircularProgress;
