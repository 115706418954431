import classNames from "classnames";
import React, {Component} from "react";

import styles from  "./style/Highlight.module.scss";
import type { Position } from "./types";

interface Props {
  id: string;
  position: Position;
  isScrolledTo: boolean;
  hashtag: string | null;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  commentUserId: string;
  authUserId: string;
}

export class Highlight extends Component<Props> {
  render() {
    const {
      id,
      position,
      isScrolledTo,
      onMouseOver,
      onMouseOut,
      commentUserId,
      authUserId,
        hashtag
    } = this.props;
    const { rects } = position;
    const myComment = commentUserId == authUserId ? 'highlight--myComment' : `highlight--hashtag-${hashtag}`;

    return (
      <div
        className={classNames(styles['highlight'], {
          [styles['highlight--scrolled-to']]: isScrolledTo,
          [styles[myComment]] : myComment !=null,
        })}
      >
        <div className={styles['highlight-parts']}>
          {rects.map((rect, index) => (
            <div
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              key={index}
              style={rect}
              className={styles['highlight-part']}
              data-highlight
              data-highlight-id={id}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Highlight;
