import { useState, useEffect } from 'react';
import getScreenOrientation from '../utils/getScreenOrientation';

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getScreenOrientation());

  const updateOrientation = () => {
    setOrientation(getScreenOrientation());
  };

  useEffect(() => {
    window.addEventListener('resize', updateOrientation);
    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);

  return orientation;
};

export default useScreenOrientation;
