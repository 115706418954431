import React from 'react';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import { compose, withHandlers, withState } from 'recompose';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import * as modalActions from '../../../redux/modals/actions';
import * as commentActions from '../../../redux/comments/actions';
import * as authActions from '../../../redux/auth/actions';
import Icon, { ICON_NAMES } from '../../../components/Icon';
// import * as modalNames from '../../../components/Modal/modalNames';
import ReplyInput from './ReplyInput';

import styles from './ReplyForm.module.scss';
import AuthorAvatar from './AuthorAvatar';

class ReplyForm extends React.Component {
  handleClickOutside() {
    this.props.onClickOutside();
  }

  render() {
    const { user, isShown, handleSubmit, isReplyInProgress } = this.props;

    return (
      <form
        className={classNames(styles['reply-form'], {
          [styles.hide]: !isShown,
        })}
        onSubmit={handleSubmit}
      >
        <AuthorAvatar user={user} isReply />
        <Field name="text" isShown={isShown} component={ReplyInput} />
        <button
          type="submit"
          className={styles['send-btn']}
          disabled={isReplyInProgress}
        >
          <Icon name={ICON_NAMES.arrowRight} />
        </button>
      </form>
    );
  }
}

export default compose(
  connect(state => ({ user: state.auth.user }), {
    createReply: commentActions.createReply,
    setReplyingComment: commentActions.setReplyingComment,
    showModal: modalActions.show,
    hideModal: modalActions.hide,
    passOnlySignedIn: authActions.passOnlySignedIn,
  }),
  withState('isReplyInProgress', 'setIsReplyInProgress', false),
  withFormik({
    mapPropsToValues: () => ({
      text: '',
    }),
    handleSubmit: (values, { props, resetForm }) => {
      props
        .passOnlySignedIn()
        .then(() => {
          if (!values.text.trim()) return;

          const { originComment, setIsReplyInProgress } = props;
          setIsReplyInProgress(true);

          const articleId = originComment.articleId;
          const fragmentId = originComment.fragmentId;
          const isQuote = originComment.threadId != null;
          const threadId = originComment.threadId || originComment._id;

          const reply = { articleId, threadId, fragmentId, text: values.text };

          if (isQuote) {
            reply.quotedId = originComment._id;
          }

          props
            .createReply(reply)
            .then(() => {
              setIsReplyInProgress(false);
              resetForm();
              window.dispatchEvent(
                new CustomEvent('google_analytics', {
                  detail: { eventName: 'reply_created' },
                }),
              );
            })
            .catch(e => {
              setIsReplyInProgress(false);
              console.log(e);
            });
        })
        .catch(e => console.log(e));
    },
    displayName: 'ReplyForm',
  }),
  withHandlers({
    onClickOutside: () =>
      // {
      // values,
      // setReplyingComment,
      // showModal,
      // hideModal,
      // },
      () => {
        // if (!values.text.trim()) {
        //   setReplyingComment(null);
        //   return;
        // }
        // showModal({
        //   name: modalNames.CONFIRMATION,
        //   title:
        //     'You have a draft comment. Do you wish close form and lose your draft?',
        // })
        //   .then(action => {
        //     if (action.name === 'confirm') {
        //       setReplyingComment(null);
        //     }
        //   })
        //   .finally(() => {
        //     hideModal(modalNames.CONFIRMATION);
        //   });
      },
  }),
  onClickOutside,
)(ReplyForm);
