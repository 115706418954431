import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import cn from 'classnames';
import onClickOutside from 'react-onclickoutside';

import Checkbox from '../../../components/Checkbox';
import TextArea from '../../../components/TextArea';
import styles from './CommentForm.module.scss';
import HashtagRadio from './HashtagRadio';
// import { COMMENT_HASHTAG_COLORS } from '../../../constants/comments';
import Button from '../../../components/Button/Button';
import AuthorAvatar from '../CommentCard/AuthorAvatar';
import Icon, { ICON_NAMES } from '../../../components/Icon';

const PREDEFINED_HASHTAGS = [
  { value: 'praise', label: '#praise' },
  { value: 'issue', label: '#issue' },
  { value: 'query', label: '#query' },
];

function CommentForm(props) {
  const {
    user,
    values,
    handleSubmit,
    onCancel,
    onClickOutside,
    isPrivateCommentsOnly,
  } = props;

  CommentForm.handleClickOutside = onClickOutside;

  const textareaRef = useRef();

  useEffect(() => {
    textareaRef.current.focus();
  }, []);

  const draftComment = localStorage.getItem('draft:comment');
  const formRef = useRef();
  useEffect(() => {
    if (formRef && formRef.current && draftComment) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [draftComment, formRef]);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className={cn(styles['form-container'], {
        [styles[`form-container--hashtag-${values.hashtag}`]]: !!values.hashtag,
      })}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
    >
      <AuthorAvatar user={user} />

      {/* TODO: remove if dont need show selected hashtags */}
      {/* <div className={`${styles['top-row']} row gutters-sm`}>
        <div className="col-auto">
          {values.hashtag && (
            <div
              className={styles['selected-hashtag']}
              style={{ color: COMMENT_HASHTAG_COLORS[values.hashtag] }}
            >
              {
                PREDEFINED_HASHTAGS.find(
                  hashtag => hashtag.value === values.hashtag,
                ).label
              }
            </div>
          )}
        </div>
      </div> */}

      <div className={`${styles['comment-input-wrap']} mb-2`}>
        <Field
          innerRef={textareaRef}
          name="text"
          type="textarea"
          inputClassName={styles['textarea-input']}
          placeholder="Text..."
          component={TextArea}
        />
      </div>

      <div className="row align-items-center mb-1">
        <div className="col-auto">
          <p className={styles['hashtags-title']}>Click to add hashtags:</p>
        </div>
        <div className="col-auto d-flex">
          {PREDEFINED_HASHTAGS.map(hashtag => (
            <div key={hashtag.value} className="mr-1">
              <Field
                name="hashtag"
                value={hashtag.value}
                placeholder={hashtag.label}
                checked={hashtag.value === values.hashtag}
                component={HashtagRadio}
              />
            </div>
          ))}
        </div>
      </div>
      {!isPrivateCommentsOnly && (
        <div className="row gutters-xxs">
          <div className="col-auto">
            <Field
              name="isPrivate"
              type="checkbox"
              placeholder="Keep comment private"
              component={Checkbox}
              withError={false}
              size="sm"
            />
          </div>
          <div className="col-auto">
            <Icon
              className={styles['private-icon']}
              name={ICON_NAMES.invisibility}
            />
          </div>
        </div>
      )}
      <div className="row justify-content-end">
        <div className="col-auto">
          <Button
            type="button"
            size="xsmall"
            caption="Cancel"
            color="secondary"
            onClick={onCancel}
          />
        </div>
        <div className="col-auto">
          <Button type="submit" size="xsmall" caption="Drop a comment" />
        </div>
      </div>
    </form>
  );
}

export default onClickOutside(CommentForm, {
  handleClickOutside: () => CommentForm.handleClickOutside,
});
