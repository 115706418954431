import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Button.module.scss';

function ButtonContainer(props) {
  const {
    href,
    to,
    children,
    color,
    type,
    className,
    style,
    size,
    onClick,
    isDisabled,
  } = props;

  const styleClasses = classNames(styles.btn, className, {
    [styles[`btn-${color}`]]: color != null,
    [styles[`btn--size-${size}`]]: size != null,
  });

  if (href)
    return (
      <a href={href} className={styleClasses} onClick={onClick}>
        {children}
      </a>
    );

  if (to)
    return (
      <Link className={styleClasses} to={to}>
        {children}
      </Link>
    );

  return (
    <button
      type={type}
      className={styleClasses}
      style={style}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

export default ButtonContainer;
