import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import ErrorImg from '../../assets/images/error.svg';
import InfoImg from '../../assets/images/info.svg';
import styles from './Error.module.scss';
import { Palette } from '@material-ui/core/styles/createPalette';
import { useTheme } from '@material-ui/core';

const ErrorTypes = {
  AccessDenied: 'access_denied',
  EmailNotVerified: 'email_not_verified',
};

const getContent = (
  type: string,
  palette: Palette,
): { title: string; message: string; icon: string; color: string } => {
  switch (type) {
    case ErrorTypes.AccessDenied: {
      return {
        title: 'Error',
        message: 'Access denied',
        icon: ErrorImg,
        color: palette.error.main,
      };
    }
    case ErrorTypes.EmailNotVerified: {
      return {
        title: 'Info',
        message: 'Check your email to validate your address',
        icon: InfoImg,
        color: palette.info.main,
      };
    }
    default: {
      return {
        title: 'Error',
        message: 'Something went wrong',
        icon: ErrorImg,
        color: palette.error.main,
      };
    }
  }
};

export const Error = () => {
  const theme = useTheme();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const error = urlSearchParams.get('error') || '';
  const content = getContent(error, theme.palette);

  return (
    <div className={styles.content}>
      <div className={styles.body}>
        <div className={styles.textContainer}>
          <h2 className={styles['mainHeader']} style={{ color: content.color }}>
            {content.title}
          </h2>
          <h3 className={styles['subHeader']}>{content.message}</h3>
          <Link
            to={ROUTES.home}
            className={styles.goBack}
            style={{ backgroundColor: content.color }}
          >
            Refresh
          </Link>
        </div>
        <div>
          <img src={content.icon} alt="icon" width="542" height="342" />
        </div>
      </div>
    </div>
  );
};
