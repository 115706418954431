import config from '../config';
import ApiClient from './client';

import Auth from './auth';
import Users from './users';
import Articles from './articles';
import Comments from './comments';
import InterestAreas from './interestAreas';
import ExpertiseAreas from './expertiseAreas';
import Activities from './activities';
import SystemSettings from './systemSettings';
import Categories from './categories';

const apiClient = new ApiClient(config);

export default {
  auth: new Auth(apiClient),
  comments: new Comments(apiClient),
  users: new Users(apiClient),
  articles: new Articles(apiClient),
  interestAreas: new InterestAreas(apiClient),
  expertiseAreas: new ExpertiseAreas(apiClient),
  activities: new Activities(apiClient),
  systemSettings: new SystemSettings(apiClient),
  categories: new Categories(apiClient),
  client: apiClient,
};
