const ICON_NAMES = {
  home: 'home',
  user: 'user',
  settings: 'settings',
  arrowRight: 'arrowRight',
  arrowLeft: 'arrowLeft',
  arrowSmallDown: 'arrowSmallDown',
  reply: 'reply',
  search: 'search',
  like: 'like',
  delete: 'delete',
  lock: 'lock',
  filter: 'filter',
  close: 'close',
  covid: 'covid',
  visibility: 'visibility',
  invisibility: 'invisibility',
  navigate: 'navigate',
  sort: 'sort',
  refresh: 'refresh',
  magnifyingGlass: 'magnifyingGlass',
  uploadFile: 'uploadFile',
  comment: 'comment',
};

export default ICON_NAMES;
