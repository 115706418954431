import { useEffect } from 'react';
import { useScript } from './useScript';
import logger from '../services/logger';

function gtag() {
  window.dataLayer.push(arguments);
}

export const useTracking = trackingId => {
  if (!trackingId) {
    logger(
      'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
    );
    return;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    gtag('js', new Date());
    gtag('config', trackingId);
  }, [trackingId]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useScript(`https://www.googletagmanager.com/gtag/js?id=${trackingId}`);

  const handleEvent = ({ detail: { eventName, eventLabel } }) => {
    gtag('event', eventName, {
      event_label: eventLabel,
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener('google_analytics', handleEvent);

    return () => {
      window.removeEventListener('google_analytics', handleEvent);
    };
  }, [trackingId]);

  if (!trackingId) {
    logger(
      'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
    );
    return;
  }
};
