import * as actionTypes from './actionTypes';

const show = props => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: actionTypes.SHOW,
      payload: { ...props, onAction: resolve },
    });
  });
};

const hide = name => ({ type: actionTypes.HIDE, payload: { name } });

export { show, hide };
