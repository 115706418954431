const PREFIX = 'COMMENTS';

export const GET_COMMENTS_SUCCESS = `${PREFIX}/GET_COMMENTS_SUCCESS`;

export const INCREASE_REPLIES_COUNT = `${PREFIX}/INCREASE_REPLIES_COUNT`;

export const GET_REPLIES_SUCCESS = `${PREFIX}/GET_REPLIES_SUCCESS`;

export const GET_STATISTIC_SUCCESS = `${PREFIX}/GET_STATISTIC_SUCCESS`;

export const SET_REPLYING_COMMENT = `${PREFIX}/SET_REPLYING_COMMENT`;

export const SET_ARE_REPLIES_SHOWN = `${PREFIX}/SET_ARE_REPLIES_SHOWN`;

export const APPEND_COMMENT = `${PREFIX}/APPEND_COMMENT`;
export const DELETE_COMMENT = `${PREFIX}/DELETE_COMMENT`;

export const ADD_HIGHLIGHT = `${PREFIX}/ADD_HIGHLIGHT`;
export const CANCEL_COMMENT_CREATION = `${PREFIX}/CANCEL_THREAD_CREATION`;

export const APPEND_REPLY = `${PREFIX}/APPEND_REPLY`;
export const DELETE_REPLY = `${PREFIX}/DELETE_REPLY`;

export const CREATE_LIKE_SUCCESS = `${PREFIX}/CREATE_LIKE_SUCCESS`;
export const REMOVE_LIKE_SUCCESS = `${PREFIX}/REMOVE_LIKE_SUCCESS`;

export const CREATE_REPLY_LIKE_SUCCESS = `${PREFIX}/CREATE_REPLY_LIKE_SUCCESS`;
export const REMOVE_REPLY_LIKE_SUCCESS = `${PREFIX}/REMOVE_REPLY_LIKE_SUCCESS`;

export const SHARE_COMMENT_SUCCESS = `${PREFIX}/SHARE_COMMENT_SUCCESS`;

export const GET_FILTERS_SUCCESS = `${PREFIX}/GET_FILTERS_SUCCESS`;
export const GET_FILTERS_ERROR = `${PREFIX}/GET_FILTERS_ERROR`;
export const APPLY_FILTER = `${PREFIX}/APPLY_FILTER`;
export const RESET_FILTER = `${PREFIX}/RESET_FILTER`;

export const SET_SELECTED_HIGHLIGHTS = `${PREFIX}/SET_SELECTED_SELECTED`;
export const RESET_SELECTED_HIGHLIGHTS = `${PREFIX}/RESET_SELECTED_HIGHLIGHTS`;
