import React from 'react';
import Button from '../../../Button';

import Modal from '../../Modal';

import styles from './Confirmation.module.scss';

const ModalConfirmation = ({ isShown, title, onAction }) => {
  const handleClose = () => onAction({ name: 'close' });
  const handleConfirm = () => onAction({ name: 'confirm' });

  return (
    <Modal
      isShown={isShown}
      onHide={handleClose}
      className={styles.modal}
      footer={[
        <Button
          type="button"
          color="secondary"
          caption="Cancel"
          size="small"
          onClick={handleClose}
        />,
        <Button
          type="button"
          caption="Yes"
          size="small"
          onClick={handleConfirm}
        />,
      ]}
    >
      <p>{title ? title : 'Are you sure?'}</p>
    </Modal>
  );
};

export default ModalConfirmation;
