import { createSelector } from 'reselect';

export class HomePageArticlesSelectors {
  static selectHomePageState = state => state.homePageArticles;

  static selectArticles = createSelector(
    HomePageArticlesSelectors.selectHomePageState,
    state => state.data,
  );

  static selectIsLoading = createSelector(
    HomePageArticlesSelectors.selectHomePageState,
    state => state.isLoading,
  );

  static selectOffset = createSelector(
    HomePageArticlesSelectors.selectHomePageState,
    state => state.offset,
  );

  static selectLimit = createSelector(
    HomePageArticlesSelectors.selectHomePageState,
    state => state.limit,
  );

  static selectFilters = createSelector(
    HomePageArticlesSelectors.selectHomePageState,
    state => state.filters,
  );

  static selectHasMore = createSelector(
    HomePageArticlesSelectors.selectHomePageState,
    state => state.hasMore,
  );
}
