import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './UserAvatar.module.scss';
import { getUserInitials } from '../../utils/getUserInitials';

const UserAvatar = props => {
  const { user, size } = props;

  const userInitials = getUserInitials(user);

  return (
    <div
      className={classNames(styles['user-avatar'], {
        [styles['user-avatar--lg']]: size === 'lg',
      })}
      title={user ? user.name : ''}
    >
      {userInitials}
    </div>
  );
};

UserAvatar.propTypes = {
  size: PropTypes.oneOf(['md', 'lg']),
};

UserAvatar.defaultProps = {
  size: 'md',
};

export default UserAvatar;
