import React from 'react';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import LoginButton from '../../components/LoginButton';
import { ROUTES } from '../../constants/routes';

import styles from './SignIn.module.scss';
import SignUpButton from '../../components/SignUpButton';
import { useAuth0 } from '@auth0/auth0-react';

export default function SignIn({ isAuth, error }) {
  const { loginWithRedirect } = useAuth0();
  if (isAuth) {
    return <Redirect to={ROUTES.home} />;
  }
  loginWithRedirect();
  return null;

  // return (
  //   <div className="container">
  //     <div className="row justify-content-center">
  //       <div className={classNames('col-6', styles.column)}>
  //         <div
  //           className={classNames(
  //             styles.card,
  //             'd-flex flex-column align-items-center',
  //           )}
  //         >
  //           <div>
  //             <p>Welcome! DropCite requires you to login.</p>
  //           </div>
  //           <div>
  //             <LoginButton />
  //           </div>
  //           <div className="d-flex justify-content-end mt-2">
  //             {error && <p className={styles.error}>{error}</p>}
  //           </div>
  //           <div className="d-flex justify-content-end mt-2 mb-2">
  //             <p className={styles['beta']}>
  //               If you do not have an account please sign up!
  //             </p>
  //           </div>
  //           <SignUpButton />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}
