import React from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import linkifyStr from 'linkify-string';
import cn from 'classnames';

import styles from './CommentText.module.scss';

const CommentText = props => {
  const { text, hashtag, isReduced, highlight,clickedComment } = props;

  const linkifiedText = linkifyStr(text, {
    className: styles.link,
    target: '_blank',
    attributes: href => ({
      title: href,
    }),
  });
  const className = (highlight || clickedComment)
    ? cn(styles.text, {
        [styles[`text--hashtag-${hashtag}`]]: hashtag != null,
        [styles[`text--newReply`]]:true,
      })
    : cn(styles.text, {
        [styles[`text--hashtag-${hashtag}`]]: hashtag != null,
      });

  return isReduced ? (
    <HTMLEllipsis
      unsafeHTML={linkifiedText}
      maxLine="1"
      basedOn="letters"
      ellipsis="... (more)"
      className={className}
    />
  ) : (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: linkifiedText }}
    ></span>
  );
};

export default CommentText;
