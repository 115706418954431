import React, { Component } from 'react';

import Preloader from '../components/Preloader';

const withPreloader = WrappedComponent => {
  return class UpdatedComponent extends Component {
    getContainerStyle() {
      const { showPreloader } = this.props;

      return {
        position: 'relative',
        pointerEvents: showPreloader ? 'none' : 'initial',
      };
    }

    renderPreloader() {
      const { showPreloader } = this.props;

      if (!showPreloader) return null;

      return <Preloader />;
    }

    render() {
      const { showPreloader, ...restProps } = this.props;

      return (
        <WrappedComponent
          {...restProps}
          preloaderContainerStyle={this.getContainerStyle()}
          preloader={this.renderPreloader()}
        />
      );
    }
  };
};

export default withPreloader;
