import React from 'react';
import Icon, { ICON_NAMES } from '../../../components/Icon';

import styles from './PdfScalePanel.module.scss';

const PdfScalePanel = props => {
  const { isZoomChanged, onZoomIn, onZoomOut, onReset } = props;

  return (
    <div className={styles['scale-panel-sticker']}>
      <div className={styles['scale-panel']}>
        <div className={styles['scale-panel__icon']}>
          <Icon name={ICON_NAMES.magnifyingGlass} />
        </div>
        <div className={styles['scale-panel__body']}>
          {isZoomChanged && (
            <button
              type="button"
              className={`${styles['panel-btn']} ${styles['panel-btn--text']}`}
              onClick={onReset}
            >
              Reset
            </button>
          )}

          <button
            type="button"
            className={styles['panel-btn']}
            onClick={onZoomOut}
          >
            &#8722;
          </button>
          <button
            type="button"
            className={styles['panel-btn']}
            onClick={onZoomIn}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfScalePanel;
