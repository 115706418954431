import React, { useState } from 'react';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import Icon, { ICON_NAMES } from '../Icon';
import UserAvatar from '../UserAvatar';
import DotsProgress from '../DotsProgress';
import MenuIcon from '@material-ui/icons/Menu';
import * as authActions from '../../redux/auth/actions';
import styles from './UserMenu.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { Hidden } from '@material-ui/core';
import { isUserAuthSelector } from '../../redux/auth/selectors';
import useClickOutside from '../../hooks/useClickOutside';

const UserMenu = ({ user, onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading] = useState(false);
  const { logout, loginWithRedirect } = useAuth0();
  const isAuth = useSelector(isUserAuthSelector);

  const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleClickOutside = () => {
    setIsMenuOpen(false);
  };
  const ref = useClickOutside(handleClickOutside);

  const handleOnLogout = event => {
    onLogout();
    logout({ returnTo: window.location.origin });
    event.stopPropagation();
  };

  const handleLogin = event => {
    localStorage.setItem('goBackPathname', window.location.pathname);
    loginWithRedirect();
    event.stopPropagation();
  };

  const renderMenuItems = () => (
    <div ref={ref}>
      {isAuth && (
        <>
          <Link
            className={styles['dropdown-button']}
            to={ROUTES.myActivities}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Activities
          </Link>
          <Link
            className={styles['dropdown-button']}
            to={ROUTES.myArticles}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            My Documents
          </Link>
          <Link
            className={styles['dropdown-button']}
            to={ROUTES.settings}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Settings
          </Link>
        </>
      )}
      <button
        type="button"
        className={styles['dropdown-button']}
        onClick={event => (isAuth ? handleOnLogout(event) : handleLogin())}
      >
        {isAuth ? 'Log out' : 'Sign in'}
      </button>
    </div>
  );

  const renderMobileMenuItems = () => (
    <div ref={ref}>
      <div className={styles['mobile-user-menu']}>
        <div>
          <UserAvatar userName={user.name} size="lg" />
        </div>
        <span className={styles['mobile-user-name']}>{user.name}</span>
      </div>

      <Link
        className={styles['dropdown-button']}
        to={ROUTES.home}
        onClick={() => {
          setIsMenuOpen(false);
        }}
      >
        Home
      </Link>
      {isAuth && (
        <>
          <Link
            className={styles['dropdown-button']}
            to={ROUTES.settings}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Settings
          </Link>
          <Link
            className={styles['dropdown-button']}
            to={ROUTES.myArticles}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            My Documents
          </Link>
        </>
      )}
      <button
        type="button"
        className={styles['dropdown-button']}
        onClick={event => (isAuth ? handleOnLogout(event) : handleLogin())}
      >
        {isAuth ? 'Log out' : 'Sign in'}
      </button>
    </div>
  );

  const renderLoader = () => (
    <div className={styles['loader-wrapper']}>
      <DotsProgress spinnerClassName={styles.spinner} />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <div className={styles['user-menu']}>
          <button
            type="button"
            className={classNames(styles['dropdown-toggler'], {
              [styles['dropdown-toggler-active']]: isMenuOpen,
            })}
            onClick={handleToggleMenu}
          >
            <span className={styles['menu-content']}>
              <MenuIcon />
            </span>
          </button>
          {isMenuOpen && (
            <div className={styles['dropdown-mobile']}>
              {isLoading ? renderLoader() : renderMobileMenuItems()}
            </div>
          )}
        </div>
      </Hidden>

      <Hidden mdDown>
        <div className={styles['user-menu']}>
          <button
            type="button"
            className={classNames(styles['dropdown-toggler'], {
              [styles['dropdown-toggler-active']]: isMenuOpen,
            })}
            onClick={handleToggleMenu}
          >
            <span className={styles['menu-content']}>
              <div>
                <UserAvatar userName={user.name} size="lg" />
              </div>
              <span className={styles['user-name']}>{user.name}</span>
              <span>
                <span className={styles['menu-icon-wrap']}>
                  <Icon name={ICON_NAMES.arrowSmallDown} />
                </span>
              </span>
            </span>
          </button>
          {isMenuOpen && (
            <div className={styles['dropdown']}>
              {isLoading ? renderLoader() : renderMenuItems()}
            </div>
          )}
        </div>
      </Hidden>
    </>
  );
};

export default connect(null, {
  onLogout: authActions.logout,
})(UserMenu);
