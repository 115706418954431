import { AppState } from '../type';
import { createSelector } from 'reselect';

export class MyArticlesSelectors {
  static selectState = (state: AppState) => state.myArticles;

  static selectIsLoading = createSelector(
    MyArticlesSelectors.selectState,
    state => state.isLoading,
  );

  static selectFilters = createSelector(
    MyArticlesSelectors.selectState,
    state => state.filters,
  );

  static selectPage = createSelector(
    MyArticlesSelectors.selectState,
    state => state.page,
  );

  static selectPageSize = createSelector(
    MyArticlesSelectors.selectState,
    state => state.pageSize,
  );

  static selectRowCount = createSelector(
    MyArticlesSelectors.selectState,
    state => state.rowCount,
  );

  static selectData = createSelector(
    MyArticlesSelectors.selectState,
    state => state.data,
  );

  static selectError = createSelector(
    MyArticlesSelectors.selectState,
    state => state.error,
  );
}
