import _ from 'lodash';

const sortRepliesAsTree = unsortedReplies => {
  const repliesSortedByDate = _.orderBy(
    unsortedReplies,
    ['createdAt'],
    ['asc'],
  );

  const repliesMapByQuoteId = repliesSortedByDate.reduce((map, reply) => {
    if (map[reply.quotedId]) map[reply.quotedId].push(reply);
    else map[reply.quotedId] = [reply];
    return map;
  }, {});

  const repliesIdInRightPosition = [];

  const getSorted = replies => {
    return replies.reduce((sortedReplies, reply) => {
      if (repliesIdInRightPosition.includes(reply._id)) {
        return sortedReplies;
      }

      sortedReplies.push(reply);
      repliesIdInRightPosition.push(reply._id);

      if (repliesMapByQuoteId[reply._id]) {
        sortedReplies.push(...getSorted(repliesMapByQuoteId[reply._id]));
      }

      return sortedReplies;
    }, []);
  };

  return getSorted(repliesSortedByDate);
};

export default sortRepliesAsTree;
