import React, { useRef, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import styles from './ReplyInput.module.scss';

function ReplyInput(props) {
  const inputRef = useRef(null);
  const { field, isShown } = props;

  useEffect(() => {
    if (isShown) inputRef.current.focus();
  }, [isShown]);

  return (
    <TextareaAutosize
      {...field}
      ref={inputRef}
      placeholder="Reply..."
      className={styles.textarea}
    />
  );
}

export default ReplyInput;
