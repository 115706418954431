import React, { useEffect, useState } from 'react';

import Modal from '../../Modal';
import getImageSize from '../../../../utils/getImageSize';

const HEIGHT_OFFSET = 150;
const WIDTH_OFFSET = 100;

const ImagePreviewModal = ({ isShown, onAction, imgSrc, imgAlt }) => {
  const [style, setStyle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const rescaleImage = async imgSrc => {
      const { width, height } = await getImageSize(imgSrc);

      let imageWidth = width;
      let imageHeight = height;
      const maxImageWidth = window.innerWidth - WIDTH_OFFSET;
      const maxImageHeight = window.innerHeight - HEIGHT_OFFSET;

      const isScalable = width > maxImageWidth || height > maxImageHeight;

      if (isScalable) {
        if (width < height) {
          imageWidth = (width * maxImageHeight) / height;
          if (imageWidth > maxImageWidth) imageWidth = maxImageWidth;
          imageHeight = (height * imageWidth) / width;
        } else {
          imageHeight = (height * maxImageWidth) / width;
          if (imageHeight > maxImageHeight) imageHeight = maxImageHeight;
          imageWidth = (width * imageHeight) / height;
        }
      }

      setIsLoading(false);
      setStyle({ width: imageWidth, height: imageHeight });
    };

    rescaleImage(imgSrc);
  }, [imgSrc]);

  const handleClose = () => onAction({ name: 'close' });

  return (
    <Modal isShown={isShown} isLoading={isLoading} onHide={handleClose}>
      <img src={imgSrc} alt={imgAlt} style={style} />
    </Modal>
  );
};

export default ImagePreviewModal;
