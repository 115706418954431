import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
} from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { ROUTES } from '../../constants/routes';
import Link from '../../components/Link';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { MyArticlesSelectors } from '../../redux/myArticles/selectors';
import { MyArticlesActions } from '../../redux/myArticles/actions';
import { AppState } from '../../redux/type';
import { Alert, AlertTitle } from '@material-ui/lab';

// TODO: mobile view
const MyArticles = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: AppState) => state.auth.user._id);
  const isLoading = useSelector(MyArticlesSelectors.selectIsLoading);
  const filters = useSelector(MyArticlesSelectors.selectFilters);
  const page = useSelector(MyArticlesSelectors.selectPage) || 0;
  const pageSize = useSelector(MyArticlesSelectors.selectPageSize) || 0;
  const rowCount = useSelector(MyArticlesSelectors.selectRowCount) || 0;
  const rows = useSelector(MyArticlesSelectors.selectData);
  const error = useSelector(MyArticlesSelectors.selectError);

  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(MyArticlesActions.fetchArticles(userId, page, pageSize, filters));
  }, [userId, filters, page, pageSize]);

  const handlePageChange = (page: number) =>
    dispatch(MyArticlesActions.setPage(page));

  const handlePageSizeChange = (pageSize: number) =>
    dispatch(MyArticlesActions.setPageSize(pageSize));

  const handleDelete = (id: string) => async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(MyArticlesActions.deleteArticle(id));
  };

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 3 },
    { field: 'access', headerName: 'Access', disableColumnMenu: true, flex: 1 },
    { field: 'title', headerName: 'Title', disableColumnMenu: true, flex: 4 },
    { field: 's3key', headerName: 'S3 Key', disableColumnMenu: true, flex: 3 },
    {
      field: 'authors',
      headerName: 'Authors',
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: 'authorsCorresponding',
      headerName: 'Contact',
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: '',
      headerName: ' ',
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderCell: params => (
        <div>
          <Link
            to={ROUTES.articles.replace(/:doi/, params.row.doi)}
            className=""
          >
            <IconButton aria-label="view">
              <VisibilityIcon color="secondary" />
            </IconButton>
          </Link>
          <Link
              to={{pathname: ROUTES.editArticle, state:{id:params.row.id, editArticle: true}}}
            className=""
          >
            <IconButton aria-label="edit">
              <EditIcon color="primary" />
            </IconButton>
          </Link>
          <IconButton aria-label="delete" onClick={handleDelete(params.row.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      gridRowGap={20}
      height="100%"
      width="100%"
      pt={2}
      pr={4}
      pb={2}
      pl={4}
    >
      {error && (
        <Box>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Link to={{pathname: ROUTES.createArticle, state:{}}} className="">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            endIcon={<AddCircleIcon />}
          >
            New Article
          </Button>
        </Link>
      </Box>
      <Box display="flex" flex={1}>
        <DataGrid
          disableSelectionOnClick
          rows={rows || []}
          columns={columns}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[5, 10, 20]}
          rowCount={rowCount}
          page={page}
          onPageChange={newValue => handlePageChange(newValue)}
          pageSize={pageSize}
          onPageSizeChange={newValue => handlePageSizeChange(newValue)}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default MyArticles;
