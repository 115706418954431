import React from 'react';
import classNames from 'classnames';
import OverlayTrigger from 'react-overlay-trigger';

import styles from './Tooltip.module.scss';

const Overlay = React.forwardRef(({ children, className, ...rest }, ref) => (
  <span ref={ref} className={classNames(styles.overlay, className)} {...rest}>
    {children}
  </span>
));

export default function Tooltip({
  children,
  className,
  text,
  placement,
  triggers,
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Overlay className={className}>{text}</Overlay>}
      triggers={triggers}
    >
      {children}
    </OverlayTrigger>
  );
}
