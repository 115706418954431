import React, { PointerEventHandler, PureComponent } from "react";
import ReactDom from "react-dom";
import {debounce} from "lodash";

import {
  EventBus,
  PDFViewer,
  PDFLinkService,
  // @ts-ignore
} from "pdfjs-dist/legacy/web/pdf_viewer";
import onClickOutside from 'react-onclickoutside';

import "pdfjs-dist/web/pdf_viewer.css";
import "./style/pdf_viewer.css";
import styles from './style/PdfHighlighter.module.scss';

import getBoundingRect from "./lib/get-bounding-rect";
import getClientRects from "./lib/get-client-rects";
import getAreaAsPng from "./lib/get-area-as-png";

import {
  asElement,
  getPageFromRange,
  getPageFromElement,
  getWindow,
  findOrCreateContainerLayer,
  isHTMLElement,
} from "./lib/pdfjs-dom";

import TipContainer from "./TipContainer";
import MouseSelection from "./MouseSelection";

import { scaledToViewport, viewportToScaled } from "./lib/coordinates";

import type {
  Position,
  ScaledPosition,
  IHighlight,
  Scaled,
  Point,
  LTWH,
  T_EventBus,
  T_PDFJS_Viewer,
  T_PDFJS_LinkService,
  ViewportHighlight,
  NewHighlight,
} from "./types";
import type { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import isHighlightStandsOnPoint from "../../pages/ArticlePdf/PdfContent/isHighlightStandsOnPoint.util";

type T_ViewportHighlight<T_HT> = { position: Position } & T_HT;

// interface State<T_HT> {
//   ghostHighlight: {
//     position: ScaledPosition;
//     content?: { text?: string; image?: string };
//   } | null;
//   isCollapsed: boolean;
//   range: Range | null;
//   tip: {
//     highlight: T_ViewportHighlight<T_HT>;
//     callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element;
//   } | null;
//   tipPosition: Position | null;
//   tipChildren: JSX.Element | null;
//   isAreaSelectionInProgress: boolean;
//   scrolledToHighlightId: string;
// }

// interface Props<T_HT> {
//   highlightTransform: (
//     highlight: T_ViewportHighlight<T_HT>,
//     index: number,
//     setTip: (
//       highlight: T_ViewportHighlight<T_HT>,
//       callback: (highlight: T_ViewportHighlight<T_HT>) => JSX.Element
//     ) => void,
//     hideTip: () => void,
//     viewportToScaled: (rect: LTWH) => Scaled,
//     screenshot: (position: LTWH) => string,
//     isScrolledTo: boolean
//   ) => JSX.Element;
//   highlights: Array<T_HT>;
//   onScrollChange: () => void;
//   scrollRef: (scrollTo: (highlight: IHighlight) => void) => void;
//   pdfDocument: PDFDocumentProxy;
//   pdfScaleValue: string;
//   onSelectionFinished: (
//     position: ScaledPosition,
//     content: { text?: string; image?: string },
//     hideTipAndSelection: () => void,
//     transformSelection: () => void
//   ) => JSX.Element | null;
//   enableAreaSelection: (event: MouseEvent) => boolean;
// }

const EMPTY_ID = "empty-id";

// export class PdfHighlighter<T_HT extends IHighlight> extends PureComponent<
//   Props<T_HT>,
//   State<T_HT>
// > {
class PdfHighlighter extends PureComponent {
  static defaultProps = {
    pdfScaleValue: "auto",
    comments: []
  };

  // state: State = {
  state: any = {
    ghostHighlight: null,
    isCollapsed: true,
    range: null,
    scrolledToHighlightId: EMPTY_ID,
    isAreaSelectionInProgress: false,
    tip: null,
    tipPosition: null,
    tipChildren: null,
  };

  // @ts-ignore
  eventBus: T_EventBus = new EventBus();
  linkService: T_PDFJS_LinkService = new PDFLinkService({
    eventBus: this.eventBus,
    externalLinkTarget: 2,
  });

  // @ts-ignore
  viewer: T_PDFJS_Viewer;

  resizeObserver: ResizeObserver | null = null;
  containerNode?: HTMLDivElement | null = null;
  unsubscribe = () => {};
  props: any;

  // constructor(props: Props<T_HT>) {
  constructor(props: any) {
    super(props);
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver(this.debouncedScaleValue);
    }
  }

  componentDidMount() {
    this.init();
  }

  attachRef = (ref: HTMLDivElement | null) => {
    const { eventBus, resizeObserver: observer } = this;
    this.containerNode = ref;
    this.unsubscribe();

    if (ref) {
      const { ownerDocument: doc } = ref;
      eventBus.on("textlayerrendered", this.onTextLayerRendered);
      eventBus.on("pagesinit", this.onDocumentReady);
      doc.addEventListener("selectionchange", this.onSelectionChange);
      doc.addEventListener("keydown", this.handleKeyDown);
      if (doc.defaultView) doc.defaultView.addEventListener("resize", this.debouncedScaleValue);
      if (observer) observer.observe(ref);

      this.unsubscribe = () => {
        eventBus.off("pagesinit", this.onDocumentReady);
        eventBus.off("textlayerrendered", this.onTextLayerRendered);
        doc.removeEventListener("selectionchange", this.onSelectionChange);
        doc.removeEventListener("keydown", this.handleKeyDown);
        if (doc.defaultView) doc.defaultView.removeEventListener(
          "resize",
          this.debouncedScaleValue
        );
        if (observer) observer.disconnect();
      };
    }
  };

  // componentDidUpdate(prevProps: Props<T_HT>) {
  componentDidUpdate(prevProps: any) {
    this.hideSelectionIfNewCommentAdded();

    if (prevProps.pdfScaleValue !== this.props.pdfScaleValue) {      
      this.debouncedScaleValue();
    }

    if (prevProps.pdfDocument !== this.props.pdfDocument) {
      this.init();
      return;
    }
    if (prevProps.highlights !== this.props.highlights) {
      this.renderHighlights(this.props);
    }
  }

  init() {
    const { pdfDocument } = this.props;

    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNode,
        eventBus: this.eventBus,
        enhanceTextSelection: true,
        removePageBorders: true,
        linkService: this.linkService,
      });

    this.linkService.setDocument(pdfDocument);
    this.linkService.setViewer(this.viewer);
    this.viewer.setDocument(pdfDocument);

    // debug
    (window as any).PdfViewer = this;
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  findOrCreateHighlightLayer(page: number) {
    const { textLayer } = this.viewer.getPageView(page - 1) || {};

    if (!textLayer) {
      return null;
    }

    return findOrCreateContainerLayer(
      textLayer.textLayerDiv,
      styles['highlight-layer']
    );
  }

  // groupHighlightsByPage(highlights: Array<T_HT>): {
  groupHighlightsByPage(highlights: any): {
    // [pageNumber: string]: Array<T_HT>;
    [pageNumber: string]: any;
  } {
    const { ghostHighlight } = this.state;

    return [...highlights, ghostHighlight]
      .filter(Boolean)
      .sort((h1, h2) => {
        if (!h1.content || !h2.content) return 0;
        return h2.content.text.length - h1.content.text.length})
      .reduce((res, highlight) => {
        const { pageNumber } = highlight!.position;

        res[pageNumber] = res[pageNumber] || [];
        res[pageNumber].push(highlight);

        return res;
      }, {} as Record<number, any[]>);
  }

  // showTip(highlight: T_ViewportHighlight<T_HT>, content: JSX.Element) {
  showTip(highlight: any, content: JSX.Element) {
    const { isCollapsed, ghostHighlight, isAreaSelectionInProgress } =
      this.state;

    const highlightInProgress = !isCollapsed || ghostHighlight;

    if (highlightInProgress || isAreaSelectionInProgress) {
      return;
    }

    this.setTip(highlight.position, content);
  }

  scaledPositionToViewport({
    pageNumber,
    boundingRect,
    rects,
    usePdfCoordinates,
  }: ScaledPosition): Position {
    const viewport = this.viewer.getPageView(pageNumber - 1).viewport;

    return {
      boundingRect: scaledToViewport(boundingRect, viewport, usePdfCoordinates),
      rects: (rects || []).map((rect) =>
        scaledToViewport(rect, viewport, usePdfCoordinates)
      ),
      pageNumber,
    };
  }

  viewportPositionToScaled({
    pageNumber,
    boundingRect,
    rects,
  }: Position): ScaledPosition {
    const viewport = this.viewer.getPageView(pageNumber - 1).viewport;

    return {
      boundingRect: viewportToScaled(boundingRect, viewport),
      rects: (rects || []).map((rect) => viewportToScaled(rect, viewport)),
      pageNumber,
    };
  }

  screenshot(position: LTWH, pageNumber: number) {
    const canvas = this.viewer.getPageView(pageNumber - 1).canvas;

    return getAreaAsPng(canvas, position);
  }

  // renderHighlights(nextProps?: Props<T_HT>) {
  renderHighlights(nextProps?: any) {
    const { highlightTransform, highlights } = nextProps || this.props;

    const { pdfDocument } = this.props;

    const { tip, scrolledToHighlightId } = this.state;

    const highlightsByPage = this.groupHighlightsByPage(highlights);

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const highlightLayer = this.findOrCreateHighlightLayer(pageNumber);

      if (highlightLayer) {
        ReactDom.render(
          <div>
            {(highlightsByPage[String(pageNumber)] || []).map(
              // @ts-ignore
              ({ position, id, ...highlight }, index) => {
                // @ts-ignore
                const viewportHighlight: T_ViewportHighlight<T_HT> = {
                  id,
                  position: this.scaledPositionToViewport(position),
                  ...highlight,
                };

                if (tip && tip.highlight.id === String(id)) {
                  this.showTip(tip.highlight, tip.callback(viewportHighlight));
                }

                const isScrolledTo = Boolean(scrolledToHighlightId === id);

                return highlightTransform(
                  viewportHighlight,
                  index,
                  // @ts-ignore
                  (highlight, callback) => {
                    // @ts-ignore
                    this.setState({
                      tip: { highlight, callback },
                    });

                    this.showTip(highlight, callback(highlight));
                  },
                  this.hideTipAndSelection,
                  // @ts-ignore
                  (rect) => {
                    const viewport = this.viewer.getPageView(
                      pageNumber - 1
                    ).viewport;

                    return viewportToScaled(rect, viewport);
                  },
                  // @ts-ignore
                  (boundingRect) => this.screenshot(boundingRect, pageNumber),
                  isScrolledTo
                );
              }
            )}
          </div>,
          highlightLayer
        );
      }
    }
  }

  hideTipAndSelection = () => {
    // @ts-ignore
    this.setState({
      tipPosition: null,
      tipChildren: null,
    });

    // @ts-ignore
    this.setState({ ghostHighlight: null, tip: null }, () =>
      this.renderHighlights()
    );
  };

  setTip(position: Position, inner: JSX.Element | null) {
    // @ts-ignore
    this.setState({
      tipPosition: position,
      tipChildren: inner,
    });
  }

  renderTip = () => {
    const { tipPosition, tipChildren } = this.state;
    if (!tipPosition) return null;

    const { boundingRect, pageNumber, rects } = tipPosition;
    const page = {
      node: this.viewer.getPageView(pageNumber - 1).div,
    };

    return (
      <TipContainer
        scrollTop={this.viewer.container.scrollTop}
        pageBoundingRect={page.node.getBoundingClientRect()}
        style={{
          left:
            page.node.offsetLeft + rects[0].left,
          top: boundingRect.top + page.node.offsetTop,
          bottom: boundingRect.top + page.node.offsetTop + boundingRect.height,
        }}
      >
        {tipChildren}
      </TipContainer>
    );
  };

  onTextLayerRendered = () => {
    this.renderHighlights();
  };

  scrollTo = (highlight: IHighlight) => {
    return new Promise<void>(resolve => {
      const { pageNumber, boundingRect, usePdfCoordinates } = highlight.position;

      this.viewer.container.removeEventListener("scroll", this.onScroll);

      const pageViewport = this.viewer.getPageView(pageNumber - 1).viewport;

      const scrollMargin = 10;

      this.viewer.scrollPageIntoView({
        pageNumber,
        destArray: [
          null,
          { name: "XYZ" },
          ...pageViewport.convertToPdfPoint(
            0,
            scaledToViewport(boundingRect, pageViewport, usePdfCoordinates).top -
              scrollMargin
          ),
          0,
        ],
      });

      // @ts-ignore
      this.setState(
        {
          scrolledToHighlightId: highlight.id,
        },
        () => this.renderHighlights()
      );

      // wait for scrolling to finish
      setTimeout(() => {
        this.viewer.container.addEventListener("scroll", this.onScroll);
        // ts-disable-next-line
        resolve();
      }, 100);
    })
  };

  onDocumentReady = () => {
    const { scrollRef, onInitialScaleSet } = this.props;

    this.handleScaleValue();

    if (this.containerNode) this.containerNode.scrollTop = 0;

    scrollRef(this.scrollTo);

    onInitialScaleSet(this.viewer.currentScale);
  };

  onSelectionChange = () => {
    const container = this.containerNode;
    const selection = getWindow(container).getSelection();

    if (!selection) {
      return;
    }

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    if (selection.isCollapsed) {
      // @ts-ignore
      this.setState({ isCollapsed: true });
      return;
    }

    if (
      !range ||
      !container ||
      !container.contains(range.commonAncestorContainer)
    ) {
      return;
    }

    // @ts-ignore
    this.setState({
      isCollapsed: false,
      range,
    });

    this.debouncedAfterSelection();
  };

  onScroll = (event: any) => {
    const { onScrollChange } = this.props;

    if (typeof onScrollChange === 'function') onScrollChange(event);

    this.resetSelecctedHighlight();

    this.viewer.container.removeEventListener("scroll", this.onScroll);
  };

  resetSelecctedHighlight = () => {
    this.setState(
      {
        scrolledToHighlightId: EMPTY_ID,
      },
      () => this.renderHighlights()
    );
  }

  onMouseDown: PointerEventHandler = (event) => {
    if (!isHTMLElement(event.target)) {
      return;
    }

    if (asElement(event.target).closest('[data-tip-container]')) {
      return;
    }

    this.hideTipAndSelection();
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === "Escape") {
      this.hideTipAndSelection();
    }
  };

  afterSelection = () => {
    const { onSelectionFinished } = this.props;

    const { isCollapsed, range } = this.state;

    if (!range || isCollapsed) {
      return;
    }

    const page = getPageFromRange(range);

    if (!page) {
      return;
    }

    const rects = getClientRects(range, page.node);

    if (rects.length === 0) {
      return;
    }

    const boundingRect = getBoundingRect(rects);

    const viewportPosition = { boundingRect, rects, pageNumber: page.number };

    const content = {
      text: range.toString(),
    };
    const scaledPosition = this.viewportPositionToScaled(viewportPosition);

    this.setTip(
      viewportPosition,
      onSelectionFinished(
        scaledPosition,
        content,
        () => this.hideTipAndSelection(),
        () =>
          // @ts-ignore
          this.setState(
            {
              ghostHighlight: { position: scaledPosition, content },
            },
            () => this.renderHighlights()
          )
      )
    );
  };

  debouncedAfterSelection: () => void = debounce(this.afterSelection, 500);

  toggleTextSelection(flag: boolean) {    
    this.viewer.viewer.classList.toggle(
      styles['highlighter-container--disable-selection'],
      flag
    );
  }

  handleScaleValue = () => {    
    if (parseFloat(this.viewer.currentScaleValue) === parseFloat(this.props.pdfScaleValue)) return;

    if (this.viewer) {
      this.viewer.currentScaleValue = this.props.pdfScaleValue;
      
      if (this.props.pdfScaleValue === 'auto') {        
        this.props.onInitialScaleSet(this.viewer.currentScale);
      }
    }
  };

  debouncedScaleValue: () => void = debounce(this.handleScaleValue, 500);

  getClickScaledPositionOnViewport(event: React.MouseEvent): Point | null {
    const pageNode = asElement(event.target).closest('.page');

    if (!pageNode) return null;

    const pageRect = pageNode.getBoundingClientRect()

    return {
      x: event.clientX - pageRect.left,
      y: event.clientY - pageRect.top
    };
  }

  onDocumentClick = (event: React.MouseEvent) => {
    const position = this.getClickScaledPositionOnViewport(event);
    const page = getPageFromElement(event.target as HTMLElement);
    const pageNumber = page ? page.number : null;
    const isHighlighting = this.state.range && !this.state.isCollapsed;

    this.resetSelecctedHighlight();

    if (isHighlighting) return;

    // @ts-ignore
    const highlightClicked = this.props.comments.find(comment => {
      return isHighlightStandsOnPoint(comment, {
        position,
        pageNumber,
        containerWidth: this.containerNode && this.containerNode.clientWidth,
        // @ts-ignore
        containerHeight: this.containerNode && this.containerNode.clientHeight,
      })
    })

    if (highlightClicked) {
      this.setState(
          {
            scrolledToHighlightId: highlightClicked._id,
          },
          () => this.renderHighlights()
      );
    }

    this.props.onClick({
      globalPosition: {x: event.clientX, y: event.clientY},
      position,
      pageNumber,
      containerWidth: this.containerNode && this.containerNode.clientWidth,
      containerHeight: this.containerNode && this.containerNode.clientHeight
    });
  }

  handleClickOutside() {
    this.resetSelecctedHighlight();
  }

  areHighlightsEqual(highlight1: NewHighlight, highlight2: NewHighlight) {
    if  (highlight1.content.text !== highlight2.content.text) return false;

    let key: keyof typeof highlight1.position.boundingRect;

    for (key in highlight1.position.boundingRect) {
      if (highlight1.position.boundingRect[key] !== highlight2.position.boundingRect[key])
        return false;
    }

    return true;
  }

  hideSelectionIfNewCommentAdded() {
    if (this.state.ghostHighlight) {
      this.props.highlights.forEach((highlight: NewHighlight) => {
        if (this.areHighlightsEqual(this.state.ghostHighlight, highlight))
          this.hideTipAndSelection();
      });
    }
  }

  render() {
    const { onSelectionFinished, enableAreaSelection, renderBefore, onPdfScroll } = this.props;    

    return (
      <div onPointerDown={this.onMouseDown} onClick={this.onDocumentClick}>
        <div
          ref={this.attachRef}
          className={styles['highlighter-container']}
          data-article-container
          onContextMenu={(e) => e.preventDefault()}
          onScroll={onPdfScroll}
        >
          <div className="pdfViewer" />
          {typeof renderBefore === 'function' && (
            <div className={styles['before-content']} onClick={(event) => event.stopPropagation()}>
              {renderBefore()}
            </div>
          )}
          {this.renderTip()}
          {typeof enableAreaSelection === "function" ? (
            <MouseSelection
              onDragStart={() => this.toggleTextSelection(true)}
              onDragEnd={() => this.toggleTextSelection(false)}
              onChange={(isVisible) =>
                // @ts-ignore
                this.setState({ isAreaSelectionInProgress: isVisible })
              }
              shouldStart={(event) =>
                enableAreaSelection(event) &&
                isHTMLElement(event.target) &&
                Boolean(asElement(event.target).closest(".page"))
              }
              onSelection={(startTarget, boundingRect, resetSelection) => {
                const page = getPageFromElement(startTarget);

                if (!page) {
                  return;
                }

                const pageBoundingRect = {
                  ...boundingRect,
                  top: boundingRect.top - page.node.offsetTop,
                  left: boundingRect.left - page.node.offsetLeft,
                };

                const viewportPosition = {
                  boundingRect: pageBoundingRect,
                  rects: [],
                  pageNumber: page.number,
                };

                const scaledPosition =
                  this.viewportPositionToScaled(viewportPosition);

                const image = this.screenshot(pageBoundingRect, page.number);                

                this.setTip(
                  viewportPosition,
                  onSelectionFinished(
                    scaledPosition,
                    { image },
                    () => this.hideTipAndSelection(),
                    () =>
                    // @ts-ignore
                      this.setState(
                        {
                          ghostHighlight: {
                            position: scaledPosition,
                            content: { image },
                          },
                        },
                        () => {
                          resetSelection();
                          this.renderHighlights();
                        }
                      )
                  )
                );
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}


export default onClickOutside(PdfHighlighter)
