import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonContainer from './ButtonContainer';
import styles from './Button.module.scss';
import withPreloader from '../../HOC/withPreloader';

function Button(props) {
  const { caption, color, preloader, preloaderContainerStyle } = props;

  return (
    <ButtonContainer {...props} style={preloaderContainerStyle}>
      <span
        className={classNames(styles.caption, {
          [styles[`caption-${color}`]]: color != null,
        })}
      >
        {caption}
        {preloader}
      </span>
    </ButtonContainer>
  );
}

Button.defaultProps = {
  color: 'primary',
  tagName: 'button',
  className: '',
  size: 'medium',
};

Button.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium']),
};

export default withPreloader(Button);
