import React from 'react';
import cn from 'classnames';

import styles from './ArticleUploader.module.scss';

function ArticleUploader(props) {
  const { onUploadingPdfChange } = props;

  return (
    <div className={cn('container', styles['uploader-container'])}>
      <label className={styles['uploader']}>
        <div className={styles['uploader-body']}>
          Drag and drop a pdf file here to load
        </div>
        <input
          type="file"
          accept=".pdf"
          className={styles.input}
          onChange={onUploadingPdfChange}
        />
      </label>
    </div>
  );
}

export default ArticleUploader;
