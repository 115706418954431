import { connect } from 'react-redux';
import {
  branch,
  compose,
  getContext,
  renderNothing,
  withHandlers,
} from 'recompose';
import { withFormik } from 'formik';

import CommentForm from './CommentForm';
import * as modalActions from '../../../redux/modals/actions';
import * as commentActions from '../../../redux/comments/actions';
import * as modalNames from '../../../components/Modal/modalNames';
import { toast } from 'react-toastify';
import * as authActions from '../../../redux/auth/actions';
import { checkAuth } from '../../../redux/auth/actions';
import PropTypes from 'prop-types';
// import scrollTo from '../../../utils/scrollTo';

export default compose(
  connect(
    state => ({
      articleId: state.articles.data._id,
      isPrivateCommentsOnly: state.articles.data.isPrivateCommentsOnly,
      isFormOpen: state.comments.isCommentFormOpen,
      newHighlight: state.comments.newHighlight,
      user: state.auth.user,
    }),
    {
      createThread: commentActions.createThread,
      cancelThreadCreation: commentActions.cancelThreadCreation,
      showModal: modalActions.show,
      hideModal: modalActions.hide,
      passOnlySignedIn: authActions.passOnlySignedIn,
      checkAuth: authActions.checkAuth,
    },
  ),
  getContext({
    selectComment: PropTypes.func,
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => {
      const draftComment = localStorage.getItem('draft:comment');
      if (draftComment) {
        return JSON.parse(draftComment);
      }
      return {
        text: '',
        isPrivate: false,
        hashtag: '',
      };
    },
    handleSubmit: (values, { props, resetForm }) => {
      if (props && props.newHighlight && props.newHighlight.position) {
        localStorage.setItem('draft:comment', JSON.stringify(values));
        props
          .checkAuth()
          .then(() => {
            props
              .createThread({
                articleId: props.articleId,
                text: values.text.trim(),
                quotedText: props.newHighlight ? props.newHighlight.text : null,
                position: props.newHighlight
                  ? props.newHighlight.position
                  : null,
                hashtag: values.hashtag ? values.hashtag : null,
                isPrivate: props.isPrivateCommentsOnly || values.isPrivate,
              })
              .then(res => {
                if (props) {
                  props.selectComment(res);
                }
                localStorage.removeItem('draft:comment');
                resetForm();
                window.dispatchEvent(
                  new CustomEvent('google_analytics', {
                    detail: {
                      eventName: 'comment_created',
                      eventLabel: values.hashtag,
                    },
                  }),
                );
              });
          })
          .catch(e => console.log(e));
      } else {
        toast.warning(
          'We cant find your highlight. Please re-select the text you want to make a comment on.',
        );
        localStorage.removeItem('draft:comment');
        resetForm();
      }
    },
    displayName: 'CommentForm',
  }),
  withHandlers({
    onCancel: ({ cancelThreadCreation, resetForm }) => () => {
      resetForm();
      cancelThreadCreation();
    },
  }),
  withHandlers({
    onClickOutside: ({ showModal, hideModal, onCancel, values }) => () => {
      if (!values.text.trim()) {
        onCancel();
        return;
      }

      showModal({
        name: modalNames.CONFIRMATION,
        title:
          'You have a draft comment. Do you want to delete your draft comment?',
      })
        .then(action => {
          if (action.name === 'confirm') {
            onCancel();
          }
        })
        .finally(() => {
          hideModal(modalNames.CONFIRMATION);
        });
    },
  }),
  branch(({ isFormOpen }) => !isFormOpen, renderNothing),
)(CommentForm);
