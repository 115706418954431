import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, withHandlers, getContext } from 'recompose';

import * as commentActions from '../../../redux/comments/actions';
import * as commentAlignSizeActions from '../../../redux/commentsAlignUI/actions';
import { titleCommentsSelector } from '../../../redux/comments/selectors';
import TopInfo from './TopInfo';

export default compose(
  getContext({
    isTitleSelected: PropTypes.bool,
    setIsTitleSelected: PropTypes.func,
  }),
  connect(
    state => ({
      titleComments: titleCommentsSelector(state),
      isCommentFormOpen: state.comments.isCommentFormOpen,
    }),
    {
      addHighlight: commentActions.addHighlight,
      setSelectedHighlights: commentActions.setSelectedHighlights,
      setBlocksSize: commentAlignSizeActions.setBlocksSize,
    },
  ),
  withHandlers({
    onCommentTitle: ({ addHighlight }) => () => {
      addHighlight();
    },
    onTitleClick: ({ titleComments, setSelectedHighlights }) => () => {
      setSelectedHighlights(
        titleComments.map(comment => ({
          id: comment._id,
          offset: 180,
        })),
      );
    },
    onOutsideClick: ({ setIsTitleSelected }) => () => {
      setIsTitleSelected(false);
    },
  }),
)(TopInfo);
