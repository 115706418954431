import { AppState } from '../type';
import { createSelector } from 'reselect';

export class SystemSettingsSelectors {
  static selectState = (state: AppState) => state.systemSettings;

  static selectOptOutEnabled = createSelector(
    SystemSettingsSelectors.selectState,
    settings => settings.optOutEnabled,
  );

  static selectOptOutText = createSelector(
    SystemSettingsSelectors.selectState,
    settings => settings.optOutText,
  );
}
