import React from 'react';
import PropTypes from 'prop-types';

import ICON_NAMES from './iconNames';

import styles from './Icon.module.scss';

const IconTemplate = props => {
  const { children, width, height, className, isSizeStrict, disabled } = props;

  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width} ${height}`}
      className={`${styles.container} ${className}`}
      style={{
        width: isSizeStrict ? `${width}px` : '1em',
        height: isSizeStrict ? `${height}px` : `${height / width}em`,
        pointerEvents: disabled ? 'none' : 'all',
      }}
    >
      {children}
    </svg>
  );
};

IconTemplate.defaultProps = {
  className: '',
};

const Icon = props => {
  const { name } = props;

  switch (name) {
    case ICON_NAMES.home:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path
            d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723
              16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821
              21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428
              20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046
              7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195
              2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562
              9.10792 20.7822L9.13478 20.7733Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.user:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path
            d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601
              7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295
              4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.settings:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path
            d="M20.4023 13.5801C20.76 13.7701 21.036 14.0701 21.2301 14.3701C21.6083 14.9901 21.5776 15.7501 21.2097
              16.4201L20.4943 17.6201C20.1162 18.2601 19.411 18.6601 18.6855 18.6601C18.3278 18.6601 17.9292 18.5601 17.6022
              18.3601C17.3365 18.1901 17.0299 18.1301 16.7029 18.1301C15.6911 18.1301 14.8429 18.9601 14.8122 19.9501C14.8122
              21.1001 13.872 22.0001 12.6968 22.0001H11.3069C10.1215 22.0001 9.18125 21.1001 9.18125 19.9501C9.16081 18.9601
              8.31259 18.1301 7.30085 18.1301C6.96361 18.1301 6.65702 18.1901 6.40153 18.3601C6.0745 18.5601 5.66572 18.6601
              5.31825 18.6601C4.58245 18.6601 3.87729 18.2601 3.49917 17.6201L2.79402 16.4201C2.4159 15.7701 2.39546 14.9901
              2.77358 14.3701C2.93709 14.0701 3.24368 13.7701 3.59115 13.5801C3.87729 13.4401 4.06125 13.2101 4.23498
              12.9401C4.74596 12.0801 4.43937 10.9501 3.57071 10.4401C2.55897 9.87012 2.23194 8.60012 2.81446 7.61012L3.49917
              6.43012C4.09191 5.44012 5.35913 5.09012 6.38109 5.67012C7.27019 6.15012 8.425 5.83012 8.9462 4.98012C9.10972
              4.70012 9.20169 4.40012 9.18125 4.10012C9.16081 3.71012 9.27323 3.34012 9.4674 3.04012C9.84553 2.42012 10.5302
              2.02012 11.2763 2.00012H12.7172C13.4735 2.00012 14.1582 2.42012 14.5363 3.04012C14.7203 3.34012 14.8429 3.71012
              14.8122 4.10012C14.7918 4.40012 14.8838 4.70012 15.0473 4.98012C15.5685 5.83012 16.7233 6.15012 17.6226
              5.67012C18.6344 5.09012 19.9118 5.44012 20.4943 6.43012L21.179 7.61012C21.7718 8.60012 21.4447 9.87012 20.4228
              10.4401C19.5541 10.9501 19.2475 12.0801 19.7687 12.9401C19.9322 13.2101 20.1162 13.4401 20.4023 13.5801ZM9.10972
              12.0101C9.10972 13.5801 10.4076 14.8301 12.0121 14.8301C13.6165 14.8301 14.8838 13.5801 14.8838 12.0101C14.8838
              10.4401 13.6165 9.18012 12.0121 9.18012C10.4076 9.18012 9.10972 10.4401 9.10972 12.0101Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.arrowRight:
      return (
        <IconTemplate {...props} width={32} height={32}>
          <path
            d="M15.5 16V15V16ZM24.7071 16.7071C25.0976 16.3166 25.0976 15.6834 24.7071 15.2929L18.3431 8.92893C17.9526
              8.53841 17.3195 8.53841 16.9289 8.92893C16.5384 9.31946 16.5384 9.95262 16.9289 10.3431L22.5858 16L16.9289
              21.6569C16.5384 22.0474 16.5384 22.6805 16.9289 23.0711C17.3195 23.4616 17.9526 23.4616 18.3431 23.0711L24.7071
              16.7071ZM7 17L15.5 17V15L7 15L7 17ZM15.5 17H24V15H15.5V17Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.arrowLeft:
      return (
        <IconTemplate {...props} width={32} height={32}>
          <path d="M16.5,16v1V16z M7.3,15.3c-0.4,0.4-0.4,1,0,1.4l6.4,6.4c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L9.4,16l5.7-5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L7.3,15.3z M25,15h-8.5v2H25V15z M16.5,15H8v2h8.5V15z" />
        </IconTemplate>
      );

    case ICON_NAMES.arrowSmallDown:
      return (
        <IconTemplate {...props} width={10} height={10}>
          <path
            d="M4.5,6.9c0,0-0.1-0.1-0.2-0.2C3.8,6.2,2.9,5,2.6,4.3c0-0.1-0.1-0.4-0.1-0.5c0-0.1,0-0.3,0.1-0.4
              C2.7,3.3,2.8,3.2,3,3.1C3.1,3.1,3.4,3,3.4,3C3.8,3,4.4,2.9,5,2.9c0.6,0,1.2,0,1.5,0.1c0,0,0.4,0.1,0.6,0.1c0.3,0.1,0.4,0.4,0.4,0.7
              v0c0,0.2-0.2,0.6-0.2,0.6C7.1,5,6.2,6.2,5.7,6.7c0,0-0.1,0.1-0.2,0.2C5.3,7,5.2,7.1,5,7.1C4.8,7.1,4.7,7,4.5,6.9z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.reply:
      return (
        <IconTemplate {...props} width={13} height={12}>
          <path
            style={{
              fill: 'none',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M8.9,1L12,4.1L8.9,7.1 M12,4.1H7.1C3.7,4.1,1,6.8,1,10.2v0.6"
          />
        </IconTemplate>
      );

    case ICON_NAMES.like:
      return (
        <IconTemplate {...props} width={20} height={20}>
          <path
            d="M12.483 1.70398C11.684 0.866984 10.391 1.31698 10.052 2.29398C9.77203 3.09998 9.40803 4.06598 9.05403
              4.77698C7.99403 6.90298 7.37603 8.11198 5.67003 9.62598C5.41998 9.8365 5.13547 10.0023 4.82903
              10.116C3.69903 10.562 2.63903 11.732 2.91603 13.121L3.26903 14.886C3.36004 15.3414 3.57609 15.7624 3.89296
              16.1019C4.20983 16.4413 4.61499 16.6858 5.06303 16.808L10.663 18.335C11.2647 18.4988 11.8938 18.5359 12.5105
              18.4437C13.1272 18.3516 13.7181 18.1323 14.2456 17.7997C14.773 17.4672 15.2256 17.0286 15.5747
              16.5119C15.9237 15.9951 16.1615 15.4115 16.273 14.798L16.958 11.037C17.0367 10.6046 17.0194 10.1602 16.9074
              9.73524C16.7953 9.31027 16.5913 8.91511 16.3097 8.5777C16.0281 8.24029 15.6758 7.96887 15.2777
              7.78264C14.8796 7.59641 14.4455 7.49992 14.006 7.49998H13.123L13.133 7.44798C13.213 7.03898 13.309 6.47798
              13.373 5.86498C13.438 5.25498 13.473 4.57998 13.422 3.95198C13.372 3.33598 13.238 2.70298 12.918
              2.22198C12.7873 2.03779 12.6419 1.86455 12.483 1.70398Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.delete:
      return (
        <IconTemplate {...props} width={14} height={14}>
          <path
            d="M11.0478 5.07335C11.1641 5.07335 11.2696 5.1241 11.353 5.20985C11.4307 5.30143 11.4699 5.41518 11.4585
              5.53534C11.4585 5.57501 11.1476 9.50664 10.9701 11.1615C10.8589 12.1771 10.2042 12.7937 9.22214
              12.8106C8.46704 12.8275 7.72895 12.8334 7.00221 12.8334C6.23065 12.8334 5.47612 12.8275 4.7437
              12.8106C3.79457 12.7879 3.13932 12.1602 3.03379 11.1615C2.85112 9.5008 2.5459 5.57501 2.54022
              5.53534C2.53455 5.41518 2.57313 5.30143 2.65142 5.20985C2.72857 5.1241 2.83977 5.07335 2.95664
              5.07335H11.0478ZM8.20442 1.16669C8.72012 1.16669 9.18079 1.5266 9.31411 2.03993L9.40942 2.46576C9.48657
              2.81284 9.78725 3.05842 10.1333 3.05842H11.8342C12.0611 3.05842 12.25 3.24683 12.25 3.48658V3.70825C12.25
              3.94216 12.0611 4.13641 11.8342 4.13641H2.16641C1.93892 4.13641 1.75 3.94216 1.75 3.70825V3.48658C1.75
              3.24683 1.93892 3.05842 2.16641 3.05842H3.86725C4.21275 3.05842 4.51343 2.81284 4.59115 2.46634L4.68022
              2.06851C4.81865 1.5266 5.27421 1.16669 5.79557 1.16669H8.20442Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.lock:
      return (
        <IconTemplate {...props} width={19} height={19}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0473 5.71143V6.86144C15.3391 7.26465 16.2803 8.43403 16.2803 9.83073V13.5334C16.2803 15.2625 14.8468
              16.6644 13.0794 16.6644H6.73187C4.96379 16.6644 3.53027 15.2625 3.53027 13.5334V9.83073C3.53027 8.43403
              4.47223 7.26465 5.76324 6.86144V5.71143C5.77086 3.47552 7.62277 1.66443 9.89384 1.66443C12.1954 1.66443
              14.0473 3.47552 14.0473 5.71143ZM9.90906 2.96872C11.4561 2.96872 12.7136 4.19847 12.7136
              5.71144V6.69971H7.0969V5.69653C7.10452 4.19102 8.36199 2.96872 9.90906 2.96872ZM10.5721 12.5056C10.5721
              12.8708 10.2749 13.1615 9.90147 13.1615C9.53566 13.1615 9.23844 12.8708 9.23844 12.5056V10.8511C9.23844
              10.4933 9.53566 10.2026 9.90147 10.2026C10.2749 10.2026 10.5721 10.4933 10.5721 10.8511V12.5056Z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.filter:
      return (
        <IconTemplate {...props} width={18} height={18}>
          <path
            d="M6.7,4.8c0,1.4-1.2,2.5-2.6,2.5c-1.4,0-2.6-1.1-2.6-2.5c0-1.4,1.2-2.5,2.6-2.5C5.5,2.2,6.7,3.4,6.7,4.8z
              M15.4,3.7c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1h-4.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1H15.4z
              M2.6,12h4.9 c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1H2.6c-0.6,0-1.1-0.5-1.1-1.1C1.5,12.5,2,12,2.6,12z
              M13.9,15.6c1.4,0,2.6-1.1,2.6-2.5 c0-1.4-1.2-2.5-2.6-2.5c-1.4,0-2.6,1.1-2.6,2.5C11.3,14.4,12.5,15.6,13.9,15.6z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.close:
      return (
        <IconTemplate {...props} width={12} height={12}>
          <path d="M1 1L10.8995 10.8995" />
          <path d="M1 11L10.8995 1.1005" />
        </IconTemplate>
      );

    case ICON_NAMES.covid:
      return (
        <IconTemplate {...props} width={256} height={256}>
          <path
            d="M105,8v4.9l8.8,0.3l8.7,0.3v13v13l-6.3,0.7c-13.2,1.5-29,7.9-40.5,16.7l-6.5,4.9l-9.3-9.4l-9.3-9.3l5.5-5.6
            c3-3,5.5-6,5.7-6.4c0.3-1-5.3-7.1-6.5-7.1c-0.5,0-8,7.2-16.8,16L22.6,55.9l2.4,2.7c4.6,4.8,5.2,4.8,11.6-1.6l6-5.9l9.3,9.3l9.3,9.3
            l-4.7,6.4c-8.6,11.7-15.1,27.5-17,41.6l-0.7,5.3H25.9H13v-8.5V106H8H3v22.7v22.8h4.7h4.8l0.5-9l0.5-9h12.9h13l1.2,8.5
            c2,13.7,8.4,28.5,17.3,39.8l3.9,4.9l-9.4,9.4l-9.3,9.3l-5.8-5.7c-3.2-3.1-6.3-5.7-6.9-5.7s-2.4,1.6-3.9,3.6l-2.8,3.6l16.1,16.1
            l16.1,16.1l2.7-2.4c4.8-4.6,4.7-5.2-1.1-11.1c-3-3-5.5-5.9-5.5-6.4c0-0.6,4-5,8.9-9.9l8.8-8.8l6.4,4.7c11.7,8.6,27.5,15.1,41.7,17
            l5.2,0.7v12.9V243h-8.5H106v5.5v5.5h22.4h22.4l0.3-2.8c0.2-1.5,0.3-3.9,0.1-5.5l-0.3-2.7h-9H133v-12.9v-12.9l6.3-0.7
            c7.4-0.9,20.2-4.7,27.2-8.2c2.8-1.4,8.5-5,12.7-7.9l7.7-5.4l9.2,9.1l9.2,9l-6.1,6.2l-6.2,6.2l3.7,3.8l3.8,3.7l16.2-16.2l16.3-16.2
            l-3.7-3.8l-3.7-3.8l-6.3,6.2l-6.2,6.2l-9.5-9.3l-9.4-9.3l4.8-6.4c8.3-10.9,15.1-27.5,16.8-40.9l0.7-6h13h13l0.3,8.8l0.3,8.7h4.9h5
            v-23v-23h-5h-4.9l-0.3,8.7l-0.3,8.8h-12.9h-13l-1.2-8.5c-2-13.7-8.4-28.5-17.3-39.8l-3.9-4.9l9.4-9.4l9.3-9.3l6.3,6.2l6.2,6.2
            l3.3-3.8c1.8-2,3.3-3.9,3.3-4.2s-7.2-7.7-16-16.5l-15.9-15.9l-2.7,2.4c-4.8,4.6-4.8,5.2,1.6,11.5l5.8,5.9l-9,9.8l-9,9.7l-4.8-3.8
            c-11-8.7-28.6-16.2-42-17.8l-6.5-0.8v-13v-13l8.8-0.3l8.7-0.3V8V3h-23h-23L105,8L105,8z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.visibility:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path d="M18.43 5.25c-.5-.21-1.03-.41-1.58-.56-1.36-.41-2.87-.65-4.45-.68C12.27 4 12.14 4 12 4c-.13 0-.27 0-.4.01-1.58.03-3.09.27-4.45.68-.55.15-1.08.35-1.58.56C2.22 6.66 0 9.16 0 12c0 3.28 2.95 6.09 7.17 7.32 1.48.44 3.11.68 4.83.68s3.35-.24 4.83-.68C21.05 18.09 24 15.28 24 12c0-2.84-2.22-5.34-5.57-6.75zM2 12c0-1.48 1.01-2.94 2.72-4.05-.54 1.22-.84 2.6-.84 4.05 0 1.46.31 2.84.85 4.07C3.04 14.99 2 13.55 2 12zm13.38 5.62c-1.06.25-2.2.38-3.38.38s-2.32-.13-3.38-.38c-1.51-1.24-2.5-3.29-2.5-5.62s.99-4.39 2.51-5.63c.96-.22 1.98-.34 3.01-.36h.06l.05-.01h.5l.05.01h.06c1.03.02 2.05.14 3.01.36 1.52 1.24 2.51 3.3 2.51 5.63s-.99 4.38-2.5 5.62zm3.89-1.55c.54-1.23.85-2.61.85-4.07 0-1.45-.3-2.83-.84-4.05C20.99 9.06 22 10.52 22 12c0 1.55-1.04 2.99-2.73 4.07z" />
          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm1.5 5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z" />
        </IconTemplate>
      );

    case ICON_NAMES.invisibility:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path d="M19.36 5.798l3.99-4.054c.4-.397.4-1.04.01-1.446-.39-.397-1.03-.397-1.42 0l-4.58 4.645c-.17-.06-.34-.112-.51-.163-1.36-.418-2.87-.662-4.45-.693-.13-.01-.26-.01-.4-.01-.13 0-.27 0-.4.01-1.58.03-3.09.275-4.448.693-.55.153-1.08.356-1.58.57-3.35 1.436-5.57 3.983-5.57 6.875 0 2.455 1.59 4.655 4.12 6.142l-3.83 3.89c-.39.398-.39 1.04 0 1.437.2.204.45.306.71.306s.51-.102.71-.295l4.34-4.41c.36.143.74.275 1.12.387 1.48.448 3.11.693 4.83.693s3.35-.244 4.83-.693C21.05 18.428 24 15.566 24 12.225c0-2.617-1.81-4.94-4.64-6.427zM2.002 12.225c0-1.508 1.01-2.995 2.72-4.125-.54 1.243-.84 2.648-.84 4.125 0 1.487.31 2.893.85 4.146-1.69-1.1-2.73-2.565-2.73-4.144zm4.85 3.382c-.46-.998-.73-2.15-.73-3.382 0-2.373.99-4.472 2.51-5.735.96-.224 1.98-.346 3.01-.367h.06l.05-.01h.5l.05.01h.06c1.03.02 2.05.143 3.01.367.08.07.16.143.24.224l-1.84 1.864c-.54-.275-1.14-.428-1.77-.428-2.21 0-4 1.823-4 4.074 0 .642.15 1.253.42 1.793l-1.57 1.59zm8.15-3.89c0 .844-.67 1.527-1.5 1.527-.41 0-.78-.163-1.05-.438l2.12-2.15c.27.276.43.642.43 1.06zm.38 6.233c-1.06.255-2.2.387-3.38.387s-2.32-.132-3.38-.387c-.23-.183-.44-.397-.63-.62l1.73-1.753c.65.448 1.44.723 2.28.723 2.21 0 4-1.823 4-4.074 0-.856-.26-1.64-.71-2.302l1.59-1.61c.63 1.11 1 2.466 1 3.912 0 2.373-.99 4.46-2.5 5.724zm3.89-1.58c.54-1.252.85-2.658.85-4.145 0-1.477-.3-2.883-.84-4.125C20.99 9.23 22 10.718 22 12.225c0 1.58-1.04 3.046-2.73 4.146z" />
        </IconTemplate>
      );

    case ICON_NAMES.navigate:
      return (
        <IconTemplate {...props} isSizeStrict width={8.414} height={14.828}>
          <path
            style={{
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: 2,
            }}
            d="M15,18,9,12l6-6"
            transform="translate(-8 -4.586)"
          />
        </IconTemplate>
      );

    case ICON_NAMES.sort:
      return (
        <IconTemplate {...props} width={32} height={32}>
          <g>
            <path d="M16.71,7.29l-7-7a1,1,0,0,0-1.41,0l-7,7L2.71,8.71,8,3.41V32h2V3.41l5.29,5.29Z" />
            <path d="M29.29,23.29,24,28.59V0H22V28.59l-5.29-5.29-1.41,1.41,7,7a1,1,0,0,0,1.41,0l7-7Z" />
          </g>
        </IconTemplate>
      );

    case ICON_NAMES.refresh:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path d="M12 4C9.69818 4 7.62561 5.02187 6.19328 6.65856C5.82957 7.07417 5.1978 7.11624 4.78219 6.75253C4.36658 6.38881 4.32451 5.75705 4.68823 5.34144C6.47858 3.29564 9.08928 2 12 2C17.3671 2 21.6932 6.38925 21.7981 11.7989L22.285 11.3009C22.6711 10.906 23.3042 10.8989 23.6991 11.285C24.094 11.6711 24.1011 12.3042 23.715 12.6991L21.515 14.9491C21.3269 15.1415 21.0691 15.25 20.8 15.25C20.5309 15.25 20.2731 15.1415 20.085 14.9491L17.885 12.6991C17.4989 12.3042 17.506 11.6711 17.9009 11.285C18.2958 10.8989 18.9289 10.906 19.315 11.3009L19.7975 11.7943C19.691 7.45096 16.2203 4 12 4ZM3.2 8.75C3.46911 8.75 3.72687 8.85847 3.91501 9.05088L6.11501 11.3009C6.50112 11.6958 6.49401 12.3289 6.09912 12.715C5.70423 13.1011 5.07111 13.094 4.68499 12.6991L4.20252 12.2057C4.30903 16.549 7.77965 20 12 20C14.3018 20 16.3744 18.9781 17.8067 17.3414C18.1704 16.9258 18.8022 16.8838 19.2178 17.2475C19.6334 17.6112 19.6755 18.243 19.3118 18.6586C17.5214 20.7044 14.9107 22 12 22C6.6329 22 2.30681 17.6107 2.20195 12.2011L1.71501 12.6991C1.3289 13.094 0.69577 13.1011 0.300883 12.715C-0.0940045 12.3289 -0.101118 11.6958 0.284994 11.3009L2.48499 9.05088C2.67313 8.85847 2.93089 8.75 3.2 8.75Z" />
        </IconTemplate>
      );

    case ICON_NAMES.magnifyingGlass:
      return (
        <IconTemplate {...props} width={30.239} height={30.239}>
          <path
            d="M20.194,3.46c-4.613-4.613-12.121-4.613-16.734,0c-4.612,4.614-4.612,12.121,0,16.735
		c4.108,4.107,10.506,4.547,15.116,1.34c0.097,0.459,0.319,0.897,0.676,1.254l6.718,6.718c0.979,0.977,2.561,0.977,3.535,0
		c0.978-0.978,0.978-2.56,0-3.535l-6.718-6.72c-0.355-0.354-0.794-0.577-1.253-0.674C24.743,13.967,24.303,7.57,20.194,3.46z
		 M18.073,18.074c-3.444,3.444-9.049,3.444-12.492,0c-3.442-3.444-3.442-9.048,0-12.492c3.443-3.443,9.048-3.443,12.492,0
		C21.517,9.026,21.517,14.63,18.073,18.074z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.uploadFile:
      return (
        <IconTemplate {...props} width={490.955} height={490.955}>
          <path
            d="M445.767,308.42l-53.374-76.49v-20.656v-11.366V97.241c0-6.669-2.604-12.94-7.318-17.645L312.787,7.301
            C308.073,2.588,301.796,0,295.149,0H77.597C54.161,0,35.103,19.066,35.103,42.494V425.68c0,23.427,19.059,42.494,42.494,42.494
            h159.307h39.714c1.902,2.54,3.915,5,6.232,7.205c10.033,9.593,23.547,15.576,38.501,15.576c26.935,0-1.247,0,34.363,0
            c14.936,0,28.483-5.982,38.517-15.576c11.693-11.159,17.348-25.825,17.348-40.29v-40.06c16.216-3.418,30.114-13.866,37.91-28.811
            C459.151,347.704,457.731,325.554,445.767,308.42z M170.095,414.872H87.422V53.302h175.681v46.752
            c0,16.655,13.547,30.209,30.209,30.209h46.76v66.377h-0.255v0.039c-17.685-0.415-35.529,7.285-46.934,23.46l-61.586,88.28
            c-11.965,17.134-13.387,39.284-3.722,57.799c7.795,14.945,21.692,25.393,37.91,28.811v19.842h-10.29H170.095z M410.316,345.771
            c-2.03,3.866-5.99,6.271-10.337,6.271h-0.016h-32.575v83.048c0,6.437-5.239,11.662-11.659,11.662h-0.017H321.35h-0.017
            c-6.423,0-11.662-5.225-11.662-11.662v-83.048h-32.574h-0.016c-4.346,0-8.308-2.405-10.336-6.271
            c-2.012-3.866-1.725-8.49,0.783-12.07l61.424-88.064c2.189-3.123,5.769-4.984,9.57-4.984h0.017c3.802,0,7.38,1.861,9.568,4.984
            l61.427,88.064C412.04,337.28,412.328,341.905,410.316,345.771z"
          />
        </IconTemplate>
      );

    case ICON_NAMES.comment:
      return (
        <IconTemplate {...props} width={24} height={24}>
          <path d="M12,2A10,10,0,0,0,2,12a9.89,9.89,0,0,0,2.26,6.33l-2,2a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,22h9A10,10,0,0,0,12,2Zm0,18H5.41l.93-.93a1,1,0,0,0,0-1.41A8,8,0,1,1,12,20Z" />
        </IconTemplate>
      );

    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string,
  strictSize: PropTypes.bool,
};

export default Icon;
