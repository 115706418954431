import React from 'react';
import { Field } from 'formik';

import Modal from '../../Modal';
import Button from '../../../Button';
import Input from '../../../Input';
import Checkbox from '../../../Checkbox';

import styles from './UserCreation.module.scss';

const UserCreation = ({
  isOwner,
  isShown,
  isSaving,
  onClose,
  handleSubmit,
  errors,
  touched,
}) => {
  return (
    <Modal
      className={styles.modal}
      isShown={isShown}
      onHide={!isSaving ? onClose : null}
      title="Add User"
      footer={[
        <Button
          type="button"
          color="secondary"
          caption="Cancel"
          size="small"
          isDisabled={isSaving}
          onClick={onClose}
        />,
        <Button
          type="button"
          caption="Add"
          size="small"
          isDisabled={isSaving}
          showPreloader={isSaving}
          onClick={handleSubmit}
        />,
      ]}
    >
      <form className="d-flex">
        <div>
          <p className={styles['input-row']}>ORCID:</p>
          <p className={styles['input-row']}>Enabled:</p>
          <p className={styles['input-row']}>User Admin:</p>
        </div>
        <div className={styles['input-column']}>
          <div className={styles['input-row']}>
            <div className="w-100">
              <Field
                name="orcid"
                component={Input}
                error={errors.orcid}
                isTouched={touched.orcid}
              />
            </div>
          </div>
          <div className={styles['input-row']}>
            <Field
              type="checkbox"
              name="isEnabled"
              component={Checkbox}
              error={errors.isEnabled}
              isTouched={touched.isEnabled}
            />
          </div>
          <div className={styles['input-row']}>
            <Field
              type="checkbox"
              name="isAdmin"
              component={Checkbox}
              error={errors.isAdmin}
              isTouched={touched.isAdmin}
              isDisabled={!isOwner}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default UserCreation;
