import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import * as commentActions from '../../../redux/comments/actions';
import ReplyForm from './ReplyForm';
import formatTimeAgo from '../../../utils/formatTimeAgo';
import { formatDate } from '../../../utils/formatDate';
import Icon, { ICON_NAMES } from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import styles from './CommentCard.module.scss';
import CommentText from './CommentText';
import { getUserInitials } from '../../../utils/getUserInitials';
import AuthorAvatar from './AuthorAvatar';

const DATE_TIME_FORMAT = 'dddd, MMMM D, YYYY h:mm A';

function CommentCard({
  children,
  isReply,
  isSelected,
  comment,
  quotedComment,
  areRepliesShown,
  onRepliesOpen,
  onRepliesClose,
  onReplyDelete,
  onReplyCreateLike,
  onReplyRemoveLike,
  onSelectTreadComment,
}) {
  isSelected = true;

  const dispatch = useDispatch();

  const replyingCommentId = useSelector(
    state => state.comments.replyingCommentId,
  );

  const authUserId = useSelector(state => state.auth.user._id);
  const isUserAuthorized = useSelector(state => state.auth.isAuth);
  const isPrivateCommentsOnly = useSelector(
    state => state.articles.data.isPrivateCommentsOnly,
  );

  const handleReply = event => {
    event.stopPropagation();
    dispatch(commentActions.setReplyingComment(comment._id));
  };

  const handleLikeClick = event => {
    event.stopPropagation();
    if (isReply) {
      if (comment.isLiked) onReplyRemoveLike(comment._id);
      else onReplyCreateLike(comment._id);
      return;
    }

    dispatch(
      comment.isLiked
        ? commentActions.removeLike(comment._id)
        : commentActions.createLike(comment._id),
    );
  };

  const handleDelete = event => {
    event.stopPropagation();
    if (isReply) onReplyDelete(comment._id);
    else dispatch(commentActions.deleteThread(comment._id));
  };

  const handleShare = event => {
    event.stopPropagation();
    dispatch(commentActions.shareComment(comment._id));
  };

  const isCommentReplying = comment._id === replyingCommentId;

  const {
    hashtags,
    isPrivate,
    user: { _id: userId, name: userName },
    createdAt,
    serverDate,
    // isLiked,
    likesCount,
    repliesCount,
  } = comment;

  const renderHashtags = () => (
    // hashtags.map(({ value }) => (
    <p
      key={hashtags}
      className={classNames(styles.hashtag, styles[`${hashtags}-hashtag`])}
    >{`#${hashtags}`}</p>
  );
  // ));

  const doesUserOwnComment = isUserAuthorized && userId === authUserId;

  const isReducedView = !isSelected && !isReply;

  const commentHashtag = hashtags;
  // hashtags.find(
  //   hashtag => COMMENT_PREDEFINED_HASHTAGS[hashtag.value] != null,
  // );

  const handleRepliesOpen = event => {
    handleReply(event);
    if (!isReply) onRepliesOpen();
  };

  return (
    <div
      onClick={onSelectTreadComment}
      data-comment-id={comment._id}
      data-thread={!isReply}
      className={classNames(styles['comment'], {
        [styles['comment--reply']]: isReply,
        [styles['comment--is-replying']]: isCommentReplying,
        [styles['comment--is-selected']]: isSelected,
        [styles[`comment--hashtag-${commentHashtag}`]]: commentHashtag,
      })}
    >
      <AuthorAvatar user={comment.user} isReply={isReply} />
      <div className={styles.body}>
        <div
          role="button"
          tabIndex="0"
          className={classNames(styles.text, {
            [styles['text--selectable']]:
              typeof onSelectTreadComment === 'function',
            [styles['text--highlight']]: comment.isHighlight,
            [styles['text--reduced']]: isReducedView,
          })}
        >
          {quotedComment && (
            <span className={styles['quote-label']}>
              Replying to{' '}
              <span title={quotedComment.user.name}>
                {getUserInitials(quotedComment.user)}
              </span>
              :{' '}
            </span>
          )}
          {comment.isHighlight ? (
            'Highlight'
          ) : (
            <CommentText
              text={comment.text}
              hashtag={commentHashtag ? commentHashtag : null}
              isReduced={isReducedView}
              highlight={comment.highlight}
              clickedComment={areRepliesShown}
            />
          )}
        </div>
        {!isReducedView && (
          <div className={styles.actions}>
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <div className="row">
                  <div className="col-auto">
                    <Tooltip
                      placement="bottom"
                      text="Reply"
                      triggers={['hover', 'focus']}
                    >
                      <button
                        type="button"
                        className={styles['action-btn']}
                        onClick={
                          areRepliesShown ? onRepliesClose : handleRepliesOpen
                        }
                      >
                        <Icon name={ICON_NAMES.comment} />
                        <span className={styles['action-btn__number']}>
                          {repliesCount}
                        </span>
                      </button>
                    </Tooltip>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className={classNames(styles['action-btn'])}
                      onClick={handleLikeClick}
                    >
                      <Icon name={ICON_NAMES.like} />
                      <span className={styles['action-btn__number']}>
                        {likesCount}
                      </span>
                    </button>
                  </div>

                  {!isPrivateCommentsOnly && isPrivate && (
                    <div className="col-auto">
                      <Tooltip
                        placement="bottom"
                        text="Private / Click to share"
                        triggers={['hover', 'focus']}
                      >
                        <button
                          type="button"
                          className={styles['action-btn']}
                          onClick={handleShare}
                        >
                          <Icon name={ICON_NAMES.invisibility} />
                        </button>
                      </Tooltip>
                    </div>
                  )}

                  {doesUserOwnComment && (
                    <div className="col-auto">
                      <Tooltip
                        placement="bottom"
                        text="Delete"
                        triggers={['hover', 'focus']}
                      >
                        <button
                          type="button"
                          className={styles['action-btn']}
                          onClick={handleDelete}
                        >
                          <Icon name={ICON_NAMES.delete} />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-auto">
                <div className="row">
                  <div className="col-auto">
                    {!!hashtags && renderHashtags()}
                  </div>

                  <div className="col-auto">
                    <Tooltip
                      placement="bottom"
                      text={formatDate(createdAt, DATE_TIME_FORMAT)}
                      triggers={['hover', 'focus']}
                    >
                      <p className={styles.date}>
                        {formatTimeAgo({
                          date: createdAt,
                          fromDate: serverDate,
                        })}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {(areRepliesShown || isCommentReplying) && (
        <ReplyForm isShown originComment={comment} />
      )}
      {!isReducedView && children}
    </div>
  );
}

export default CommentCard;
