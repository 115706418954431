import React, { useEffect, useRef, useState } from 'react';

import CommentThread from '../CommentsBar/CommentThread';
import styles from './CommentsSection.module.scss';

const SCROLL_DOWN_LABEL_SPACE = 30;

function CommentsSection(props) {
  const { comments, sectionNumber, commentsAlignUI } = props;
  const scrollAreaRef = useRef(null);

  if (commentsAlignUI.articlePageSize === null) return null;

  const sectionHeight = commentsAlignUI.articlePageSize;

  const [isSectionScrollable, setIsSectionScrollable] = useState(false);
  const [isTotallyScrolled, setIsTotallyScrolled] = useState(false);

  useEffect(() => {
    if (scrollAreaRef && scrollAreaRef.current) {
      const hasOverflow =
        scrollAreaRef.current.scrollHeight > scrollAreaRef.current.clientHeight;
      setIsSectionScrollable(hasOverflow);
    }
  }, [scrollAreaRef]);

  const customScrollHandler = event => {
    const element = event.target;

    if (!element) {
      return;
    }

    const isScrolled =
      element.scrollHeight - Math.abs(element.scrollTop) ===
      element.clientHeight;
    if (isScrolled !== isTotallyScrolled) {
      setIsTotallyScrolled(isScrolled);
    }
  };

  return (
    <div
      className={styles['comments-section']}
      style={{
        marginTop: sectionNumber === 0 ? 0 : '48px',
        marginBottom:
          sectionNumber === commentsAlignUI.pagesCount - 1 ? '48px' : 0,
        height: `${commentsAlignUI.articlePageSize}px`,
      }}
      key={props.key}
    >
      <div
        ref={scrollAreaRef}
        data-section={sectionNumber}
        style={{
          height: `${sectionHeight - SCROLL_DOWN_LABEL_SPACE}px`,
          overflow: 'auto',
        }}
        className={styles['comments-section-body']}
        onScroll={customScrollHandler}
      >
        {comments.map(comment => (
          <CommentThread key={comment._id} comment={comment} />
        ))}
      </div>
      {isSectionScrollable && !isTotallyScrolled && (
        <span className={styles['btn-scroll-to-see']}>Scroll to see more</span>
      )}
    </div>
  );
}

export default CommentsSection;
