import React, { useEffect } from 'react';
import { Container, Grid, List, ListSubheader, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/type';
import MyActivitiesSelectors from '../../redux/myActivities/selectors';
import MyActivitiesActions from '../../redux/myActivities/actions';
import ActivityCard from '../../components/ActivityCard/ActivityCard';

const MyActivities = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state: AppState) => state.auth.user._id);
  const userActivities = useSelector(
    MyActivitiesSelectors.selectUserActivities,
  );
  const globalActivities = useSelector(
    MyActivitiesSelectors.selectGlobalActivities,
  );
  const isLoading = useSelector(MyActivitiesSelectors.selectIsLoading);

  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(MyActivitiesActions.fetchActivities());
  }, [userId]);

  return (
    <Container>
      <Grid container spacing={3}>
        {isLoading}
        <Grid container item xs={12}>
          <List
            subheader={<ListSubheader>User Notifications</ListSubheader>}
            style={{ width: '100%', padding: '3' }}
          >
            <Paper>
              {userActivities.map((a: any, i: number) => {
                return (
                  <ActivityCard
                    key={a.id}
                    activity={a}
                    divider={userActivities.length > i + 1}
                  />
                );
              })}
            </Paper>
          </List>
        </Grid>
        <Grid container item xs={12}>
          <List
            subheader={<ListSubheader>Article Uploads</ListSubheader>}
            style={{ width: '100%', padding: '3' }}
          >
            <Paper>
              {globalActivities.map((a: any, i: number) => {
                return (
                  <ActivityCard
                    key={a.id}
                    activity={a}
                    divider={globalActivities.length > i + 1}
                  />
                );
              })}
            </Paper>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyActivities;
