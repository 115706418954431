import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import MainLayout from './MainLayout';

const selector = createStructuredSelector({});

const actions = {};

export { MainLayout };

export default withRouter(connect(selector, actions)(MainLayout));
