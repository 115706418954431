import React from 'react';
import { Link } from 'react-router-dom';

const ArticleLoadingError = ({ articleLoadingError }) => {
  return (
    <div className="container pt-5">
      {articleLoadingError.code === 'PERMISSION_DENIED' && (
        <p>
          To view the article, you must be in possession of the PDF. Pease drag
          and drop the PDF file into{' '}
          <Link to="/personal/articles">this page</Link>
        </p>
      )}
    </div>
  );
};

export default ArticleLoadingError;
