import React from 'react';

import DotsProgress from '../DotsProgress';
import styles from './Preloader.module.scss';

const Preloader = props => {
  const { className = '' } = props;

  return (
    <span className={`${styles.overlay} ${className}`}>
      <DotsProgress />
    </span>
  );
};

export default Preloader;
