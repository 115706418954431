import Base from './base';

export default class Activities extends Base {
  list = () => {
    return this.apiClient.get('notifications');
  }

  // list({ offset, limit, type }) {
  //   return this.apiClient.get('activities', { offset, limit, type });
  // }
  //
  // read(id) {
  //   return this.apiClient.post(`activities/${id}/read`);
  // }
}
