import React from 'react';
import ReactSelect from 'react-select';

import ErrorText from '../ErrorText';

import styles from './Select.module.scss';

export default function Select({
  field,
  form,
  type,
  placeholder,
  placeholderClassName,
  innerRef,
  options,
  setFieldValue,
  error,
  isTouched,
  ...restProps
}) {
  const isError = !!error && isTouched;

  return (
    <>
      <ReactSelect
        {...restProps}
        {...field}
        className={styles.select}
        placeholder={placeholder}
        value={
          Array.isArray(field.value)
            ? options
                .reduce((acc, option) => {
                  option.options
                    ? acc.push(...option.options)
                    : acc.push(option);
                  return acc;
                }, [])
                .filter(option => field.value.includes(option.value))
            : options.find(option => option.value === field.value) || ''
        }
        options={options}
        onChange={selected => {
          const nextValue = Array.isArray(selected)
            ? selected.map(option => option.value)
            : selected.value;

          form.setFieldValue(field.name, nextValue);
        }}
      />
      <ErrorText isShown={isError}>{error}</ErrorText>
    </>
  );
}
