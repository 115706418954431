import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import styles from './ArticleActivity.module.scss';

const LABELS = {
  replies: {
    single: 'reply',
    plural: 'replies',
  },
  threads: {
    single: 'comment',
    plural: 'comments',
  },
  likes: {
    single: 'like',
    plural: 'likes',
  },
};

const renderStatistic = (name, value) => {
  if (!value) return null;
  return value + ' ' + LABELS[name][value === 1 ? 'single' : 'plural'];
};

function ArticleActivity({ article }) {
  const { comments, likesCount } = article;

  const authUserId = useSelector(state => state.auth.user._id);

  if (comments.length === 0 && likesCount === 0) return null;

  const { threads, replies } = comments.reduce(
    (acc, comment) => {
      if (comment.threadId) acc.replies.push(comment);
      else acc.threads.push(comment);
      return acc;
    },
    {
      threads: [],
      replies: [],
    },
  );

  const statistic = [
    { name: 'threads', value: threads.length },
    { name: 'replies', value: replies.length },
    { name: 'likes', value: likesCount },
  ].filter(stat => stat.value);

  return (
    <Link
      className={styles['statistic-container']}
      to={{
        pathname: `/articles/${article.doi}`,
        search: queryString.stringify(
          {
            user: authUserId,
          },
          { skipNull: true },
        ),
      }}
    >
      Added{' '}
      {statistic.map((stat, index) => (
        <Fragment key={stat.name}>
          {renderStatistic(stat.name, stat.value)}
          {stat.value && statistic[index + 1] && statistic[index + 1].value
            ? ', '
            : null}
        </Fragment>
      ))}
    </Link>
  );
}

export default ArticleActivity;
