import { connect } from 'react-redux';

import { isUserAuthSelector } from '../../../../redux/auth/selectors';
import LoginToAction from './LoginToAction';

export default connect(state => {
  return {
    user: state.auth.user,
    isUserAuth: isUserAuthSelector(state),
  };
})(LoginToAction);
