import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './AuthorAvatar.module.scss';
import { getUserInitials } from '../../../utils/getUserInitials';

const AuthorAvatar = props => {
  const { user, isReply } = props;

  const userInitials = getUserInitials(user);

  return (
    <div
      className={classNames(styles['author-avatar'], {
        [styles['author-avatar--reply']]: isReply,
      })}
      title={user.name}
    >
      {userInitials}
    </div>
  );
};

AuthorAvatar.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default AuthorAvatar;
