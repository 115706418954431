import {
  compose,
  withProps,
  withPropsOnChange,
  branch,
  renderNothing,
  renderComponent,
  withState,
  withContext,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PagePreloader from '../../components/PagePreloader';
import * as articleActions from '../../redux/articles/actions';
import * as commentActions from '../../redux/comments/actions';
import * as commentsAlignUIActions from '../../redux/commentsAlignUI/actions';
import withRouteProtection from '../../HOC/withRouteProtection';
import withPdfUpload from './withPdfUpload.hoc';
import ArticlePdf from './ArticlePdf';
import ArticleUploader from './ArticleUploader';
import ArticleLoadingError from './ArticleLoadingError';
import withCompleteOnboarding from '../../HOC/withCompleteOnboarding';
import { getArticlePdfUrlByDoi } from '../../utils/getArticlePdfUrl';

const COMMENT_HEIGHT = 30;

export default compose(
  withCompleteOnboarding,
  branch(({ isPdfPersonal }) => isPdfPersonal, withRouteProtection),
  withPdfUpload,
  connect(
    state => {
      const { isLoading, data, error } = state.articles;

      return {
        isLoading,
        article: data,
        comments: state.comments.list,
        articleLoadingError: error,
        allowSyncScroll: state.commentsAlignUI.allowSyncScroll,
      };
    },
    {
      getArticleByDoi: articleActions.getArticleByDoi,
      getArticleByFingerprint: articleActions.getArticleByFingerprint,
      setArticleReccomnedationData: articleActions.setArticleReccomnedationData,
      setSelectedHighlights: commentActions.setSelectedHighlights,
      getComments: commentActions.getComments,
      getStatistic: commentActions.getStatistic,
      resetSelectedHighlights: commentActions.resetSelectedHighlights,
      setAllowSyncScroll: commentsAlignUIActions.setAllowSyncScroll,
      setLimitedSectionScrolling:
        commentsAlignUIActions.setLimitedSectionScrolling,
      setAreRepliesShown: commentActions.setAreRepliesShown,
      getReplies: commentActions.getReplies,
    },
  ),
  withProps(props => {
    const { fingerprint } = props.match.params;
    const queryParams = new URLSearchParams(window.location.search);
    const commentId = queryParams.get('commentId');
    const replyId = queryParams.get('replyId');

    if (props.isPdfPersonal)
      return {
        fingerprintUrlParam: fingerprint,
      };

    const doi = props.match.params.doi;

    return {
      doi,
      commentId,
      replyId,
    };
  }),
  withPropsOnChange(['doi'], props => {
    if (!props.doi) return;

    if (!props.article || props.doi !== props.article.doi) {
      props.getArticleByDoi({ doi: props.doi});
    }
  }),
  withPropsOnChange(
    ['fingerprintUrlParam'],
    ({
      fingerprintUrlParam,
      pdfBinaryData,
      getArticleByFingerprint,
      setPdfBinaryData,
    }) => {
      if (!pdfBinaryData && fingerprintUrlParam) {
        getArticleByFingerprint(fingerprintUrlParam);
        return;
      }

      if (!fingerprintUrlParam) {
        setPdfBinaryData(null);
      }
    },
  ),
  withPropsOnChange(
    ['fingerprintUrlParam', 'article'],
    ({ fingerprintUrlParam, article }) => {
      if (fingerprintUrlParam && article && article.doi)
        window.history.replaceState(
          null,
          null,
          `/articles/${article.doi}v${article.version}`,
        );
    },
  ),
  withState('isTitleSelected', 'setIsTitleSelected', false),
  withState('isFilterVisible', 'showFilter', false),
  withHandlers({
    loadReplies: props => () => {
      const { commentId, setAreRepliesShown, setSelectedHighlights } = props;
      setSelectedHighlights([{ id: commentId, offset: 0, scrollOff: true }]);
      props.getReplies(commentId);
      setAreRepliesShown(commentId, true);
    },
  }),
  withHandlers(() => {
    let pdfScrollRef = null;

    return {
      pdfHighlighterRef: () => ref => (pdfScrollRef = ref),
      pdfScrollTo: () => pdfScrollRef,
    };
  }),
  withHandlers({
    toggleFilter: ({ isFilterVisible, showFilter }) => () => {
      showFilter(!isFilterVisible);
    },
    selectComment: ({
      pdfScrollTo,
      setIsTitleSelected,
      setSelectedHighlights,
      setAllowSyncScroll,
      setLimitedSectionScrolling,
    }) => (comment, offset = 0) => {
      // disable sync scrolling comments when pdf is scrolling to selected comment position
      setAllowSyncScroll(false);

      setSelectedHighlights([{ id: comment._id, offset: 0, scrollOff: true }]);

      const isTitleSelected = comment.position.rects.length === 0;
      setIsTitleSelected(isTitleSelected);

      if (isTitleSelected) {
        const TITLE_OFFSET_TOP = 160;

        const scrollToComment = document.querySelector(
          `[data-comment-id="${comment._id}"]`,
        );

        const sectionNode = document.querySelector(`[data-section="0"]`);

        setLimitedSectionScrolling(comment.position.pageNumber - 1);

        sectionNode.scrollTo(
          0,
          sectionNode.scrollTop +
            scrollToComment.getBoundingClientRect().top -
            TITLE_OFFSET_TOP +
            COMMENT_HEIGHT / 2,
        );
        return;
      }

      pdfScrollTo({
        id: comment._id,
        position: {
          ...comment.position,
          boundingRect: {
            ...comment.position.boundingRect,
            y1: comment.position.boundingRect.y1 - offset,
            y2: comment.position.boundingRect.y2 + offset,
          },
        },
      })
        .then(() => {})
        .catch(err => console.log(err));
      // pdfScrollTo({
      //   id: comment._id,
      //   position: {
      //     ...comment.position,
      //     boundingRect: {
      //       ...comment.position.boundingRect,
      //       y1: comment.position.boundingRect.y1 - offset,
      //       y2: comment.position.boundingRect.y2 + offset,
      //     },
      //   },
      // }).then(() => {
      //   const selectedHighlight = document.querySelector(
      //     `[data-highlight-id="${comment._id}"]`,
      //   );
      //   const scrollToComment = document.querySelector(
      //     `[data-comment-id="${comment._id}"]`,
      //   );
      //
      //   const selectedHighlightOffset = selectedHighlight.getBoundingClientRect()
      //     .top;
      //
      //   const sectionNode = document.querySelector(
      //     `[data-section="${comment.position.pageNumber - 1}"]`,
      //   );
      //
      //   setLimitedSectionScrolling(comment.position.pageNumber - 1);
      //
      //   sectionNode.scrollTo(
      //     0,
      //     sectionNode.scrollTop +
      //       scrollToComment.getBoundingClientRect().top -
      //       selectedHighlightOffset +
      //       COMMENT_HEIGHT / 2,
      //   );
      // });
    },
    handleLoadMetadata: ({
      article,
      isPdfPersonal,
      fingerprintUrlParam,
      uploadPdf,
      setArticleReccomnedationData,
      getComments,
      getStatistic,
    }) => (fingerprint, title) => {
      if (!isPdfPersonal && !article.fingerprint) {
        // api.articles
        //   .setFingerprint(article._id, fingerprint)
        //   .then(({ data }) => {
        //     getComments();
        //     getStatistic();
        //     setArticleReccomnedationData({
        //       isLiked: data.isLiked,
        //       likesCount: data.likesCount,
        //     });
        //   });
      }

      if (isPdfPersonal && !fingerprintUrlParam) {
        uploadPdf(fingerprint, title);
      }
    },
    getPdfSrc: ({ pdfBinaryData, fingerprintUrlParam, article }) => () => {
      if (pdfBinaryData) return pdfBinaryData;

      if (fingerprintUrlParam) return article.pdfUrl;

      return getArticlePdfUrlByDoi(
        article.doi,
        article.server,
        article.version,
      );
    },
  }),
  withContext(
    {
      isFilterVisible: PropTypes.bool,
      isTitleSelected: PropTypes.bool,
      setIsTitleSelected: PropTypes.func,
      toggleFilter: PropTypes.func,
      toggleComments: PropTypes.func,
      showFilter: PropTypes.func,
      selectComment: PropTypes.func,
      pdfHighlighterRef: PropTypes.func,
    },
    ({
      isFilterVisible,
      isTitleSelected,
      setIsTitleSelected,
      toggleFilter,
      toggleComments,
      showFilter,
      selectComment,
      pdfHighlighterRef,
    }) => ({
      isFilterVisible,
      isTitleSelected,
      setIsTitleSelected,
      toggleFilter,
      toggleComments,
      showFilter,
      selectComment,
      pdfHighlighterRef,
    }),
  ),
  branch(
    ({ isPdfPersonal, pdfBinaryData, fingerprintUrlParam }) =>
      isPdfPersonal && !fingerprintUrlParam && !pdfBinaryData,
    renderComponent(ArticleUploader),
  ),
  branch(
    ({ articleLoadingError }) => articleLoadingError,
    renderComponent(ArticleLoadingError),
  ),
  branch(({ isLoading }) => isLoading, renderComponent(PagePreloader)),
  branch(
    ({ article, pdfBinaryData }) => article == null && !pdfBinaryData,
    renderNothing,
  ),
)(ArticlePdf);
