import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';

import Select from '../../../components/Select';
import Icon, { ICON_NAMES } from '../../../components/Icon';
import styles from './CommentsFilter.module.scss';
import iconStyles from '../../../components/Icon/Icon.module.scss';
import PercentageLine from '../../../components/PercentageLine';
import useCommentsHashtagsSegments from '../../../hooks/useCommentsHashtagsSegments';

function CommentsFilter(props) {
  const {
    isFilterVisible,
    // filterOptions,
    commentsStats,
    handleSubmit,
    handleReset,
    toggleFilter,
    allFilters,
  } = props;

  if (!commentsStats.commentsCountByHashtags) return null;

  const totalFilteredComments = Object.values(
    commentsStats.commentsCountByHashtags,
  ).reduce((acc, stat) => {
    acc += stat.count;
    return acc;
  }, 0);

  const commentsHashtagsSegments = useCommentsHashtagsSegments(
    commentsStats.commentsCountByHashtags,
  );

  const caretArrowStyle = cn(iconStyles['arrow'], {
    [iconStyles['up']]: isFilterVisible,
    [iconStyles['down']]: !isFilterVisible,
  });

  return (
    <div data-comments-filter className={styles['filter-container']}>
      <div className={styles['filter-body']}>
        <div className={styles.header}>
          <div className="row justify-content-between mb-2">
            <div className={cn('col-auto', styles['comments-count-title'])}>
              {`${commentsStats.filteredThreadsCount} of ${commentsStats.totalThreadsCount} comments, ${commentsStats.filteredRepliesCount} of ${commentsStats.totalRepliesCount} replies`}
            </div>
            <div className="col-auto">
              <button
                type="button"
                onClick={toggleFilter}
                className={styles['filter-toggler']}
              >
                <Icon name={ICON_NAMES.filter} />
                <span className={styles['filter-toggler__label']}>
                  Filter <i className={caretArrowStyle}></i>
                </span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <PercentageLine
                extended
                totalCount={totalFilteredComments}
                segments={commentsHashtagsSegments}
                className={styles['comments-hashtags-chart']}
              />
            </div>
          </div>
        </div>
        {isFilterVisible && (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className={styles['fields']}>
              <Field
                name="hashtags"
                placeholder="Select hashtags"
                options={allFilters.hashtags}
                component={Select}
                isMulti
              />
              {/*<Field*/}
              {/*  name="user"*/}
              {/*  placeholder="Select user"*/}
              {/*  options={allFilters.users}*/}
              {/*  component={Select}*/}
              {/*/>*/}
              {/*<Field*/}
              {/*  name="affiliations"*/}
              {/*  placeholder="Select affiliations"*/}
              {/*  options={filterOptions.affiliations}*/}
              {/*  component={Select}*/}
              {/*  isMulti*/}
              {/*/>*/}
            </div>
            <div className="row justify-content-between">
              <div className="col-auto">
                <button
                  type="submit"
                  className={cn(styles.action, styles['action--primary'])}
                >
                  Apply
                </button>
              </div>
              <div className="col-auto">
                <button type="reset" className={styles.action}>
                  Reset
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default CommentsFilter;
