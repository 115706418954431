import React from 'react';
import classNames from 'classnames';
// import Tooltip from 'react-tooltip';
import onClickOutside from 'react-onclickoutside';

import styles from './Title.module.scss';
// import pen from '../../../assets/images/pen.svg';

const Title = ({
  text,
  // isCommentFormOpen,
  // isTitleSelected,
  // onCommentTitle,
  onTitleClick,
  onOutsideClick,
}) => {
  // const [isMouseOver, setIsMouseOver] = useState(false);

  Title.handleClickOutside = () => onOutsideClick();

  return (
    <div role="button" tabIndex="0" onClick={onTitleClick}>
      <p
        tabIndex="0"
        className={classNames(styles.title,
        //     {
        //   [styles['title--commenting']]: isCommentFormOpen || isMouseOver,
        //   [styles['title--selected']]: isTitleSelected,
        // }
        )}
        data-tip
        data-for="add-title-comment"
      >
        {text}
      </p>
      {/*<Tooltip*/}
      {/*  id="add-title-comment"*/}
      {/*  clickable*/}
      {/*  effect="solid"*/}
      {/*  place="bottom"*/}
      {/*  delayHide={200}*/}
      {/*  className={styles['add-popup']}*/}
      {/*  afterShow={() => setIsMouseOver(true)}*/}
      {/*  afterHide={() => setIsMouseOver(false)}*/}
      {/*>*/}
      {/*  <button*/}
      {/*    type="button"*/}
      {/*    className={styles['add-button']}*/}
      {/*    onClick={event => {*/}
      {/*      event.stopPropagation();*/}
      {/*      onCommentTitle(event);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <img className={styles.pen} src={pen} alt="Pen" />*/}
      {/*  </button>*/}
      {/*</Tooltip>*/}
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Title.handleClickOutside,
};

export default onClickOutside(Title, clickOutsideConfig);
