import { withFormik } from 'formik';
import { connect } from 'react-redux';
import {
  compose,
  getContext,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose';
import PropTypes from 'prop-types';

import CommentsFilter from './CommentsFilter';
import * as commentActions from '../../../redux/comments/actions';

export default compose(
  getContext({
    isFilterVisible: PropTypes.bool,
    toggleFilter: PropTypes.func,
  }),
  connect(
    state => ({
      articleId: state.articles.data._id,
      filter: state.comments.filter,
      commentsStats: {
        totalThreadsCount: state.comments.totalThreadsCount,
        totalRepliesCount: state.comments.totalRepliesCount,
        filteredThreadsCount: state.comments.filteredThreadsCount,
        filteredRepliesCount: state.comments.filteredRepliesCount,
        commentsCountByHashtags: state.comments.commentsCountByHashtags,
      },
      allFilters: state.comments.allFilters,
    }),
    {
      applyFilter: commentActions.applyFilter,
      resetFilter: commentActions.resetFilter,
      getFilters: commentActions.getFilters,
    },
  ),
  withState('isFilterLoading', 'setIsFilterLoading', true),
  withState('filterOptions', 'setFilterOptions', {
    users: [],
    hashtags: [],
    affiliations: [],
  }),
  withFormik({
    enableReinitialize: true,
    displayName: 'SearchForm',

    mapPropsToValues: ({ filter }) => filter,

    handleSubmit: (values, { props }) => {
      props.applyFilter(values);
    },
  }),
  withHandlers({
    handleReset: ({ resetForm, resetFilter }) => () => {
      resetForm();
      resetFilter();
    },
    onLoadInitialData: ({ dispatch, articleId, getFilters }) => () => {
      getFilters(articleId);
    },
  }),
  withPropsOnChange(
    [],
    ({
      articleId,
      filterOptions,
      setFilterOptions,
      setIsFilterLoading,
      onLoadInitialData,
    }) => {
      onLoadInitialData();
      // setIsFilterLoading(true);
      // api.comments
      //   .getFilter(articleId)
      //   .then(({ data }) => {
      //     setFilterOptions({
      //       ...filterOptions,
      //       users: data.users.map(user => ({
      //         value: user._id,
      //         label: user.name,
      //       })),
      //       hashtags: [
      //         {
      //           label: 'Popular hashtags',
      //           options: data.hashtags.map(hashtag => ({
      //             value: hashtag.value,
      //             label: hashtag.value,
      //           })),
      //         },
      //       ],
      //       affiliations: data.affiliations.map(affiliation => ({
      //         value: affiliation,
      //         label: affiliation,
      //       })),
      //     });
      //   })
      //   .finally(() => setIsFilterLoading(false));
    },
  ),
)(CommentsFilter);
