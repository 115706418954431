import { connect } from 'react-redux';
import { compose, getContext, withHandlers } from 'recompose';
import PropTypes from 'prop-types';

import * as commentActions from '../../../redux/comments/actions';
import PdfContent from './PdfContent';
import isHighlightStandsOnPoint from './isHighlightStandsOnPoint.util';
import { pdfCommentsSelector } from '../../../redux/comments/selectors';

export default compose(
  connect(
    state => ({
      comments: pdfCommentsSelector(state),
    }),
    {
      setSelectedHighlights: commentActions.setSelectedHighlights,
    },
  ),
  getContext({
    pdfHighlighterRef: PropTypes.func,
  }),
  withHandlers({
    handlePdfClick: ({ comments, setSelectedHighlights }) => ({
      position,
      pageNumber,
      containerWidth,
      containerHeight,
      globalPosition,
    }) => {
      const idsToHighlight = comments
        .filter(comment =>
          isHighlightStandsOnPoint(comment, {
            position,
            pageNumber,
            containerWidth,
            containerHeight,
          }),
        )
        .map(comment => ({
          id: comment._id,
          offset: globalPosition.y,
          pageNumber,
        }));

      setTimeout(() => setSelectedHighlights(idsToHighlight));
    },
  }),
)(PdfContent);
