export default function appendCommentByPosition(newComment, commentlist) {
  const commentsByPage = getMapByPage(commentlist);
  const newCommentPage = newComment.position.pageNumber;

  if (!newCommentPage) return [newComment, ...commentlist];

  if (!Array.isArray(commentsByPage[newCommentPage])) {
    commentsByPage[newCommentPage] = [newComment];
    return getArrayByMap(commentsByPage);
  }

  let isNewCommentAdded = false;
  const updatedSortedCommentList = commentsByPage[newCommentPage].reduce(
    (acc, comment) => {
      if (
        !isNewCommentAdded &&
        (!newComment.position.boundingRect.y1 ||
          newComment.position.boundingRect.y1 <
            comment.position.boundingRect.y1)
      ) {
        isNewCommentAdded = true;
        acc.push(newComment);
      }

      acc.push(comment);

      return acc;
    },
    [],
  );
  if (!isNewCommentAdded) updatedSortedCommentList.push(newComment);

  commentsByPage[newCommentPage] = updatedSortedCommentList;

  return getArrayByMap(commentsByPage);
}

function getMapByPage(commentlist) {
  return commentlist.reduce((map, comment) => {
    const { pageNumber = 0 } = comment.position;
    const hasCommentByPage = Array.isArray(map[pageNumber]);

    if (hasCommentByPage) map[pageNumber].push(comment);
    else map[pageNumber] = [comment];

    return map;
  }, {});
}

function getArrayByMap(commentMap) {
  return Object.values(commentMap).reduce(
    (acc, commentsByPage) => acc.concat(commentsByPage),
    [],
  );
}
