import { AppDispatch } from '../type';
import {
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
} from './actionTypes';
import api from '../../api';

export default class MyActivitiesActions {
  static fetchActivities = () => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: FETCH_ACTIVITIES_REQUEST });

      const data = await api.activities.list();

      dispatch({
        type: FETCH_ACTIVITIES_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_ACTIVITIES_FAILURE,
        payload: { error: err },
      });
    }
  };
}
