import * as yup from 'yup';

const emptyFormState = {
  firstName: '',
  lastName: '',
  profession: [],
  affiliation: [],
  optOut: false,
};

const professions = [
  { label: 'Physician', value: 'Physician' },
  { label: 'Other health care provider', value: 'Other health care provider' },
  { label: 'Clinical researcher', value: 'Clinical researcher' },
  { label: 'Laboratory researcher', value: 'Laboratory researcher' },
  { label: 'Research management', value: 'Research management' },
  { label: 'Patient/patient advocate', value: 'Patient/patient advocate' },
  { label: 'Sales/marketing', value: 'Sales/marketing' },
  { label: 'Press/media', value: 'Press/media' },
  { label: 'Other', value: 'Other' },
];

const affiliations = [
  { label: 'Academic institution', value: 'Academic institution' },
  { label: 'Healthcare facility', value: 'Healthcare facility' },
  { label: 'Corporation/company', value: 'Corporation/company' },
  { label: 'Private practice', value: 'Private practice' },
  { label: 'Non-profit organization', value: 'Non-profit organization' },
  { label: 'Other', value: 'Other' },
];

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^\s*\S[\s\S]*$/, '*This field cannot contain only blankspaces')
    .required('*required'),
  lastName: yup
    .string()
    .matches(/^\s*\S[\s\S]*$/, '*This field cannot contain only blankspaces')
    .required('*required'),
  profession: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    ),
  affiliation: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    }),
  ),
});

const checkBoxMessage =
  'Opt-in to contact from poster authors. DropCite will not share your email for any commercial purpose. We are offering poster authors the option of contacting interested commenters to provide direct responses to comments. If you would be open to email contact under these circumstances, click here: ';

export {
  emptyFormState,
  professions,
  affiliations,
  validationSchema,
  checkBoxMessage,
};
