import React, { useState} from "react";
import { connect } from "react-redux";

import * as commentActions from "../../redux/comments/actions";
import styles from "./CommentForm.module.scss";
import pen from "../../assets/images/pen.svg";
import { useDispatch } from "react-redux";
import * as modalActions from "../../redux/modals/actions";
import * as MODALS from "../../components/Modal/modalNames";
import * as ORIENTATION from "../../constants/screen-orientations";
import useScreenOrientation from "../../hooks/useScreenOrientation";

function HighlightForm(props) {
    const { highlight, onOpen, onAddHighlight } = props;
    const dispatch = useDispatch();
    const screenOrientation = useScreenOrientation();

    const [isOpen, setIsOpen] = useState(false);

    if (isOpen) return null;

    const handleClick = () => {

        if (typeof onOpen === "function") onOpen();
        setIsOpen(true);
        onAddHighlight(highlight);
    };

    return (
        <div className={styles.tip} onClick={handleClick}>
            <img className={styles.pen} src={pen} alt="Pen" />
        </div>
    );
}

export default connect(null, {
    onAddHighlight: commentActions.addHighlight,
})(HighlightForm);
