import { connect } from 'react-redux';

import SignIn from './SignIn';

export default connect(state => {
  return {
    isAuth: state.auth.isAuth,
    error: state.auth.error,
  };
})(SignIn);
