import React from 'react';

import CommentCardEmpty from '../CommentCard/CommentCardEmpty';
import styles from './CommentsBar.module.scss';
import CommentsSection from '../CommentsSection';

const CommentsBar = props => {
  const { comments, pagesCount, scrollRef, commentsAlignUI } = props;

  const commentsBySection = comments.reduce((acc, comment) => {
    const commentSectionNumber = comment.position.pageNumber || 1;

    if (!acc[commentSectionNumber - 1]) acc[commentSectionNumber - 1] = [];

    acc[commentSectionNumber - 1].push(comment);

    return acc;
  }, []);

  const commentSections = Array.from(
    Array(pagesCount).keys(),
  ).map(pageNumber => (
    <CommentsSection
      key={pageNumber}
      comments={commentsBySection[pageNumber] || []}
      sectionNumber={pageNumber}
      pagesCount={pagesCount}
    />
  ));

  return (
    <section
      ref={scrollRef}
      data-comments-list
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        paddingTop: `${commentsAlignUI.articleTitleSize}px`,
        scrollbarWidth: 0,
        msOverflowStyle: 'none',
      }}
    >
      {comments.length === 0 ? <CommentCardEmpty /> : commentSections}
    </section>
  );
};

export default CommentsBar;
