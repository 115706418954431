import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as modalNames from './modalNames';
import Confirmation from './modals/Confirmation';
import ImagePreview from './modals/ImagePreview';
import UserCreation from './modals/UserCreation';
import LoginToAction from './modals/LoginToAction';
import * as modalActions from '../../redux/modals/actions';
{/* import DeviceOrientation from './modals/DeviceOrientation'; */}

const modalsMap = {
  [modalNames.CONFIRMATION]: Confirmation,
  [modalNames.IMAGE_PREVIEW]: ImagePreview,
  [modalNames.USER_CREATION]: UserCreation,
  [modalNames.LOGIN_TO_ACTION]: LoginToAction,
};

const ModalsContainer = props => {
  const { toShow } = props;

  const renderByName = props => {
    const { name } = props;
    const Modal = modalsMap[name];

    if (!Modal) return null;

    return <Modal key={name} isShown {...props} />;
  };

  return toShow.map(modal => renderByName(modal));
};

ModalsContainer.propTypes = {
  toShow: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, onAction: PropTypes.func }),
  ),
};

export default connect(
  state => {
    const { toShow } = state.modals;

    return {
      toShow,
    };
  },
  {
    hide: modalActions.hide,
  },
)(ModalsContainer);
