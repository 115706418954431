import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import TopInfo from "./TopInfo";
import ArticleRecommendations from "./ArticleRecommendations";
import styles from "./ArticlePdf.module.scss";
import PdfContent from "./PdfContent";
import CommentsBar from "./CommentsBar";
import CommentsFilter from "./CommentsFilter";
import CommentForm from "./CommentForm";
import PageNavigation from "./PageNavigation";
import DotsProgress from "../../components/DotsProgress";
import PdfLoader from "../../components/pdf/PdfLoader";
import Preloader from "../../components/Preloader";
// import VersionSelect from './VersionSelect';
import { debounce } from 'lodash';
import Hidden from '@material-ui/core/Hidden';
import * as ORIENTATION from '../../constants/screen-orientations';
import { useDispatch } from 'react-redux';
import * as modalActions from '../../redux/modals/actions';
import * as MODALS from '../../components/Modal/modalNames';
import scrollTo from '../../utils/scrollTo';
import _ from 'lodash';
import {Button} from "@material-ui/core";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import useScreenOrientation from "../../hooks/useScreenOrientation";

function ArticlePdf({
  article,
  isPdfPersonal,
  isPdfUploading,
  resetArticle,
  handleLoadMetadata,
  resetSelectedHighlights,
  setIsTitleSelected,
  allowSyncScroll,
  setAllowSyncScroll,
  getPdfSrc,
  commentId,
  replyId,
  comments,
                        loadReplies,
}) {
    const dispatch = useDispatch();
    const commentsScrollArea = useRef(null);
    const screenOrientation = useScreenOrientation();
    const [openThumbnails, setOpenThumbnails] = useState(true);
    const [onSelectedPage,setOnSelectedPage]=useState(true)

    const showHideThumbnails = (e) => {
      setOpenThumbnails(prev => !prev);
      e.stopPropagation();
    };

    useEffect(() => () => resetArticle(), []);

  const rootCommentScroll = () => {
    if (!_.isNil(commentId) && !_.isNil(comments) && onSelectedPage) {
      const cc = comments.find(com => commentId === com._id);
      if (!_.isNil(cc)) {
        scrollTo(
          document.querySelector(`[data-article-container]`),
          document.querySelector(
            `[data-page-number="${cc.position.pageNumber}"]`,
          ),
          100,
        );
        setOnSelectedPage(false);
        if(!_.isNil(replyId)){
          loadReplies();
        }
      }
    }
  };

  useEffect(() => rootCommentScroll());


    const src = getPdfSrc();

    const runPdfScrollingSideEffects = debounce(() => {
        // TODO: verify whether we need this feature turned on or off
        // resetSelectedHighlights();
        // setIsTitleSelected(false);
    }, 300);

    const onPdfScroll = (event) => {
        if (commentsScrollArea.current != null) {
            commentsScrollArea.current.scrollTo(0, event.target.scrollTop);
        }

        if (!allowSyncScroll) {
            setAllowSyncScroll(true);
            return;
        }

        runPdfScrollingSideEffects();
    };

    return (
        <div
            className={classNames(
                "container justify-content-center px-2",
                styles["article-page"],
                {
                    [styles["article-page--uploading"]]: isPdfUploading,
                }
            )}
        >
            {isPdfUploading && (
                <Preloader className={styles["pdf-uploading-preloader"]} />
            )}
            {article && article.title && (
                <Helmet>
                    <title>DropCite: {article.title}</title>
                </Helmet>
            )}
            <PdfLoader
                src={src}
                beforeLoad={
                    <div
                        className={classNames(
                            styles["col-reforged-8"],
                            styles.column
                        )}
                    >
                        <div className="d-flex justify-content-center">
                            <DotsProgress />
                        </div>
                    </div>
                }
                onLoadMetadata={handleLoadMetadata}
            >
                {(pdfDocument) => (
                    <>
                        <Hidden smDown>
                          {openThumbnails && (
                            <div
                                className={classNames(
                                    styles["col-reforged-1"],
                                    styles.column,
                                    styles["column--scrollable"]
                                )}
                            >
                                <div className="mb-3">
                                    {/*{!isPdfPersonal && <VersionSelect />}*/}
                                    {article && <ArticleRecommendations />}
                                    <PageNavigation pdfDocument={pdfDocument} />
                                </div>
                            </div>
                          )}
                          <div>
                            <Button
                                onClick={showHideThumbnails}
                                style={{ minWidth: '5px', maxWidth: '8px', marginTop:'25px' }}
                            >
                              {openThumbnails ? <ChevronLeft /> :  <ChevronRight />}
                            </Button>
                          </div>
                        </Hidden>
                        <div
                            className={classNames(
                                openThumbnails ? [
                                    `${
                                        screenOrientation ===
                                        ORIENTATION.LANDSCAPE
                                            ? "col-6"
                                            : "col-12"
                                    } col-lg-7`,
                                ] : [`${
                                    screenOrientation ===
                                    ORIENTATION.LANDSCAPE
                                        ? "col-6"
                                        : "col-12"
                                } col-lg-8`],
                                // ["col-12 col-lg-7"],
                                styles.column,
                                styles.columnMain
                            )}
                        >
                            <article
                                data-article-body
                                className={styles["article-body"]}
                            >
                                <PdfContent
                                    pdfDocument={pdfDocument}
                                    onPdfScroll={onPdfScroll}
                                    renderTitle={() =>
                                        article &&
                                        article.title && (
                                            <TopInfo
                                                title={article.title}
                                                date={article.date}
                                                serverName={article.server}
                                            />
                                        )
                                    }
                                />
                            </article>
                        </div>
                        {article && screenOrientation === ORIENTATION.LANDSCAPE && (
                            <div
                                className={classNames(
                                        ["col-6 col-md-4"],
                                    styles["comments-column"]
                                )}
                            >
                                <CommentsFilter />
                                <CommentForm />
                                <CommentsBar scrollRef={commentsScrollArea} />
                            </div>
                        )}
                    </>
                )}
            </PdfLoader>
        </div>
    );
}

export default ArticlePdf;
