import appendCommentByPosition from './utils/appendCommentByPosition';
import * as actionTypes from './actionTypes';
import sortRepliesAsTree from './utils/sortRepliesAsTree';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const INITIAL_FILTER = {
  user: null,
  hashtags: [],
  affiliations: [],
};

const INITIAL_STATE = {
  list: [],
  selectedHighlights: [],
  allFilters: INITIAL_FILTER,
  filter: INITIAL_FILTER,
  statistic: null,
  replyingCommentId: null,
  newHighlight: null,
  isCommentFormOpen: false,
  totalThreadsCount: null,
  totalRepliesCount: null,
  filteredThreadsCount: null,
  filteredRepliesCount: null,
  commentsCountByHashtags: null,
};

function comments(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.APPEND_COMMENT: {
      return {
        ...state,
        list: appendCommentByPosition(payload.comment, state.list),
        isCommentFormOpen: false,
        newHighlight: null,
        selectedHighlights: [{ id: payload.comment._id, offset: null }],
      };
    }

    case actionTypes.DELETE_COMMENT:
      return {
        ...state,
        list: state.list.filter(thread => thread._id !== payload._id),
      };

    case actionTypes.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        list: payload.list.map(item => {
          const stateItem = state.list.find(i => i._id === item._id);
          return {
            ...stateItem,
            ...item,
          };
        }),
        totalThreadsCount: payload.totalThreadsCount,
        totalRepliesCount: payload.totalRepliesCount,
        filteredThreadsCount: payload.filteredThreadsCount,
        filteredRepliesCount: payload.filteredRepliesCount,
        commentsCountByHashtags: payload.commentsCountByHashtags,
      };

    case actionTypes.SET_REPLYING_COMMENT: {
      return { ...state, replyingCommentId: payload.id };
    }

    case actionTypes.SET_ARE_REPLIES_SHOWN: {
      const { threadId, areRepliesShown } = payload;

      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === threadId || !threadId
            ? {
                ...comment,
                areRepliesShown,
              }
            : { ...comment, areRepliesShown: false },
        ),
      };
    }

    case actionTypes.GET_REPLIES_SUCCESS: {
      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === payload.threadId
            ? {
                ...comment,
                replies: sortRepliesAsTree(payload.list),
                repliesCount: payload.list.length,
              }
            : comment,
        ),
      };
    }

    case actionTypes.APPEND_REPLY: {
      return {
        ...state,
        list: state.list.map(comment => {
          return comment._id === payload.threadId
            ? {
                ...comment,
                replies:
                  comment.repliesCount > 0
                    ? sortRepliesAsTree([...comment.replies, payload])
                    : [payload],
                repliesCount: parseInt(comment.repliesCount) + 1,
              }
            : comment;
        }),
      };
    }

    case actionTypes.DELETE_REPLY: {
      return {
        ...state,
        list: state.list.map(comment => {
          return comment._id === payload.threadId
            ? {
                ...comment,
                replies: comment.replies.filter(
                  reply =>
                    reply._id !== payload._id && reply.quotedId !== payload._id,
                ),
                repliesCount: parseInt(comment.repliesCount) - 1,
              }
            : comment;
        }),
      };
    }

    case actionTypes.INCREASE_REPLIES_COUNT: {
      return {
        ...state,
        list: state.list.map(comment => {
          return comment._id === payload.threadId
            ? {
                ...comment,
                repliesCount: parseInt(comment.repliesCount) + 1,
              }
            : comment;
        }),
      };
    }

    case actionTypes.CREATE_LIKE_SUCCESS: {
      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === payload.commentId
            ? {
                ...comment,
                likesCount: comment.likesCount
                  ? parseInt(comment.likesCount) + 1
                  : 1,
                isLiked: true,
              }
            : comment,
        ),
      };
    }

    case actionTypes.REMOVE_LIKE_SUCCESS: {
      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === payload.commentId
            ? {
                ...comment,
                likesCount: comment.likesCount
                  ? parseInt(comment.likesCount) - 1
                  : null,
                isLiked: false,
              }
            : comment,
        ),
      };
    }

    case actionTypes.CREATE_REPLY_LIKE_SUCCESS: {
      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === payload.threadId
            ? {
                ...comment,
                replies: comment.replies.map(reply =>
                  reply._id === payload.replyId
                    ? {
                        ...reply,
                        likesCount: reply.likesCount
                          ? parseInt(reply.likesCount) + 1
                          : 1,
                        isLiked: true,
                      }
                    : reply,
                ),
              }
            : comment,
        ),
      };
    }

    case actionTypes.REMOVE_REPLY_LIKE_SUCCESS: {
      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === payload.threadId
            ? {
                ...comment,
                replies: comment.replies.map(reply =>
                  reply._id === payload.replyId
                    ? {
                        ...reply,
                        likesCount: comment.reply
                          ? parseInt(reply.likesCount) - 1
                          : 0,
                        isLiked: false,
                      }
                    : reply,
                ),
              }
            : comment,
        ),
      };
    }

    case actionTypes.SHARE_COMMENT_SUCCESS: {
      return {
        ...state,
        list: state.list.map(comment =>
          comment._id === payload.commentId
            ? {
                ...comment,
                isPrivate: false,
              }
            : comment,
        ),
      };
    }

    case actionTypes.GET_STATISTIC_SUCCESS: {
      return { ...state, statistic: payload.statistic };
    }

    case actionTypes.RESET_FILTER:
      return { ...state, filter: INITIAL_FILTER };

    case actionTypes.APPLY_FILTER: {
      return { ...state, filter: { ...state.filter, ...payload.values } };
    }

    case actionTypes.ADD_HIGHLIGHT: {
      return {
        ...state,
        isCommentFormOpen: true,
        newHighlight: payload.highlight,
      };
    }

    case actionTypes.SET_SELECTED_HIGHLIGHTS: {
      return { ...state, selectedHighlights: payload.selectedHighlights };
    }
    case actionTypes.RESET_SELECTED_HIGHLIGHTS: {
      return {
        ...state,
        selectedHighlights: [],
      };
    }

    case actionTypes.CANCEL_COMMENT_CREATION: {
      return {
        ...state,
        isCommentFormOpen: false,
        newHighlight: null,
      };
    }

    case actionTypes.GET_FILTERS_SUCCESS: {
      return {
        ...state,
        allFilters: {
          ...payload,
          users: payload.users.map(user => ({
            value: user._id,
            label: user.name,
          })),
          hashtags: [
            {
              label: 'Popular hashtags',
              options: payload.hashtags.map(hashtag => ({
                value: hashtag.value,
                label: hashtag.value,
              })),
            },
          ],
        },
      };
    }

    case actionTypes.GET_FILTERS_ERROR: {
      return {
        ...state,
        allFilters: INITIAL_STATE,
      };
    }

    default:
      return state;
  }
}

const persistConfig = {
  key: 'comments',
  storage,
};

export default persistReducer(persistConfig, comments);
