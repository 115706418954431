import Base from './base';

export default class ExpertiseAreas extends Base {
  list(query) {
    return this.apiClient.get('expertise-areas', query);
  }

  create({ value }) {
    return this.apiClient.post('expertise-areas', { value });
  }
}
