import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Route, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps<any, any> {
  component: ComponentType<any>;
}

export const ProtectedRoute = ({ component, ...rest }: ProtectedRouteProps) => (
  <Route
    render={props => {
      const WrappedComponent = withAuthenticationRequired(component, {
        onRedirecting: () => <CircularProgress />,
      });
      return <WrappedComponent {...props} />;
    }}
    {...rest}
  />
);
