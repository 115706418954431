import moment from 'moment';

import { DATE_FORMAT } from '../constants/common';
import { formatDate } from './formatDate';

export default function formatTimeAgo({
  date,
  fromDate,
  dateFormat = DATE_FORMAT,
}) {
  const diffDuration = moment.duration(
    moment(fromDate).diff(date, 'seconds'),
    'seconds',
  );

  const durationInDays = diffDuration.asDays();

  if (durationInDays > 1) return formatDate(date, dateFormat);

  return `${diffDuration.humanize()} ago`;
}
