import Base from './base';

export default class Auth extends Base {
  login(orcidCode) {
    return this.apiClient.post('auth', { code: orcidCode });
  }

  confirm(hash) {
    return this.apiClient.post(`actions/${hash}`);
  }
}
