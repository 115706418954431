import React from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTES } from '../../../../constants/routes';
import Button from '../../../Button';
import Modal from '../../Modal';
import { useAuth0 } from '@auth0/auth0-react';

const ModalLoginToAction = ({ user, isUserAuth, isShown, onAction }) => {
  const handleCancel = e => {
    onAction('cancel');
    e.stopPropagation();
  };
  const { loginWithRedirect } = useAuth0();

  if (isUserAuth && !user.hasUserCompletedOnBoarding)
    return <Redirect to={ROUTES.onboarding} />;

  return (
    <Modal
      isShown={isShown}
      onHide={handleCancel}
      footer={[
        <Button
          type="button"
          color="secondary"
          caption="Cancel"
          size="small"
          onClick={handleCancel}
        />,
        <Button
          type="button"
          caption={user.hasUserCompletedOnBoarding ? 'Log in' : 'Ok'}
          size="small"
          onClick={() => {
            localStorage.setItem('goBackPathname', window.location.pathname);
            loginWithRedirect();
          }}
        />,
      ]}
    >
      <p>Sign in to join the conversation</p>
    </Modal>
  );
};

export default ModalLoginToAction;
