import { connect } from 'react-redux';

import ArticleRecommendations from './ArticleRecommendations';
import { createLike, removeLike } from '../../../redux/articles/actions';

export default connect(
  state => {
    const { _id, likesCount = 0, isLiked = false } = state.articles.data || {};
    return {
      articleId: _id,
      likesCount,
      isLiked,
    };
  },
  { createLike, removeLike },
)(ArticleRecommendations);
