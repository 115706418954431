import { AnyAction } from 'redux';
import storage from 'redux-persist/lib/storage';
import {
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  SET_FILTERS,
  SET_PAGE,
  SET_PAGE_SIZE,
} from './actionTypes';
import { persistReducer } from 'redux-persist';

const INITIAL_STATE = {
  isLoading: false,
  filters: {},
  page: 0,
  pageSize: 10,
  rowCount: 0,
  data: null,
  error: null,
};

const myArticles = (state = INITIAL_STATE, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_ARTICLES_REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload.rows,
        rowCount: payload.count,
      };
    }

    case FETCH_ARTICLES_FAILURE: {
      return { ...state, isLoading: false, error: payload.error };
    }

    case SET_FILTERS: {
      return { ...state, filters: payload };
    }

    case SET_PAGE: {
      return { ...state, page: payload };
    }

    case SET_PAGE_SIZE: {
      return { ...state, pageSize: payload };
    }

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'myArticles',
  storage,
};

export default persistReducer(persistConfig, myArticles);
