import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';
import api from '../../api';
import history from '../../services/history';
import logger from '../../services/logger';
import * as articleActions from '../../redux/articles/actions';
import scrollTo from '../../utils/scrollTo';

export default function withPdfUpload(BaseComponent) {
  return compose(
    connect(null, {
      setArticleData: articleActions.setArticleData,
      resetArticle: articleActions.reset,
    }),
    withState('pdfBinaryData', 'setPdfBinaryData', null),
    withState('pdfFileName', 'setPdfFileName', null),
    withState('isPdfUploading', 'setIsPdfUploading', false),
    withHandlers({
      onArticleLoaded: ({ setArticleData }) => article => {
        setArticleData(article);
        setTimeout(() =>
          scrollTo(
            document.querySelector(`[data-article-container]`),
            document.querySelector(`[data-article-top-section]`),
          ),
        );
        history.push(`/personal/articles/${article.fingerprint}`);
      },
    }),
    withHandlers({
      onUploadingPdfChange: ({
        setPdfBinaryData,
        setPdfFileName,
        resetArticle,
      }) => event => {
        const file = event.target.files[0];
        const fileReader = new FileReader();

        setPdfFileName(file.name);
        resetArticle();

        fileReader.onload = function() {
          const typedarray = new Uint8Array(this.result);

          setPdfBinaryData(typedarray);
        };

        fileReader.readAsArrayBuffer(file);
      },
      uploadPdf: ({
        pdfBinaryData,
        onArticleLoaded,
        pdfFileName,
        setIsPdfUploading,
      }) => async (fingerprint, title) => {
        const blobData = new Blob([pdfBinaryData], { type: 'application/pdf' });

        setIsPdfUploading(true);

        try {
          const {
            data: { article, signedUrl },
          } = await api.articles.getPdfUploadSignedUrl(fingerprint);

          if (article) {
            onArticleLoaded(article);
            return;
          }

          const uploadingResponse = await fetch(signedUrl, {
            method: 'PUT',
            body: blobData,
          });

          if (uploadingResponse.status === 200) {
            const {
              data: uploadedArticle,
            } = await api.articles.comfirmPdfUpload(
              fingerprint,
              title,
              pdfFileName,
            );
            onArticleLoaded(uploadedArticle);
          }
        } catch (err) {
          logger(err);
        } finally {
          setIsPdfUploading(false);
        }
      },
    }),
  )(BaseComponent);
}
