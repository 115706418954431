import {
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  SET_PAGE,
  SET_PAGE_SIZE,
} from './actionTypes';
import api from '../../api';
import { AppDispatch, AppThunk } from '../type';
import { hide as hideModal, show as showModal } from '../modals/actions';
import * as MODALS from '../../components/Modal/modalNames';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';

export class MyArticlesActions {
  static fetchArticles = (
    userId: string,
    page: number,
    pageSize: number,
    filters?: { [key: string]: any },
  ) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: FETCH_ARTICLES_REQUEST });
      const res = await api.users.getUserArticles(
        userId,
        page,
        pageSize,
        filters,
      );
      dispatch({ type: FETCH_ARTICLES_SUCCESS, payload: res });
    } catch (error) {
      dispatch({
        type: FETCH_ARTICLES_FAILURE,
        payload: { error: error.code ? error : null },
      });
    }
  };

  static setPage = (page: number) => (dispatch: AppDispatch) =>
    dispatch({ type: SET_PAGE, payload: page });

  static setPageSize = (pageSize: number) => (dispatch: AppDispatch) =>
    dispatch({ type: SET_PAGE_SIZE, payload: pageSize });

  static deleteArticle = (id: string): AppThunk => (dispatch, getState) =>
    dispatch(
      showModal({
        name: MODALS.CONFIRMATION,
        title: 'Are you sure you want to delete this article?',
      }),
    )
      // @ts-ignore
      .then(async (action: AnyAction) => {
        if (action.name === 'confirm') {
          try {
            await api.articles.deleteArticle(id);
            const state = getState();
            const userId = state.auth.user._id || '';
            const { page, pageSize, filters } = state.myArticles;
            dispatch(this.fetchArticles(userId, page, pageSize, filters));
          } catch (error) {
            toast.error('Something went wrong');
            console.log(error);
          }
        }
        return action;
      })
      .finally(() => dispatch(hideModal(MODALS.CONFIRMATION)));
}
