import { useEffect } from 'react';

import { USERBACK_WIDGET_URL } from '../constants/common';
import { useScript } from './useScript';

export const useUserFeedbackScript = () => {
  useEffect(() => {
    window.Userback = window.Userback || {};
    window.Userback.access_token = process.env.REACT_APP_USERBACK_TOKEN;
  }, []);

  useScript(USERBACK_WIDGET_URL);
};
