import Base from './base';

export default class Comments extends Base {
  threads(articleId, filter) {
    return this.apiClient.get('comments/threads', {
      articleId,
      filter,
    });
  }

  getThread(threadId) {
    return this.apiClient.get(`comments/threads/${threadId}`);
  }

  createThread({ articleId, text, quotedText, position, hashtag, isPrivate }) {
    return this.apiClient.post('comments/threads', {
      articleId,
      quotedText,
      position,
      text,
      hashtag,
      isPrivate,
    });
  }

  deleteThread(threadId) {
    return this.apiClient.delete(`comments/threads/${threadId}`);
  }

  createReply({ articleId, threadId, quotedId, text }) {
    return this.apiClient.post('comments/replies', {
      articleId,
      threadId,
      quotedId,
      text,
    });
  }

  replies(threadId, filter) {
    return this.apiClient.get('comments/replies', {
      threadId,
      filter,
    });
  }

  deleteReply(replyId) {
    return this.apiClient.delete(`comments/replies/${replyId}`);
  }

  createLike(commentId) {
    return this.apiClient.post(`comments/${commentId}/like`);
  }

  removeLike(commentId) {
    return this.apiClient.delete(`comments/${commentId}/like`);
  }

  shareComment(commentId) {
    return this.apiClient.post(`comments/${commentId}/share`);
  }

  getStatistic(articleId, filter) {
    return this.apiClient.get('comments/statistic', { articleId, filter });
  }

  getFilter(articleId) {
    return this.apiClient.get('comments/filter', { articleId });
  }
}
