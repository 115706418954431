import React from 'react';
import { Box, Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    width: '100%',
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: 3,
    width: '100%',
  },
}));

export const IsLikedFilter = ({ selected, onChange }) => {
  const classes = useStyles();

  const handleClick = () => onChange();

  return (
    <Box component="ul" className={classes.root}>
      <li key="key-isLiked">
        <Chip
          color={selected ? 'secondary' : 'default'}
          label="Liked Articles"
          onClick={handleClick}
          className={classes.chip}
        />
      </li>
    </Box>
  );
};
