import React from 'react';
import classNames from 'classnames';

import TextareaAutosize from 'react-textarea-autosize';

import styles from './TextArea.module.scss';

function TextArea({
  field,
  containerClassName,
  inputClassName,
  placeholder,
  innerRef,
  isDisabled,
}) {
  return (
    <label
      className={classNames(styles['input-container'], containerClassName)}
    >
      <TextareaAutosize
        {...field}
        ref={innerRef}
        className={classNames(styles.input, inputClassName)}
        placeholder={placeholder}
        disabled={isDisabled}
      />
    </label>
  );
}

export default TextArea;
