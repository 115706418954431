import { createSelector } from 'reselect';
import { COMMENT_PREDEFINED_HASHTAGS } from '../../constants/comments';

const allCommentsSelector = state => { return {comments: state.comments.list} };
const pdfCommentsSelector = createSelector(allCommentsSelector, (selector) => {
    return selector.comments.filter(comment => comment.position !== undefined && comment.position.rects.length > 0);
});
const titleCommentsSelector = createSelector(allCommentsSelector, selector =>
  selector.comments
    .filter(comment => comment.position !== undefined && comment.position.rects.length === 0)
    .map(comment => ({
      ...comment,
      predefinedHashtag: COMMENT_PREDEFINED_HASHTAGS[comment.hashtags],
    })),
);

const statisticSelector = state => state.comments.statistic;
const mappedStatisticSelector = createSelector(statisticSelector, statistic =>
  (statistic || []).reduce(
    (mappedStatistic, { _id, ...rest }) => ({
      ...mappedStatistic,
      [_id]: rest,
    }),
    {},
  ),
);

const maxCommentsCountSelector = createSelector(statisticSelector, statistic =>
  (statistic || []).reduce(
    (maxCount, { totalCount }) =>
      totalCount > maxCount ? totalCount : maxCount,
    0,
  ),
);

export {
  mappedStatisticSelector,
  maxCommentsCountSelector,
  pdfCommentsSelector,
  titleCommentsSelector,
};
