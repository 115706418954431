const PREFIX = 'ARTICLES';

export const GET_ARTICLE_REQUEST = `${PREFIX}/GET_ARTICLE_REQUEST`;
export const GET_ARTICLE_FAILURE = `${PREFIX}/GET_ARTICLE_FAILURE`;
export const GET_ARTICLE_SUCCESS = `${PREFIX}/GET_ARTICLE_SUCCESS`;

export const SET_ARTICLE_RECCOMNEDATION_DATA = `${PREFIX}/SET_ARTICLE_RECCOMNEDATION_DATA`;

export const RESET = `${PREFIX}/RESET`;

export const CREATE_LIKE_SUCCESS = `${PREFIX}/CREATE_LIKE_SUCCESS`;
export const REMOVE_LIKE_SUCCESS = `${PREFIX}/REMOVE_LIKE_SUCCESS`;
