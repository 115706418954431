import React, { useEffect, useMemo } from 'react';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import * as commentsActions from '../../../redux/comments/actions';
import CommentCard from '../CommentCard';
import Tooltip from '../../../components/Tooltip';

import styles from './CommentThread.module.scss';

function CommentThread(props) {
  const {
    comment,
    selectedHighlights,
    setAreRepliesShown,
    loadReplies,
    deleteReply,
    createReplyLike,
    removeReplyLike,
    resetFilter,
    resetSelectedComment,
    resetReplyingComment,
  } = props;
  const { areRepliesShown, replies = [] } = comment;

  const isSelected = Boolean(
    selectedHighlights.find(highlight => highlight.id === comment._id),
  );

  const formattedReplies = useMemo(() => {
    return replies.map(reply => ({
      ...reply,
      quotedComment: replies.find(_reply => reply.quotedId === _reply._id),
    }));
  }, [comment]);

  const handleOpenReplies = () => {
    loadReplies();
  };

  const handleCloseReplies = () => {
    setAreRepliesShown(comment._id, false);
  };

  const handleCreateReplyLike = replyId =>
    createReplyLike(comment._id, replyId);

  const handleRemoveReplyLike = replyId =>
    removeReplyLike(comment._id, replyId);

  const handleDocumentClick = event => {
    const isClickedOnComment =
      event.target.hasAttribute('data-comment-id') ||
      event.target.closest('[data-comment-id]');

    if (!isClickedOnComment) {
      resetSelectedComment();
      resetReplyingComment();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => document.removeEventListener('click', handleDocumentClick);
  });

  return (
    <CommentCard
      comment={comment}
      isSelected={isSelected}
      areRepliesShown={areRepliesShown}
      onRepliesOpen={handleOpenReplies}
      onRepliesClose={handleCloseReplies}
    >
      {(areRepliesShown || comment.withReplies) && (
        <>
          <div className={styles.replies}>
            {formattedReplies.map(comment => (
              <CommentCard
                id={comment._id}
                key={comment._id}
                comment={comment}
                isReply
                quotedComment={comment.quotedComment}
                onReplyCreateLike={handleCreateReplyLike}
                onReplyRemoveLike={handleRemoveReplyLike}
                onReplyDelete={deleteReply}
              />
            ))}
          </div>
          {comment.withReplies && formattedReplies.length > 0 && (
            <Tooltip
              placement="bottom"
              text="Will reset all filters"
              triggers={['hover', 'focus']}
            >
              <button
                type="button"
                className={styles['show-all-btn']}
                onClick={resetFilter}
              >
                Show all
              </button>
            </Tooltip>
          )}
        </>
      )}
    </CommentCard>
  );
}

export default compose(
  connect(
    state => ({ selectedHighlights: state.comments.selectedHighlights }),
    dispatch => ({
      setAreRepliesShown: (threadId, areRepliesShown) =>
        dispatch(commentsActions.setAreRepliesShown(threadId, areRepliesShown)),
      getReplies: threadId => dispatch(commentsActions.getReplies(threadId)),
      resetFilter: () => dispatch(commentsActions.resetFilter()),
      createReplyLike: (threadId, replyId) =>
        dispatch(commentsActions.createReplyLike(threadId, replyId)),
      removeReplyLike: (threadId, replyId) =>
        dispatch(commentsActions.removeReplyLike(threadId, replyId)),
      deleteReply: replyId => dispatch(commentsActions.deleteReply(replyId)),
      resetSelectedComment: () =>
        dispatch(commentsActions.resetSelectedHighlights()),
      resetReplyingComment: () => {
        dispatch(commentsActions.setReplyingComment(null));
        dispatch(commentsActions.setAreRepliesShown(null, false));
      },
    }),
  ),
  withHandlers({
    loadReplies: props => () => {
      const { comment, setAreRepliesShown } = props;
      setAreRepliesShown(comment._id, true);
      props.getReplies(comment._id);
    },
  }),
)(CommentThread);
