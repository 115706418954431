import React from 'react';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import CommentIcon from '@material-ui/icons/Comment';
import ForumIcon from '@material-ui/icons/Forum';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { formatDate } from '../../utils/formatDate';

const getDetails = activity => {
  switch (activity.type) {
    case 'USER_COMMENT_ON_ARTICLE':
      return [
        <CommentIcon />,
        `${activity.metadata.commentUserName} added a comment to your article`,
        ` - ${activity.metadata.articleTitle}`,
        `/articles/${activity.metadata.articleDoi}?commentId=${activity.pdfCommentId}`,
      ];
    case 'USER_REPLY_ON_COMMENT':
      return [
        <ForumIcon />,
        `${activity.metadata.commentUserName} replied to your comment`,
        ` - ${activity.metadata.articleTitle}`,
        `/articles/${activity.metadata.articleDoi}?commentId=${activity.metadata.threadId}&replyId=${activity.pdfCommentId}`,
      ];
    case 'USER_LIKE_ON_ARTICLE':
      return [
        <ThumbUpIcon />,
        `${activity.metadata.likeUserName} liked your Article`,
        ` - ${activity.metadata.articleTitle}`,
        `/articles/${activity.metadata.articleDoi}`,
      ];
    case 'GLOBAL_NEW_ARTICLE_UPLOADED':
      return [
        <DescriptionIcon />,
        `${activity.metadata.articleUserName} uploaded a new article`,
        ` - ${activity.metadata.articleTitle}`,
        `/articles/${activity.metadata.articleDoi}`,
      ];
    default:
      return [];
  }
};

const ActivityCard = ({ activity, divider = false }) => {
  const [icon, primary, secondary, link] = getDetails(activity);
  return (
    <React.Fragment>
      <ListItem component={Link} to={link} alignItems="flex-start">
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary">
                {formatDate(activity.createdAt)}
              </Typography>
              {secondary}
            </React.Fragment>
          }
        />
      </ListItem>
      {divider ? <Divider variant="inset" component="li" /> : null}
    </React.Fragment>
  );
};

export default ActivityCard;
