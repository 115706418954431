import * as actionTypes from './actionTypes';

export function setBlocksSize(blocks) {
  return {
    type: actionTypes.SET_BLOCK_SIZE,
    payload: { blocks },
  };
}

export function setAllowSyncScroll(isEnabled) {
  return {
    type: actionTypes.SET_ALLOW_SYNC_SCROLL,
    payload: { isEnabled },
  };
}

export function setLimitedSectionScrolling(sectionNumber) {
  return {
    type: actionTypes.SET_LIMITED_SECTION_SCROLLING,
    payload: { sectionNumber },
  };
}
