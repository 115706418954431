import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  error: null,
};

const persistConfig = {
  key: 'articles',
  storage,
  whitelist: ['data.versions'],
};

function articles(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.GET_ARTICLE_REQUEST:
      return { ...INITIAL_STATE, isLoading: true };

    case actionTypes.GET_ARTICLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload.data,
      };
    }
    case actionTypes.GET_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    case actionTypes.CREATE_LIKE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          likesCount: state.data.likesCount ? state.data.likesCount + 1 : 1,
          isLiked: true,
        },
      };
    }

    case actionTypes.REMOVE_LIKE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          likesCount: state.data.likesCount - 1,
          isLiked: false,
        },
      };
    }

    case actionTypes.SET_ARTICLE_RECCOMNEDATION_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          likesCount: payload.likesCount,
          isLiked: payload.isLiked,
        },
      };
    }

    case actionTypes.RESET:
      return { ...INITIAL_STATE, isLoading: state.isLoading, error: null };

    default:
      return state;
  }
}

export default persistReducer(persistConfig, articles);
