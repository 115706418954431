import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PagePreloader from '../../components/PagePreloader';
import { ROUTES } from '../../constants/routes';
import { Redirect, useLocation } from 'react-router-dom';
import { setLoginSuccess } from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { toast } from 'react-toastify';

// TODO:
//  - token should be requested from auth0 on api calls, but legacy codebase doesn't support this feature
//  - instead of mapping auth0 user object manually, it should be queried from server
export const AuthCallback = () => {
  const { isLoading, user: auth0User, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [token, setToken] = useState();
  const auth = useSelector(state => state.auth);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const error = urlSearchParams.get('error');

  useEffect(() => {
    const fn = async () => {
      try {
        const res = await getAccessTokenSilently();
        setToken(res);
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    };
    if (!isLoading && !error) {
      fn();
    }
  }, [getAccessTokenSilently, setToken, isLoading, error]);

  useEffect(() => {
    (async () => {
      if (isLoading || !auth0User || !token || error) {
        return;
      }

      const mappedUser = {
        ...auth0User,
        orcid: null,
        role: null,
        affiliations: [],
        expertiseAreas: [],
        isAgreementAccepted: true,
      };

      try {
        api.client.setToken(token);
        const res = await api.users.upsert(mappedUser);
        dispatch(setLoginSuccess(token, res.data));
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    })();
  }, [isLoading, auth0User, token]);

  const getRedirectPathname = () => {
    if (!auth.user.hasUserCompletedOnBoarding) return ROUTES.onboarding;

    const goBackPathname = localStorage.getItem('goBackPathname');
    localStorage.removeItem('goBackPathname');

    if (goBackPathname) return goBackPathname;

    return ROUTES.home;
  };

  if (!isLoading && error) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.error,
          search: `?error=${error}`,
        }}
      />
    );
  }

  if (isLoading || !auth0User || !auth.isAuth || !token)
    return <PagePreloader />;

  return <Redirect to={getRedirectPathname()} />;
};
