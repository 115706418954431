import React, { useEffect, useState } from 'react';
import { useQuery } from './useQuery';
import api from '../../api';
import styles from '../Home/Home.module.scss';
import CircularProgress from '../../components/CircularProgress';
import { ROUTES } from '../../constants/routes';
import { Redirect } from 'react-router-dom';

const QRCallback = () => {
  const query = useQuery();
  const articleId = query.get('id');

  const [redirectTo, setRedirectTo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDoi = async id => {
      try {
        setIsLoading(true);
        return await api.articles.getDoiById(id);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    (async () => {
      if (articleId) {
        const res = await fetchDoi(articleId);
        setRedirectTo(res ? ROUTES.articles.replace(/:doi/, res) : ROUTES.home);
      }
    })();
  }, [articleId]);

  if (!articleId) {
    return <Redirect to={ROUTES.home} />;
  }

  if (isLoading) {
    return (
      <div className={styles['preloader-container']} key="loader">
        <CircularProgress />
      </div>
    );
  }

  if (!isLoading && redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return null;
};

export default QRCallback;
