import React from 'react';

import PercentageLine from '../../../components/PercentageLine';
import useCommentsHashtagsSegments from '../../../hooks/useCommentsHashtagsSegments';

function PagePercentageLine(props) {
  const { pageStatistic, maxCommentsCount } = props;

  const { totalCount, commentCounts } = pageStatistic || {};

  if (!parseInt(totalCount)) return null;

  const segments = useCommentsHashtagsSegments(commentCounts);

  const width = `${(totalCount / maxCommentsCount) * 100}%`;

  return (
    <PercentageLine segments={segments} totalCount={totalCount} width={width} />
  );
}

export default PagePercentageLine;
