import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshUserData } from '../redux/auth/actions';
import { isUserAuthSelector } from '../redux/auth/selectors';
import history from '../services/history';
import { SystemSettingsActions } from '../redux/systemSettings/actions';

const isAuthOnlyMode = process.env.REACT_APP_AUTH_ONLY_MODE === 'true';

const InitialDataLoader = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const isUserAuth = useSelector(isUserAuthSelector);

  useEffect(() => {
    history.listen(() => {
      window.sessionStorage.clear();
    });
  }, []);

  useEffect(() => {
    if (!dispatch) {
      return;
    }

    if (isAuthOnlyMode) {
      if (isUserAuth) {
        dispatch(SystemSettingsActions.fetchSystemSettings());
      }
    } else {
      dispatch(SystemSettingsActions.fetchSystemSettings());
    }
  }, [dispatch, isAuthOnlyMode, isUserAuth]);

  useEffect(() => {
    if (isUserAuth) dispatch(refreshUserData());
  }, [isUserAuth]);

  return children;
};

export default InitialDataLoader;
