const ROUTES = {
  signIn: '/sign-in',
  auth: '/auth',
  maintenance: '/maintenance',
  error: '/error',
  home: '/',
  articles: '/articles/:doi',
  qr: '/qr',
  createArticle: '/create-article',
  editArticle: '/edit-article',
  onboarding: '/onboarding',
  settings: '/settings',
  myArticles: '/my-articles',
  myActivities: '/my-activities',
};

const ROUTES_WITHOUT_HEADER = [ROUTES.maintenance, ROUTES.error, ROUTES.qr];

export { ROUTES, ROUTES_WITHOUT_HEADER };

// const ROUTES = {
//   home: '/',
//   signIn: '/sign-in',
//   createArticle: '/create-article',
//   editArticle: '/edit-article',
//   onboarding: '/onboarding',
//   settings: '/settings',
//   auth: '/auth',
//   search: '/doi',
//   articles: '/articles/:doi',
//   personalArticles: '/personal/articles/:fingerprint?',
//   myArticles: '/my-articles',
//   covidArticles: '/covid-19',
//   activity: '/activity',
//   userAdministration: '/user-admin',
//   maintenance: '/maintenance',
//   systemAdministration: '/system-administration',
//   qr: '/qr',
//   error: '/error',
// };
//
// const ROUTES_WITHOUT_HEADER = [ROUTES.maintenance];
//
// export { ROUTES, ROUTES_WITHOUT_HEADER };
