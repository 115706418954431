import React from 'react';
import classNames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';

import styles from './Link.module.scss';

export default function Link({ className, ...props }) {
  return (
    <ReactRouterLink
      {...props}
      className={classNames(styles.link, className)}
    />
  );
}
