import api from '../../api';
import history from '../../services/history';
import logger from '../../services/logger';
import { passOnlySignedIn } from '../auth/actions';
import * as actionTypes from './actionTypes';

export function searchArticle(query) {
  return dispatch => {
    dispatch({ type: actionTypes.GET_ARTICLE_REQUEST });

    const [, , , doi] = query.match(
      '(https://www.([a-z]+).org/content/)?([0-9.]+/[0-9.]+)v?([0-9]+)?',
    );

    history.push('/');
    history.push(`articles/${doi}`);
  };
}

export function getArticleByDoi({ doi }) {
  return async (dispatch, getState) => {
    const { data } = getState().articles;

    dispatch({ type: actionTypes.GET_ARTICLE_REQUEST });

    try {
      const { data: articleData } = await api.articles.itemPublic({
        doi
      });
      if (!data || doi !== data.doi) {
        const { data: versions } = await api.articles.getVersions(doi);
        articleData.versions = versions;
      } else {
        articleData.versions = data.versions;
      }
      dispatch({
        type: actionTypes.GET_ARTICLE_SUCCESS,
        payload: {
          data: articleData,
        },
      });
    } catch (error) {
      logger(error);
      dispatch({
        type: actionTypes.GET_ARTICLE_FAILURE,
        payload: { error: error.code ? error : null },
      });
    }
  };
}

export function getArticleByFingerprint(fingerprint) {
  return async dispatch => {
    try {
      dispatch({
        type: actionTypes.GET_ARTICLE_REQUEST,
      });

      const { data } = await api.articles.itemPrivate(fingerprint);

      dispatch({
        type: actionTypes.GET_ARTICLE_SUCCESS,
        payload: {
          data,
        },
      });
    } catch (error) {
      logger(error);
      dispatch({
        type: actionTypes.GET_ARTICLE_FAILURE,
        payload: { error: error.code ? error : null },
      });
    }
  };
}

export function setArticleData(data) {
  return {
    type: actionTypes.GET_ARTICLE_SUCCESS,
    payload: { data },
  };
}

export function setArticleReccomnedationData({ likesCount, isLiked }) {
  return {
    type: actionTypes.SET_ARTICLE_RECCOMNEDATION_DATA,
    payload: { likesCount, isLiked },
  };
}

export function reset() {
  return { type: actionTypes.RESET };
}

export function createLike(articleId) {
  return dispatch => {
    dispatch(passOnlySignedIn()).then(() => {
      api.articles.createLike(articleId).then(() => {
        dispatch({
          type: actionTypes.CREATE_LIKE_SUCCESS,
          payload: { articleId },
        });
      });
    });
  };
}

export function removeLike(articleId) {
  return dispatch => {
    api.articles.removeLike(articleId).then(() => {
      dispatch({
        type: actionTypes.REMOVE_LIKE_SUCCESS,
        payload: { articleId },
      });
    });
  };
}
