import { AppDispatch } from '../type';
import {
  FETCH_SYSTEM_SETTINGS_FAILURE,
  FETCH_SYSTEM_SETTINGS_REQUEST,
  FETCH_SYSTEM_SETTINGS_SUCCESS,
} from './actionTypes';
import api from '../../api';

export class SystemSettingsActions {
  static fetchSystemSettings = () => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: FETCH_SYSTEM_SETTINGS_REQUEST });
      const res = await api.systemSettings.getPublicSystemSettings();
      dispatch({ type: FETCH_SYSTEM_SETTINGS_SUCCESS, payload: res });
    } catch (error) {
      dispatch({
        type: FETCH_SYSTEM_SETTINGS_FAILURE,
        payload: { error: error.code ? error : null },
      });
    }
  };
}
