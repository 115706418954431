import React, { ComponentProps, ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MainLayout from './components/MainLayout';
import HomePage from './pages/Home';
import ArticlePdf from './pages/ArticlePdf';
import UserSettings from './pages/UserSettings';
import Maintenance from './pages/Maintenance';
import { useTracking } from './hooks';
import { AuthCallback } from './pages/AuthCallback/AuthCallback';
import QRCallback from './pages/QRCallback';
import Article from './pages/Article/Article';
import MyArticles from './pages/MyArticles/MyArticles';
import { useAuth0 } from '@auth0/auth0-react';
import { isUserAuthSelector } from './redux/auth/selectors';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Error } from './pages/Error/Error';
import { ROUTES } from './constants/routes';
import { ProtectedRoute } from './components/ProtectedRoute';
import MyActivities from './pages/MyActivities/MyActivities';
import SignIn from './pages/SignIn';

type AppRoute = {
  path: string;
  component: ComponentType;
  exact?: boolean;
  strict?: boolean;
};

type AppRoutes = {
  public: AppRoute[];
  private: AppRoute[];
};

const routes = {
  [ROUTES.signIn]: {
    path: ROUTES.signIn,
    component: SignIn,
    exact: true,
  },
  [ROUTES.auth]: {
    path: ROUTES.auth,
    component: AuthCallback,
    exact: true,
  },
  [ROUTES.maintenance]: {
    path: ROUTES.maintenance,
    component: Maintenance,
    exact: true,
  },
  [ROUTES.error]: {
    path: ROUTES.error,
    component: Error,
    exact: true,
  },
  [ROUTES.home]: {
    path: ROUTES.home,
    component: HomePage,
    exact: true,
  },
  [ROUTES.articles]: {
    path: ROUTES.articles,
    component: ArticlePdf,
    strict: true,
  },
  [ROUTES.qr]: {
    path: ROUTES.qr,
    component: QRCallback,
    exact: true,
  },
  [ROUTES.createArticle]: {
    path: ROUTES.createArticle,
    component: Article,
    exact: true,
  },
  [ROUTES.editArticle]: {
    path: ROUTES.editArticle,
    component: Article,
    exact: true,
  },
  [ROUTES.onboarding]: {
    path: ROUTES.onboarding,
    component: UserSettings,
    exact: true,
  },
  [ROUTES.settings]: {
    path: ROUTES.settings,
    component: UserSettings,
    exact: true,
  },
  [ROUTES.myArticles]: {
    path: ROUTES.myArticles,
    component: MyArticles,
    exact: true,
  },
  [ROUTES.myActivities]: {
    path: ROUTES.myActivities,
    component: MyActivities,
    exact: true,
  },
};

const defaultRoutes: AppRoutes = {
  public: [
    routes[ROUTES.signIn],
    routes[ROUTES.home],
    routes[ROUTES.auth],
    routes[ROUTES.articles],
    routes[ROUTES.maintenance],
    routes[ROUTES.qr],
    routes[ROUTES.error],
  ],
  private: [
    routes[ROUTES.createArticle],
    routes[ROUTES.editArticle],
    routes[ROUTES.onboarding],
    routes[ROUTES.settings],
    routes[ROUTES.myArticles],
    routes[ROUTES.myActivities],
  ],
};

const authOnlyModeRoutes: AppRoutes = {
  public: [
    routes[ROUTES.signIn],
    routes[ROUTES.auth],
    routes[ROUTES.maintenance],
    routes[ROUTES.error],
  ],
  private: [
    routes[ROUTES.home],
    routes[ROUTES.articles],
    routes[ROUTES.qr],
    routes[ROUTES.createArticle],
    routes[ROUTES.editArticle],
    routes[ROUTES.onboarding],
    routes[ROUTES.settings],
    routes[ROUTES.myArticles],
    routes[ROUTES.myActivities],
  ],
};

const isAuthOnlyMode = process.env.REACT_APP_AUTH_ONLY_MODE === 'true';
const appRoutes: AppRoutes = isAuthOnlyMode
  ? authOnlyModeRoutes
  : defaultRoutes;

const renderComponent = (
  Component: ComponentType,
  props: ComponentProps<any>,
) => <Component {...props} />;

const Routes = () => {
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  const { isAuthenticated, isLoading } = useAuth0();
  const isUserAuth = useSelector(isUserAuthSelector);

  if (isLoading && !isAuthenticated && !isUserAuth) {
    return <CircularProgress />;
  }

  return (
    <MainLayout>
      <Helmet>
        <title>DropCite</title>
      </Helmet>
      <Switch>
        {appRoutes.public.map(r => (
          <Route
            key={r.path}
            path={r.path}
            render={props => renderComponent(r.component, props)}
            exact={r.exact}
            strict={r.strict}
          />
        ))}
        {appRoutes.private.map(r => (
          <ProtectedRoute
            key={r.path}
            path={r.path}
            component={r.component}
            exact={r.exact}
            strict={r.strict}
          />
        ))}
      </Switch>
    </MainLayout>
  );

  // if (
  //   process.env.REACT_APP_AUTH_ONLY_MODE === 'true' &&
  //   !isLoading &&
  //   !isAuthenticated &&
  //   !isUserAuth
  // ) {
  //   loginWithRedirect();
  //   return null;
  // }

  // return (
  //   <MainLayout>
  //     <Helmet>
  //       <title>DropCite</title>
  //     </Helmet>
  //     <Switch>
  //       <Route path={ROUTES.auth} component={AuthCallback} exact />
  //       <Route path={ROUTES.signIn} component={SignIn} exact />
  //       <Route path={ROUTES.maintenance} component={Maintenance} exact />
  //       <Route path={ROUTES.error} component={Error} exact />
  //       <PrivateRoute path={ROUTES.onboarding} exact>
  //         <UserSettings />
  //       </PrivateRoute>
  //       <PrivateRoute path={ROUTES.settings} exact>
  //         <UserSettings />
  //       </PrivateRoute>
  //       <Route path={ROUTES.qr} component={QRCallback} exact />
  //       {/*<Route*/}
  //       {/*  path={ROUTES.onboarding}*/}
  //       {/*  component={withRouteProtection(UserSettings)}*/}
  //       {/*  exact*/}
  //       {/*/>*/}
  //       {/*<Route*/}
  //       {/*  path={ROUTES.settings}*/}
  //       {/*  component={withRouteProtection(UserSettings)}*/}
  //       {/*  exact*/}
  //       {/*/>*/}
  //       <Route path={ROUTES.home} component={HomePage} exact />
  //       <Route path={ROUTES.createArticle} component={Article} exact />
  //       <Route path={ROUTES.editArticle} component={Article} exact />
  //       <Route path={ROUTES.covidArticles} component={CovidArticles} exact />
  //       <Route path={ROUTES.search} component={SearchPage} exact />
  //       <Route path={ROUTES.articles} component={ArticlePdf} strict />
  //       <PrivateRoute path={ROUTES.myArticles} exact>
  //         <MyArticles />
  //       </PrivateRoute>
  //       <Route
  //         path={ROUTES.articles}
  //         render={props => <ArticlePdf {...props} />}
  //         strict
  //       />
  //       <Route
  //         path={ROUTES.personalArticles}
  //         render={props => <ArticlePdf {...props} isPdfPersonal />}
  //         strict
  //       />
  //       <Route
  //         path={ROUTES.activity}
  //         component={withRouteProtection(ActivityPage)}
  //         exact
  //       />
  //       <Route
  //         path={ROUTES.userAdministration}
  //         component={withRouteProtection(UserAdministration)}
  //         exact
  //       />
  //       <Route
  //         path={ROUTES.systemAdministration}
  //         component={withRouteProtection(SystemAdministration)}
  //         exact
  //       />
  //     </Switch>
  //   </MainLayout>
  // );
};

export default Routes;
