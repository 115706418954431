import React from 'react';
import { Link } from 'react-router-dom';
import { SpeakerNotesOffOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import ArticleActivity from './ArticleActivity';
import { VisibilityOffOutlined } from '@material-ui/icons';

import styles from './ArticleCard.module.scss';

const TITLE_MAX_LENGTH = 178;

function ArticleCard(props) {
  const { article, withActivity, onClick } = props;

  const title = article ? article.title : '';
  const leadAuthor = article ? article.authors : '';
  const contact = article ? article.authorsCorresponding : '';

  const truncatedTitle =
    title.length > TITLE_MAX_LENGTH
      ? `${title.substring(0, TITLE_MAX_LENGTH)}...`
      : title;

  return (
    <div className={styles['article-preview']} onClick={onClick}>
      <Link
        className={styles['article-preview__link-container']}
        // to={{
        //   pathname:
        //     article.access === ARTICLE_ACCESS_TYPES.open
        //       ? `/articles/${article.doi}v${article.version}`
        //       : `/personal/articles/${article.fingerprint}`,
        // }}
        to={{
          pathname: `/articles/${article.doi}`,
        }}
      />
      {withActivity && <ArticleActivity article={article} />}
      <span className={styles['article-preview__title']}>{truncatedTitle}</span>

      <p className={styles['article-preview__info']}>
        <span>
          <b>Lead author:</b> {leadAuthor}
          {'  '}
          <b>Contact:</b> {contact}
        </span>
      </p>

      <div className={styles['article-preview__container']}>
        {article.isPrivate && (
          <Tooltip title={'Private article'}>
            <span className={styles['article-preview__visibility']}>
              <VisibilityOffOutlined fontSize={'small'} />
            </span>
          </Tooltip>
        )}
        {article.isPrivateCommentsOnly && (
          <Tooltip title={'Private comments only'}>
            <span className={styles['article-preview__commentStatus']}>
              <SpeakerNotesOffOutlined fontSize={'small'} />
            </span>
          </Tooltip>
        )}
        {article.commentsCount != null && (
          <Tooltip title={'Number of comments'}>
            <span className={styles['article-preview__total-comments']}>
              {article.commentsCount > 999 ? '999+' : article.commentsCount}
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default ArticleCard;
