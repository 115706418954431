import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ResizeObserver } from 'resize-observer';
import { debounce } from 'lodash';

import styles from './TopInfo.module.scss';
// import { formatDate } from '../../../utils/formatDate';
// import { ARTICLE_SERVER_NAMES } from '../../../constants/articles';
import Title from './Title';
import { toast } from 'react-toastify';

const TopInfo = ({
  title,
  titleComments,
  // date,
  isCommentFormOpen,
  isTitleSelected,
  // serverName,
  onCommentTitle,
  onTitleClick,
  onOutsideClick,
  setBlocksSize,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const handler = debounce(() => {
    try {
      const pagesCount = document.querySelectorAll('[data-page-number]').length;

      setBlocksSize({
        articleTitleSize: document.querySelector('[data-article-top-section]')
          .clientHeight,
        articlePageSize: document.querySelector('[data-page-number]')
          .clientHeight,
        commentsFilterSize: document.querySelector('[data-comments-filter]')
          .clientHeight,
        pagesCount,
      });
    } catch {
      // toast.error(
      //     <>
      //         Something went wrong. <br /> Please try refreshing the page!
      //     </>
      // );
    }
  }, 500);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handler);
    resizeObserver.observe(document.body);
  }, []);

  return (
    <section data-article-top-section className={styles['info-container']}>
      <div className={styles['info-body']}>
        {/*<p className={styles.date}>*/}
        {/*  {serverName && (*/}
        {/*    <span className={styles['meta-label']}>*/}
        {/*      {ARTICLE_SERVER_NAMES[serverName]}:*/}
        {/*    </span>*/}
        {/*  )}*/}
        {/*  {formatDate(date)}*/}
        {/*</p>*/}
        <div className={styles['title-container']}>
          {!isTitleSelected &&
            titleComments.map(comment => (
              <div
                key={comment._id}
                className={classNames(styles.highlight, {
                  [styles[
                    `highlight--hashtag-${comment.predefinedHashtag &&
                      comment.predefinedHashtag.value}`
                  ]]: comment.predefinedHashtag != null,
                })}
              ></div>
            ))}
          <Title
            text={title}
            isCommentFormOpen={isCommentFormOpen}
            isTitleSelected={isTitleSelected}
            isMouseOver={isMouseOver}
            setIsMouseOver={setIsMouseOver}
            onCommentTitle={onCommentTitle}
            onTitleClick={onTitleClick}
            onOutsideClick={onOutsideClick}
          />
        </div>
      </div>
    </section>
  );
};

export default TopInfo;
