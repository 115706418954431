import UserSettings from './UserSettings';

export default UserSettings;

// import {
//   branch,
//   compose,
//   renderComponent,
//   renderNothing,
//   withHandlers,
//   withPropsOnChange,
//   withReducer,
//   withState,
//   withProps,
// } from 'recompose';
// import { connect } from 'react-redux';
//
// import UserSettings from './UserSettings';
// import PagePreloader from '../../components/PagePreloader';
// import api from '../../api';
// import history from '../../services/history';
// import mapInterestAreas from '../../utils/mapInterestAreas';
// import { ROUTES } from '../../constants/routes';
// import { refreshUserData } from '../../redux/auth/actions';
//
// const INITIAL_STATE = {
//   isLoading: false,
//   isSaving: false,
//   user: null,
//   expertiseAreas: null,
//   interestAreas: null,
//   generatedAlias: null,
//   error: null,
// };
//
// const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
// const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
// const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
//
// const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
// const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
// const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
//
// const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
// const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
// const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
//
// const FETCH_AREAS_REQUEST = 'FETCH_AREAS_REQUEST';
// const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
// const FETCH_AREAS_FAILURE = 'FETCH_AREAS_FAILURE';
//
// const CREATE_EXPERTISE_AREA_REQUEST = 'CREATE_EXPERTISE_AREA_REQUEST';
// const CREATE_EXPERTISE_AREA_SUCCESS = 'CREATE_EXPERTISE_AREA_SUCCESS';
// const CREATE_EXPERTISE_AREA_FAILURE = 'CREATE_EXPERTISE_AREA_FAILURE';
//
// const SET_GENERATED_ALIAS = 'SET_GENERATED_ALIAS';
//
// export default compose(
//   connect(null, {
//     refreshUserData,
//   }),
//   withProps(({ location }) => {
//     return {
//       isOnboarding: location.pathname === ROUTES.onboarding,
//     };
//   }),
//   withState('hasUserAlias', 'setHasUserAlias', false),
//   withReducer(
//     'settings',
//     'dispatch',
//     (state, action) => {
//       const { payload } = action;
//
//       switch (action.type) {
//         case FETCH_SETTINGS_REQUEST:
//         case CONFIRM_EMAIL_REQUEST:
//         case FETCH_AREAS_REQUEST:
//         case CREATE_EXPERTISE_AREA_REQUEST:
//           return {
//             ...state,
//             isLoading: true,
//           };
//         case FETCH_SETTINGS_SUCCESS:
//           return {
//             ...state,
//             isLoading: false,
//             user: {
//               ...payload.settings,
//               expertiseAreas: payload.settings.expertiseAreas.map(
//                 area => area._id,
//               ),
//               bioRxivAreas: mapInterestAreas(
//                 payload.settings.interestAreas,
//                 'bioRxiv',
//               ),
//               medRxivAreas: mapInterestAreas(
//                 payload.settings.interestAreas,
//                 'medRxiv',
//               ),
//             },
//           };
//         case CONFIRM_EMAIL_SUCCESS:
//           return {
//             ...state,
//             isLoading: false,
//             user: { ...state.user, isEmailVerified: true },
//           };
//         case FETCH_AREAS_SUCCESS:
//           return {
//             ...state,
//             isLoading: false,
//             expertiseAreas: payload.expertiseAreas,
//             interestAreas: payload.interestAreas,
//           };
//         case FETCH_SETTINGS_FAILURE:
//         case CONFIRM_EMAIL_FAILURE:
//         case FETCH_AREAS_FAILURE:
//         case CREATE_EXPERTISE_AREA_FAILURE:
//           return {
//             ...state,
//             isLoading: false,
//             error: payload.error,
//           };
//         case UPDATE_SETTINGS_REQUEST:
//           return {
//             ...state,
//             isSaving: true,
//           };
//         case UPDATE_SETTINGS_SUCCESS:
//           return {
//             ...state,
//             isSaving: false,
//           };
//         case UPDATE_SETTINGS_FAILURE:
//           return {
//             ...state,
//             isSaving: false,
//             error: payload.error,
//           };
//         case CREATE_EXPERTISE_AREA_SUCCESS:
//           return {
//             ...state,
//             isLoading: false,
//             expertiseAreas: [...state.expertiseAreas, payload.area],
//           };
//         case SET_GENERATED_ALIAS:
//           return {
//             ...state,
//             generatedAlias: payload.alias,
//           };
//         default:
//           return state;
//       }
//     },
//     INITIAL_STATE,
//   ),
//   withHandlers({
//     onGenerateAlias: ({ dispatch }) => () => {
//       return api.users.generateAlias().then(({ data }) => {
//         const alias = {
//           noun: data.noun,
//           adjective: data.adjective,
//         };
//
//         dispatch({ type: SET_GENERATED_ALIAS, payload: { alias } });
//       });
//     },
//   }),
//   withHandlers({
//     onConfirmEmail: props => hash => {
//       props.dispatch({ type: CONFIRM_EMAIL_REQUEST });
//
//       api.auth
//         .confirm(hash)
//         .then(() => {
//           props.dispatch({
//             type: CONFIRM_EMAIL_SUCCESS,
//           });
//         })
//         .catch(error => {
//           props.dispatch({
//             type: CONFIRM_EMAIL_FAILURE,
//             payload: { error },
//           });
//         });
//     },
//     onLoadSettings: props => () => {
//       props.dispatch({
//         type: FETCH_SETTINGS_REQUEST,
//       });
//
//       api.users
//         .getSettings()
//         .then(res => {
//           props.dispatch({
//             type: FETCH_SETTINGS_SUCCESS,
//             payload: { settings: res.data },
//           });
//
//           const hasUserAlias = !!res.data.alias;
//
//           props.setHasUserAlias(hasUserAlias);
//
//           if (!hasUserAlias) props.onGenerateAlias();
//         })
//         .catch(error => {
//           props.dispatch({
//             type: FETCH_SETTINGS_FAILURE,
//             payload: { error },
//           });
//         });
//     },
//     onUpdateSettings: props => formValues => {
//       props.dispatch({
//         type: UPDATE_SETTINGS_REQUEST,
//       });
//
//       const newSettings = {
//         ...formValues,
//       };
//
//       if (props.settings.generatedAlias)
//         newSettings.alias = {
//           nounId: props.settings.generatedAlias.noun._id,
//           adjectiveId: props.settings.generatedAlias.adjective._id,
//         };
//
//       api.users
//         .updateSettings(newSettings)
//         .then(() => props.refreshUserData())
//         .then(() => {
//           props.dispatch({
//             type: UPDATE_SETTINGS_SUCCESS,
//           });
//
//           if (props.isOnboarding) {
//             const goBackPathname = localStorage.getItem('goBackPathname');
//             localStorage.removeItem('goBackPathname');
//
//             if (goBackPathname && goBackPathname !== ROUTES.onboarding) {
//               history.push(goBackPathname);
//               return;
//             }
//           }
//
//           history.push(ROUTES.home);
//         })
//         .catch(error =>
//           props.dispatch({
//             type: UPDATE_SETTINGS_FAILURE,
//             payload: { error },
//           }),
//         );
//     },
//     onLoadAreas: props => () => {
//       props.dispatch({
//         type: FETCH_AREAS_REQUEST,
//       });
//
//       Promise.all([api.expertiseAreas.list(), api.interestAreas.list()])
//         .then(([{ data: expertiseAreasData }, { data: interestAreaData }]) => {
//           props.dispatch({
//             type: FETCH_AREAS_SUCCESS,
//             payload: {
//               expertiseAreas: expertiseAreasData.list,
//               interestAreas: interestAreaData.list,
//             },
//           });
//         })
//         .catch(error =>
//           props.dispatch({
//             type: FETCH_AREAS_FAILURE,
//             payload: { error },
//           }),
//         );
//     },
//     onCreateExpertiseArea: props => value => {
//       props.dispatch({
//         type: CREATE_EXPERTISE_AREA_REQUEST,
//       });
//
//       return api.expertiseAreas
//         .create({ value })
//         .then(({ data: area }) => {
//           props.dispatch({
//             type: CREATE_EXPERTISE_AREA_SUCCESS,
//             payload: { area },
//           });
//           return area;
//         })
//         .catch(error =>
//           props.dispatch({
//             type: CREATE_EXPERTISE_AREA_FAILURE,
//             payload: { error },
//           }),
//         );
//     },
//   }),
//   withPropsOnChange([], ({ onLoadSettings, onLoadAreas }) => {
//     onLoadSettings();
//     onLoadAreas();
//   }),
//   withPropsOnChange(['settings'], ({ settings, isOnboarding }) => {
//     if (
//       isOnboarding &&
//       settings.user &&
//       settings.user.hasUserCompletedOnBoarding
//     ) {
//       history.push(ROUTES.home);
//     }
//   }),
//   branch(({ settings: { user, expertiseAreas, interestAreas, isLoading } }) => {
//     return !isLoading && (!user || !expertiseAreas || !interestAreas);
//   }, renderNothing),
//   branch(
//     ({ settings: { user, expertiseAreas, interestAreas, isLoading } }) =>
//       (!user || !expertiseAreas || !interestAreas) && isLoading,
//     renderComponent(PagePreloader),
//   ),
// )(UserSettings);
