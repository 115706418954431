import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  paper: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
}));
