import React from 'react';
import classNames from 'classnames';

import styles from './DotsProgress.module.scss';

function DotsProgress({ spinnerClassName }) {
  return (
    <span className={styles['spinner-wrap']}>
      <span className={classNames(styles.spinner, spinnerClassName)} />
    </span>
  );
}

export default DotsProgress;
