import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Container,
  Switch,
  Box,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import styles from './ArticleForm.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import { Typography } from '@material-ui/core';
import * as yup from 'yup';

const ArticleForm = ({ submitHandler, isEdit, article, categories }) => {
  const [file, setFile] = useState({});
  const validationSchema = yup.object({
    fileTitle: isEdit ? '' : yup.string().required('*Required'),
    title: yup.string().required('*Required'),
    categories: yup.array().min(1, '*Required'),
    isPrivate: yup.boolean(),
    isPrivateCommentsOnly: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: article,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      isEdit ? submitHandler(values) : submitHandler(values, file);
      setSubmitting(false);
    },
  });

  return (
    <Box component="form" className={styles.box} onSubmit={formik.handleSubmit}>
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ marginTop: '20px' }}
        >
          <Grid item>
            <input
              style={{ display: 'none' }}
              disabled={isEdit}
              id="uploadPdf"
              name="uploadPdf"
              type="file"
              onChange={event => {
                formik.setFieldValue('fileTitle', event.target.files[0].name);
                setFile(event.target.files[0]);
              }}
              accept=".pdf"
            />
            <label htmlFor="uploadPdf" className={styles.button}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={isEdit}
              >
                Upload PDF
              </Button>
            </label>
            <TextField
              disabled
              name="fileTitle"
              value={formik.values.fileTitle}
              className={styles.pdfTitle}
              label=" *File name"
              error={
                formik.touched.fileTitle && Boolean(formik.errors.fileTitle)
              }
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ marginLeft: '15px' }}>
              Article:
              <Switch
                id="isPrivate"
                name="isPrivate"
                color="primary"
                onChange={formik.handleChange}
                checked={formik.values.isPrivate}
              />
              Private
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ marginLeft: '15px' }}>
              Comments:
              <Switch
                id="isPrivateCommentsOnly"
                name="isPrivateCommentsOnly"
                color="primary"
                onChange={formik.handleChange}
                checked={formik.values.isPrivateCommentsOnly}
              />
              Private
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="title"
              name="title"
              label="*Title"
              variant="filled"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              value={formik.values.categories}
              onChange={(event, newValue) => {
                event.stopPropagation();
                formik.setFieldValue('categories', newValue);
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              multiple
              options={categories}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  id="categories"
                  name="categories"
                  label="*Categories"
                  variant="filled"
                  error={
                    formik.touched.categories &&
                    Boolean(formik.errors.categories)
                  }
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="authors"
              name="authors"
              label="Main Author"
              value={formik.values.authors}
              onChange={formik.handleChange}
              variant="filled"
              error={formik.touched.authors && Boolean(formik.errors.authors)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="authorsCorresponding"
              name="authorsCorresponding"
              label="Author Contact"
              value={formik.values.authorsCorresponding}
              onChange={formik.handleChange}
              variant="filled"
              error={formik.touched.authors && Boolean(formik.errors.authors)}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ArticleForm;
