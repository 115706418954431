import Base from './base';

export default class Users extends Base {
  current() {
    return this.apiClient.get('users/current');
  }

  list(query) {
    return this.apiClient.get('users', query);
  }

  item(id) {
    // return this.apiClient.get(`users/${id}`);
    return Promise.resolve({ data: { role: '' } });
  }

  count() {
    return this.apiClient.get('users/count');
  }

  show(id) {
    return this.apiClient.get(`users/${id}`);
  }

  create({ orcid, isEnabled, role }) {
    return this.apiClient.post('users', { orcid, isEnabled, role });
  }

  upsert(data) {
    return this.apiClient.post('users/upsert', data);
  }

  update(data) {
    return this.apiClient.patch(`users/update`, data);
  }

  getSettings() {
    return Promise.resolve({
      data: {
        alias: { noun: { value: '' }, adjective: { value: '' } },
        articlesFilter: {},
        email: null,
        expertiseAreas: [],
        hasUserCompletedOnBoarding: true,
        interestAreas: [],
        isAgreementAccepted: true,
        isEmailVerified: true,
        role: 'user',
        name: 'test',
        _id: '',
      },
      status: 1,
    }); //this.apiClient.get('user-settings');
  }

  updateSettings({
    expertiseAreas,
    interestAreas,
    isAgreementAccepted,
    email,
    articlesFilter,
    alias,
  }) {
    return this.apiClient.post('user-settings', {
      expertiseAreas,
      interestAreas,
      isAgreementAccepted,
      email,
      articlesFilter,
      alias,
    });
  }

  updatePesistedArticlesFilter(filter) {
    return Promise.resolve({ data: null, status: 1 }); //this.apiClient.patch('user-settings/filter', { filter });
  }

  generateAlias() {
    return this.apiClient.get('user-alias');
  }

  getUserArticles(userId, page, pageSize, filters) {
    return this.apiClient
      .get(`users/${userId}/articles`, {
        page,
        pageSize,
        filters,
      })
      .then(res => res.data);
  }
}
