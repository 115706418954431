import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../constants/routes';
import styles from './Navigation.module.scss';
import Icon, { ICON_NAMES } from '../Icon';

function Navigation(props) {
  const { isAuth } = props;
  const { pathname } = useLocation();

  return (
    <nav>
      <ul className={styles['nav-row']}>
        <li className={styles['nav-col']}>
          <Link
            to={ROUTES.home}
            className={classNames(styles.link, {
              [styles.active]: pathname === ROUTES.home,
            })}
          >
            <span className={styles['link-icon-wrap']}>
              <Icon name={ICON_NAMES.home} />
            </span>
            Home
          </Link>
        </li>
        {/*<li className={styles['nav-col']}>*/}
        {/*  <Link*/}
        {/*    to={ROUTES.covidArticles}*/}
        {/*    className={classNames(styles.link, {*/}
        {/*      [styles.active]: pathname === ROUTES.covidArticles,*/}
        {/*    })}*/}
        {/*  >*/}
        {/*    <span className={styles['link-icon-wrap']}>*/}
        {/*      <Icon name={ICON_NAMES.covid} />*/}
        {/*    </span>*/}
        {/*    COVID-19*/}
        {/*  </Link>*/}
        {/*</li>*/}
        {isAuth && (
          <li className={styles['nav-col']}>
            <Link
                to={{pathname: ROUTES.createArticle, state:{}}}
                className={classNames(styles.link, {
                  [styles.active]: pathname === ROUTES.createArticle,
                })}
            >
              <span className={styles['link-icon-wrap']}>
                <Icon
                  name={ICON_NAMES.uploadFile}
                  className={styles['link-icon-upload']}
                />
              </span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navigation;
