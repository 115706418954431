import Base from './base';

export default class SystemSettings extends Base {
  getSettings() {
    return this.apiClient.get('system');
  }

  setSettings(update) {
    return this.apiClient.put('system', update);
  }

  runDR() {
    return this.apiClient.post('system/run-dr');
  }

  getPublicSystemSettings() {
    return this.apiClient.get('system-settings/public');
  }
}
