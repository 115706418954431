import React, { useMemo } from 'react';
import _ from 'lodash';
import { Box, Chip, makeStyles } from '@material-ui/core';
import { allSelectOption } from '../constants/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    listStyle: 'none',
    padding: theme.spacing(1),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: 3,
    width: '100%',
  },
}));

export const CategoryFilter = ({ data, selected, onChange }) => {
  const classes = useStyles();

  const handleClick = id => () => onChange(id);

  const sortedData = useMemo(() => _.orderBy(data, ['name'], ['asc']), [data]);

  const allOptionColor = useMemo(
    () =>
      !_.isNil(selected) &&
      selected.length === 1 &&
      _.includes(selected, allSelectOption)
        ? 'secondary'
        : 'default',
    [selected],
  );
  return (
    <Box component="ul" className={classes.root}>
      <li key={`key-${allSelectOption}`}  style={{display: 'inline-flex'}}>
        <Chip
          color={allOptionColor}
          label="All Categories"
          onClick={handleClick(allSelectOption)}
          className={classes.chip}
        />
      </li>
      {sortedData.map(data => (
        <li key={`key-${data.id}`} style={{display: 'inline-flex'}}>
          <Chip
            color={_.includes(selected, data.id) ? 'primary' : 'default'}
            label={data.name}
            onClick={handleClick(data.id)}
            className={classes.chip}
          />
        </li>
      ))}
    </Box>
  );
};
