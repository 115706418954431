import superagent from 'superagent';
import storage from 'redux-persist/lib/storage';
import { getStoredState } from 'redux-persist';
import store from '../redux/store';
import { logout } from '../redux/auth/actions';
import history from '../services/history';
import { ROUTES } from '../constants/routes';

export default class ApiClient {
  constructor(config) {
    if (!config.apiPrefix) throw new Error('[apiPrefix] required');

    getStoredState({ storage, key: 'auth' }).then(persistedAuth => {
      this.config = config;
      this.token = persistedAuth ? persistedAuth.token : null;
    });
  }

  getToken() {
    return this.token;
  }

  setToken(token) {
    this.token = token;
  }

  removeToken() {
    this.token = null;
  }

  get(url, params = {}) {
    return this._request({ url, method: 'get', params });
  }

  post(url, body) {
    return this._request({ url, method: 'post', body });
  }

  patch(url, body) {
    return this._request({ url, method: 'patch', body });
  }

  put(url, body) {
    return this._request({ url, method: 'put', body });
  }

  delete(url, body) {
    return this._request({ url, method: 'delete', body });
  }

  _request({ url, method, params, body }) {
    const path = `${this.config.apiUrl}${this.config.apiPrefix}${url}`;
    const req = superagent[method](path);

    if (params) req.query(params);
    if (body) req.send(body);
    if (this.token) req.set('Authorization', `Bearer ${this.token}`);

    return req
      .then(response => {
        return { ...response.body, serverDate: response.headers.date };
      })
      .catch(({ response }) => {
        if (response.error.status === 401) {
          store.dispatch(logout());
        }

        if (response.error.status >= 500) {
          history.push(ROUTES.maintenance);
        }

        throw response.body.error;
      });
  }
}
