import {
  compose,
  getContext,
  withHandlers,
  withPropsOnChange,
} from 'recompose';
import PropTypes from 'prop-types';

import CommentCard from './CommentCard';

export default compose(
  getContext({
    selectComment: PropTypes.func,
  }),
  withHandlers({
    onSelectTreadComment: ({ comment, isReply, selectComment }) => event => {
      event.stopPropagation();
      if (!isReply) {
        selectComment(comment, event.clientY);
      }
    },
  }),
  withPropsOnChange(['isSelected'], ({ isSelected, onRepliesOpen }) => {
    if (isSelected) onRepliesOpen();
  }),
)(CommentCard);
