import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  toShow: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SHOW:
      return {
        ...state,
        toShow: [...state.toShow, payload],
      };
    case actionTypes.HIDE:
      return {
        ...state,
        toShow: state.toShow.filter(modal => modal.name !== payload.name),
      };
    default:
      return state;
  }
};

export default reducer;
