import React from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';
import ErrorText from '../ErrorText/ErrorText';

function Input({
  field,
  containerClassName,
  inputClassName,
  placeholder,
  innerRef,
  isDisabled,
  error,
  isTouched,
  ...restProps
}) {
  const isError = !!error && isTouched;

  return (
    <>
      <label
        className={classNames(styles['input-container'], containerClassName)}
      >
        <input
          {...restProps}
          {...field}
          ref={innerRef}
          className={`${styles.input} ${inputClassName}`}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      </label>
      <ErrorText isShown={isError}>{error}</ErrorText>
    </>
  );
}

Input.defaultProps = {
  inputClassName: '',
};

export default Input;
