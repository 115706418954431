import { useEffect, useState } from 'react';

const MIN_SCALE = 0.25;
const MAX_SCALE = 2.0;
const SCALE_STEP = 0.25;

const usePdfScale = () => {
  const [initialScaleState, setInitialScaleState] = useState(null);
  const [scale, setScale] = useState(null);
  const [isAutoScale, setIsAutoScale] = useState(true);

  useEffect(() => {
    if (scale == null && initialScaleState !== null)
      setScale(initialScaleState);
  }, [initialScaleState]);

  const zoomIn = () => {
    if (scale < MAX_SCALE) {
      if (scale + SCALE_STEP < MAX_SCALE) setScale(scale + SCALE_STEP);
      else setScale(MAX_SCALE);

      setIsAutoScale(false);
    }
  };

  const zoomOut = () => {
    if (scale > MIN_SCALE) {
      if (scale - SCALE_STEP > MIN_SCALE) setScale(scale - SCALE_STEP);
      else setScale(MIN_SCALE);

      setIsAutoScale(false);
    }
  };

  const setAutoScale = () => {
    setIsAutoScale(true);
  };

  const setInitialScaleValue = scaleValue => {
    setInitialScaleState(scaleValue);
    setScale(scaleValue);
  };

  return {
    scale,
    isAutoScale,
    setInitialScaleValue,
    setAutoScale,
    zoomIn,
    zoomOut,
  };
};

export default usePdfScale;
